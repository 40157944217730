(function(){

  /**
   * @ngdoc service
   * @name kmi.lms.course.common.service:CourseCouponCode
   * @description
   * Represents CouponCode data model.
   * Implements all server's CRUD methods.
   */

  angular.module('kmi.lms.course.common')
    .factory('CourseCouponCode', CourseCouponCodeService);

  /* @ngInject */
  function CourseCouponCodeService($resource){
    var CourseCouponCode = $resource('/a/course/coupon_codes/:codeId/',
      {codeId: '@id'},
      {
        query: {
          method: 'GET',
          url: '/a/course/coupon_codes/',
          isArray: false,
          transformResponse: function(data, headersGetter, status) {
            if(status === 200){
              var wrapped = angular.fromJson(data);
              angular.forEach(wrapped.items, function(item, idx) {
                wrapped.items[idx] = new CourseCouponCode(item);
              });
              return wrapped;
            }

            return data;
          }
        },
        update: { method: 'PUT' },
        available: {
          method: 'GET',
          url: '/a/course/:courseId/coupon_codes/availability/',
          isArray: false
        }
      },
      { stripTrailingSlashes: false }
    );

    return CourseCouponCode;
  }

})();
