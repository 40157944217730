(function () {

  angular.module('kmi.lms.core')
    .factory('settingsService', function ($http, $q, apiUrl) {
      var settings = {};

      function getRequestedSettings(names) {
        var result = {};

        for (var i = 0; i < names.length; i++) {
          result[names[i]] = settings[names[i]];
        }

        return result;
      }

      return {
        getSetting: function (name) {
          var names = [];

          if (typeof name === 'string') {
            names.push(name);
          } else {
            if (angular.isArray(name)) {
              names = name;
            }
          }

          var defer = $q.defer();

          var missingSettings = (<any>window).$.grep(names, function (n) {
            return !settings.hasOwnProperty(n);
          });

          if (missingSettings && missingSettings.length > 0) {
            $http.get(apiUrl('/a/about_elms/settings/?names=' + missingSettings.join(',')))
              .then(function (response) {
                angular.extend(settings, response.data);

                defer.resolve(getRequestedSettings(names));
              });
          } else {
            defer.resolve(getRequestedSettings(names));
          }

          return defer.promise;
        }
      };
    });
})();
