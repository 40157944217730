(function () {

  /**
   * @ngdoc service
   * @name kmi.lms.emails.service:MailTemplate
   *
   *
   * @description
   * Represents MailTemplate data model.
   * Implements all server's CRUD methods for MailTemplate.
   */
  angular.module('kmi.lms.emails')
    .factory('MailTemplate', MailTemplateService);

  /* @ngInject */
  function MailTemplateService($resource) {
    return $resource('/a/emails/templates/:templateId/', {templateId: '@id'}, {
      update: {method: 'PUT'}
    }, {
      stripTrailingSlashes: false
    });
  }
})();
