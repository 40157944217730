(function () {

  angular.module('kmi.lms.course.custom-list')
    .service('customCourseListsService', function customCourseListsService($http, $resource, apiUrl) {
      return {
        featuredCourseListByGroup: $resource('/a/groups/:groupId/featured_courses/', {groupId: '@id'}, {
          update: {method: 'PUT'}
        }, {stripTrailingSlashes: false}),

        getUserFeaturedCourses: function (params) {
          return $http.get(apiUrl('/a/user/featured_courses/'), {
            cache: true,
            params: params
          });
        },

        getFeaturedCourses: function () {
          return $http.get(apiUrl('/a/featured_items/courses/limited/'));
        },

        getFeaturedCourseCategories: function () {
          return $http.get(apiUrl('/a/featured_items/course_categories/'));
        },

        getRecommendedCourses: function (opt) {
          return $http.get(apiUrl('/a/advertising/recommendations/courses/'), {params: opt});
        }
      };
    });
})();
