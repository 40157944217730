(function () {

  angular.module('kmi.lms.course.registrations')
    .component('courseFormatSpecificRegistrationDetails', {
      template: require('ajs/modules/course/registrations/view/formats/format-specific-registration-details.html').default,
      controller: SpecificRegistrationDetailsController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        registration: '='
      }
    });

  /* @ngInject */
  function SpecificRegistrationDetailsController() {
    var vm = this;
    vm.$onInit = onInit;

    function onInit() {

    }
  }
})();
