(function () {

  angular.module(
    'kmi.lms.coursePresenters',
    ['kmi.lms.core', 'ngResource', 'kmi.lms.search.common']
  )
    .config(moduleConfig);

  /* @ngInject */
  function moduleConfig() {

  }
})();
