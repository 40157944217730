(function () {

  angular.module('kmi.lms.course.details')
    .component('eventSessionRegistrationActions', {
      template: require('ajs/modules/course/views/formats/live-event/components/session-registration-actions.html').default,
      controller: EventSessionRegistrationActionsController,
      controllerAs: 'vm',
      bindings: {
        workflow: '=',
        session: '=',
        course: '='
      }
    });

  /* @ngInject */
  function EventSessionRegistrationActionsController($scope, courseQuizTypes, globalConfig, _, $state,
    eventExportService, courseRegistrationStatus, courseDetailsState, $location) {
    const vm = this;
    const singleDayEvent = _.get(globalConfig, 'settings.courseDetails.leCalendarSingleDayEvent');

    vm.$onInit = $onInit;
    vm.courseQuizTypes = courseQuizTypes;
    vm.addToCalendar = addToCalendar;
    vm.isBuyNowBtnAvailable = isBuyNowBtnAvailable;
    vm.buyNow = buyNow;
    vm.payLater = payLater;
    vm.isPayLaterAvailable = isPayLaterAvailable;

    function $onInit() {
      $scope.$watch('vm.workflow', (newValue, oldValue) => {
        if (!oldValue && newValue){
          vm.currentBlsId = $location.search().currentBlsId;
          if (vm.currentBlsId){
            vm.workflow.registration.current_bls_id = vm.currentBlsId;
          }
        }
      });

      vm.isAddToCalendarBtnAvailable = _.includes([courseRegistrationStatus.inProgress,
        courseRegistrationStatus.waitList, courseRegistrationStatus.preAssessmentPending], vm.session.status_id) &&
        !vm.session.expired;

      vm.modernFeatures = courseDetailsState.modernFeaturesEnabled(vm.course);
    }

    function isBuyNowBtnAvailable() {
      return _.includes(vm.session.actions, 'PurchaseCourseAction');
    }

    function addToCalendar() {
      var schedules = vm.session.schedules, calendarSchedule = angular.copy(schedules[0]);

      if (!singleDayEvent && schedules.length > 1) {
        calendarSchedule.end_date = schedules[schedules.length - 1].end_date;
        calendarSchedule.utc_end_date = schedules[schedules.length - 1].utc_end_date;
      }
      eventExportService.addToCalendarDialog(vm.workflow.registration, calendarSchedule);
    }

    function buyNow() {
      if (vm.workflow && vm.workflow.registration.isCheckState()) {
        vm.workflow.showCheckStateNotification();
        return;
      }

      $state.go('main.checkout', {courseId: vm.course.id, sessionId: vm.session.id});
    }

    function payLater() {
      if (vm.workflow && vm.workflow.registration.isCheckState()) {
        vm.workflow.showCheckStateNotification();
        return;
      }

      $state.go('main.pay-later', {courseId: vm.course.id, sessionId:vm.session.id});
    }

    function isPayLaterAvailable() {
      return _.includes(vm.session.actions, 'PurchaseCourseLaterAction');
    }
  }
})();
