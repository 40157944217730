(function () {

  angular.module('kmi.lms.course.registrations')
    .component('courseRegistrationCredit', {
      template: require('ajs/modules/course/registrations/components/registration-credit.html').default,
      controller: CourseRegistrationCreditController,
      controllerAs: 'vm',
      bindings: {
        registrationCredit: '<',
        mode:'@?'
      }
    });

  /* @ngInject */
  function CourseRegistrationCreditController(elms) {
    const vm = this;

    vm.formatDigit = elms.formatDigit;
    vm.$onInit = onInit;

    function onInit() {
      vm.creditLongName = getCreditLongName();
    }

    function getCreditLongName() {
      let accreditedBy = '';

      if (vm.registrationCredit.accreditationAgency) {
        const agency = vm.registrationCredit.accreditationAgency;
        accreditedBy = `accredited by ${agency.acronym || agency.name} `;
      }

      let creditTypeName = vm.registrationCredit.creditType.name ? `${vm.registrationCredit.creditType.name} ` : '';
      let creditTypeLongName = vm.registrationCredit.creditType.longName ? `${vm.registrationCredit.creditType.longName} ` : '';

      return [creditTypeName, creditTypeLongName, accreditedBy].join('');
    }
  }
})();
