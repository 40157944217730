(function () {

  angular.module('kmi.lms.course.registrations')
    .factory('courseBulkEnrollmentService', courseBulkEnrollmentService);

  function courseBulkEnrollmentService($http, $window, moment, $httpParamSerializer) {
    return {
      getRecords: getRecords,
      getStats: getStats,
      exportResults: exportResults
    };

    function getRecords(sessionId, params) {
      return $http.get(['/a/course_registrations/bulk-enrollment-sessions/', sessionId, '/records/'].join(''),
        {params: params})
        .then(function (response) {
          return response.data;
        });
    }

    function getStats(sessionId) {
      return $http.get(['/a/course_registrations/bulk-enrollment-sessions/', sessionId, '/records/stats'].join(''))
        .then(function (response) {
          return response.data;
        });
    }

    function exportResults(enrollmentSession, params) {
      var fileName: any = ['assign_users', enrollmentSession.id];

      if (params && params.successful === true) {
        fileName.push('successful');
      }

      if (params && params.successful === false) {
        fileName.push('failed');
      }

      fileName.push(moment(new Date()).format('YYYY-MM-DD'));
      fileName = fileName.join('_');
      fileName = fileName.replace(/[<>:"\/\\|?*]/gi, '-');

      params = angular.extend({
        format: 'xlsx',
        file_name: fileName
      }, params);

      $window.open('/a/course_registrations/bulk-enrollment-sessions/' + enrollmentSession.id + '/records/?' + $httpParamSerializer(params),
        '_blank', '');
    }
  }

})();
