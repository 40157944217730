(function () {

  angular.module('kmi.lms.course.details')
    .component('prerequisites', {
      template: require('ajs/modules/course/views/components/prerequisites/prerequisites.html').default,
      bindings: {
        course: '<',
        prerequisites: '=',
        registration: '=',
        workflow: '='
      }
    });
})();
