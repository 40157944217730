(function () {

  /**
   * @ngdoc service
   * @name kmi.lms.customAttributes.session:customSessionAttributesService
   *
   *
   * @description
   * Represents customSessionAttributesService service.
   * Implements getting of attributes from server for session attributes.
   */
  angular.module('kmi.lms.customAttributes.session')
    .factory('customSessionAttributesService', customSessionAttributesService);

  /*@ngInject*/
  function customSessionAttributesService($http, $cacheFactory) {

    var customSessionAttributesCategoriesCache = $cacheFactory('customSessionAttributesCategoriesCache');

    var service = {
      getCategories: getCategories
    };

    return service;

    function getCategories(params, ignoreCache, timoutDefer) {
      var requestParams = {};

      if (params) {
        requestParams.params = params;
      }

      if (!ignoreCache) {
        requestParams.cache = customSessionAttributesCategoriesCache;
      }

      if (timoutDefer) {
        requestParams.timeout = timoutDefer.promise;
      }

      return $http.get('/a/custom_attributes/sessions/categories/', requestParams);
    }

  }
})();

