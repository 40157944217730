(function () {

  angular.module('kmi.lms.course.registrations', [
    'kmi.lms.course.common',
    'kmi.lms.components',
    'kmi.lms.search.common',
    'kmi.lms.user.common',
    'kmi.lms.user.certificates',
    'kmi.lms.credits',
    'kmi.lms.emails',
    'kmi.lms.network',
    'ui.router'
  ])
    .config(moduleConfig);

  /* @ngInject */
  function moduleConfig($stateProvider) {
    $stateProvider
      .state('main.registration', {
        url: '^/registrations/:id',
        params: {
          id: null,
          options: null
        },
        data: {
          label: 'Course Registration',
          icon: 'fa fa-info',
          availableFor: ['regularUser', 'admin']
        },
        component: 'courseRegistrationInfo',
        resolve: {
          registration: function (CourseRegistration, $transition$) {
            return  CourseRegistration.get({registrationId: $transition$.params().id,
              permitted_for: ['courseRegistration.view', 'courseRegistration.edit']}).$promise;
          },
          permittedFor: function () {
            return ['courseRegistration.view', 'courseRegistration.edit'];
          }
        }
      })
      .state('main.registrationExternal', {
        url: '^/user/:userId/course/:courseId/external',
        params: {
          userId: null,
          courseId: null,
          options: null
        },
        data: {
          label: 'Course Registration External',
          icon: 'fa fa-info',
          availableFor: ['regularUser', 'admin']
        },
        component: 'courseRegistrationInfoExternal',
        resolve: {
          registration: function (ExternalCourse, $transition$) {
            var parameters = {
              courseId: $transition$.params().courseId,
              userId: $transition$.params().userId,
              permitted_for: ['courseRegistration.edit']
            };

            return ExternalCourse.get(parameters).$promise;
          }
        }
      })
      .state('main.userCourseHistory', {
        url: '/courses/:courseId/history?readOnly&hideCourseDetails&showRemoveAction',
        params: {
          courseId: null,
          readOnly: null,
          hideCourseDetails: null
        },
        data: {
          label: 'Course History',
          availableFor: ['regularUser', 'admin']
        },
        component: 'userCourseHistoryModalComponent',
        resolve: {
          course: function ($transition$, Course) {
            return Course.get({courseId: $transition$.params().courseId, allowSystemFormat: true}).$promise;
          },
          hideCourseDetails: function ($transition$) {
            return $transition$.params().hideCourseDetails === 'true';
          },
          readOnly: function ($transition$) {
            return $transition$.params().readOnly === 'true';
          }
        }
      });
  }
})();
