(function () {

  angular.module('kmi.lms.course.details')
    .directive('courseResources', courseResources);

  function courseResources() {
    return {
      restrict: 'EA',
      scope: {
        course: '='
      },
      template: require('ajs/modules/course/views/components/resources.html').default,
      controller: CourseResourcesCtrl,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /* @ngInject */
  function CourseResourcesCtrl($scope) {
    var vm = this;

    vm.documents = [];

    vm.$onInit = onInit;

    function readDocuments() {
      vm.course.initDocuments().then(function (docs) {
        vm.documents = docs;
      });
    }

    function onInit() {
      readDocuments();

      $scope.$on('event:course.registration.action.finished', function () {
        readDocuments();
      });
    }

  }
})();
