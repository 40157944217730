(function () {

  angular.module('kmi.lms.core')
    .factory('urlRouterDefaultView', function ($injector) {
      return {
        get: function () {
          var navigationService = $injector.get('navigationService');
          navigationService.goHome();
          return true;
        }
      };
    });
})();
