(function () {

  angular.module('kmi.lms.course.details')
    .component('conferenceSessionCertificate', {
      template: require('ajs/modules/course/views/formats/collection/conference/components/session-certificate.html').default,
      controller: ConferenceSessionCertificateController,
      controllerAs: 'vm',
      bindings: {
        session: '=',
        sessionUserCourse: '='
      }
    });

  /* @ngInject */
  function ConferenceSessionCertificateController(userCertificatesService, _) {
    const vm = this;

    vm.hasCertificate = hasCertificate;
    vm.certificatesAvailable = certificatesAvailable;
    vm.showCertificateStatus = showCertificateStatus;

    function hasCertificate(sessionCourse) {
      return sessionCourse.certificate || (sessionCourse.certificates && sessionCourse.certificates.length);
    }

    function certificatesAvailable() {
      return _.get(vm.sessionUserCourse, 'certificates', []).length;
    }

    function showCertificateStatus() {
      userCertificatesService.printCertificate(_.get(vm.sessionUserCourse, 'lastCompletedRegistration.id'), false);
    }
  }
})();
