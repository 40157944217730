(function () {

  angular.module('kmi.lms.course.registrations')
    .component('modernManageCourseRegistration', {
      template: require('ajs/modules/course/registrations/components/modern/manage-course-registration.html').default,
      controller: ManageCourseRegistrationController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        userCourse: '=',
        showPlayCourse: '<?',
        textLight: '<?'
      }
    });

  /* @ngInject */
  function ManageCourseRegistrationController($scope, $state, $window, globalConfig, siteSettingService,
    LearningObjectRegistration, LearningObjectRegistrationWorkflow, courseRegistrationStatus,
    moment, currentUser, serverErrorHandlerService, courseEnums, backUrlService, _) {
    var vm = this;
    var filterStatuses = [courseRegistrationStatus.pending, courseRegistrationStatus.declined];

    vm.backUrl = backUrlService;
    vm.settings = _.get(globalConfig, 'settings.courseDetails');
    vm.hideAnonymousWarnings = vm.settings.hideAnonymousWarnings;
    vm.editable = globalConfig.settings.view === 'admin' || globalConfig.projectName === 'ubt';
    vm.registrationExpirationEnabled = globalConfig.settings.registrationExpirationEnabled;
    vm.hasContactsState = $state.get('main.contact');
    vm.isAnonymous = currentUser.get().anonymous;
    vm.userId = currentUser.get().id;
    vm.courseEnums = courseEnums;


    vm.$onInit = onInit;
    vm.isAccessExpired = isAccessExpired;
    vm.isExpired = isExpired;
    vm.willExpire = willExpire;
    vm.expireCountdown = expireCountdown;
    vm.regNotDeclined = regNotDeclined;

    function onInit() {
      vm.showPlayCourse = vm.course.courseFormat.registrationRequired &&
        (angular.isDefined(vm.showPlayCourse) ? vm.showPlayCourse : false);

      if (vm.course.formatTypeId !== courseEnums.formatType.liveEvent) {
        $scope.$watch('vm.actionsOpen', function (newValue, oldValue) {
          if (newValue !== oldValue && newValue && vm.userCourse && !vm.userCourse.workflow) {
            LearningObjectRegistration.get(vm.course, null).then(function (registration) {
              vm.userCourse.workflow = new LearningObjectRegistrationWorkflow(registration);
            });
          }
        });
      }

    }

    function willExpire() {
      return vm.userCourse && vm.userCourse.accessExpirationDate && regNotDeclined();
    }

    function expireCountdown() {
      if (!vm.userCourse || !vm.userCourse.accessExpirationDate) {
        return null;
      }

      return moment(vm.userCourse.accessExpirationDate).fromNow();
    }

    function isAccessExpired() {
      if (!vm.userCourse || !vm.userCourse.accessExpirationDate) {
        return false;
      }
      return regNotDeclined() && isExpired(vm.userCourse.accessExpirationDate);
    }

    function isExpired(date) {
      return moment(date).diff(moment(), 'seconds') <= 0;
    }

    function regNotDeclined() {
      return filterStatuses.indexOf(vm.userCourse.statusId) === -1;
    }
  }
})();
