(function () {

  angular.module('kmi.lms.course.details')
    .component('baseCourseReviews', {
      template: require('ajs/modules/course/reviews/base-course-reviews.html').default,
      controller: BaseCourseReviews,
      controllerAs: 'vm',
      bindings: {
        courseObj: '=',
        from: '='
      }
    }
    );

  /* @ngInject */
  function BaseCourseReviews($scope, _, backUrlService, courseDetailsState, currentUser) {
    var vm = this;

    vm.user = currentUser.get();
    vm.back = back;
    vm.$onInit = init;

    function init() {
      vm.trackingName = 'course_details_' + vm.courseObj.id + '_reviews';
    }

    function back() {
      if (vm.from === 'courseDetails'){
        backUrlService.clearDuplicateLastStateForRedirect('main.course');
        courseDetailsState.setScrollPosition('reviews');
        backUrlService.passThroughRedirect('main.course', {id: vm.courseObj.id});
      } else {
        backUrlService.goBack();
      }
    }
  }
})();
