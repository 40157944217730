(function () {

  angular.module('kmi.lms.course.details')
    .component('coursePresenters', {
      template: require('ajs/modules/course/views/components/course-presenters.html').default,
      controller: CoursePresentersController,
      controllerAs: 'vm',
      bindings: {
        presenters: '='
      }
    });

  /* @ngInject */
  function CoursePresentersController() {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {

    }
  }
})();
