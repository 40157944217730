(function () {

  angular.module('kmi.lms.course.details')
    .component('courseRegistrationRegisterOnce', {
      template: require('ajs/modules/course/views/components/state-messages/course-registration-register-once.html').default,
      controller: CourseRegistrationRegisterOnceController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        userCourse: '='
      }
    });

  /* @ngInject */
  function CourseRegistrationRegisterOnceController() {
  }
})();
