(function () {

  angular.module('kmi.lms.course.details')
    .component('prerequisitesList', {
      template: require('ajs/modules/course/views/components/prerequisites/prerequisites-list.html').default,
      bindings: {
        prerequisites: '='
      }
    });

})();
