(function() {

  angular.module('kmi.lms.course.details')
    .controller('CourseRelatedCollectionsController', CourseRelatedCollectionsController);

  /* @ngInject */
  function CourseRelatedCollectionsController($scope, $window, collectionsService, _) {
    var vm = this;
    // used as a controller on different components

    vm.$onInit = onInit;

    function onInit() {
      var window = angular.element($window);

      checkMobileView();

      window.bind('resize', checkMobileView);

      loadCollections();

      function checkMobileView() {
        vm.mobileView = window.width() < 768;
      }

      $scope.$on('event:course.registration.updated', function () {
        loadCollections();
      });
    }

    function loadCollections() {
      collectionsService.getCourseCollections(vm.courseId, null, null, true)
        .then(function (collections) {
          vm.collections = _.slice(collections, 0, vm.maxCount || collections.length);
        });
    }
  }
})();
