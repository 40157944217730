(function () {

  angular.module('kmi.lms.customAttributes.common')
    .component('customAttributesCategoriesMenu', {
      restrict: 'AE',
      replace: true,
      bindings: {
        categories: '=',
        currentTab: '=',
        isFormIncomplete: '='
      },
      template: require('ajs/modules/custom-attributes/common/categories-menu-template.html').default,
      controller: CustomAttributesCategoriesMenu,
      controllerAs: 'vm',
      bindToController: true
    });

  /* @ngInject */
  function CustomAttributesCategoriesMenu(attributesService) {
    var vm = this;
    vm.initForm = attributesService.initForm;
  }

})();
