(function () {

  angular.module('kmi.lms.course.common')
    .directive('courseEditActions', courseEditActions);

  function courseEditActions() {
    return {
      restrict: 'EA',
      scope: {
        course: '=',
        courseEditActions: '@',
        disabled: '<?'
      },
      template: require('ajs/modules/course/common/edit-actions.html').default,
      controller: CourseEditActionsController
    };
  }

  /* @ngInject */
  function CourseEditActionsController($scope, $state, $injector, backUrlService, supportedCourseFormats, Course,
    globalConfig, courseService, _, securityService, courseEnums, currentUser) {

    $scope.backUrlParam = backUrlService.external.getBackUrlParam();
    $scope.toggleButton = $scope.courseEditActions !== 'icon';
    $scope.projectName = globalConfig.projectName;
    $scope.settings = globalConfig.settings;
    $scope.courseFormatTypes = courseEnums.formatType;
    $scope.currentUser = currentUser.get();
    $scope.superAdmin = currentUser.get().isInRole(1);
    $scope.approvalManager = currentUser.get().isInRole(14);

    $scope.cloneCourse = cloneCourse;
    $scope.isClonable = isClonable;
    $scope.isEditSessionsStateAvailable = securityService.isStateAvailable('edit.courseSessions');
    $scope.assignUsersStateAvailable = securityService.isStateAvailable('main.assignUsers');
    $scope.organizationsStateAvailable = securityService.isStateAvailable('main.courseOrganizations');
    $scope.organizationsManagementStateAvailable = securityService.isStateAvailable('main.courseOrganizationsManagement');
    $scope.editTrainQuiz = editTrainQuiz;


    activate();

    function activate() {
      if (!($scope.course instanceof Course)) {
        $scope.course = new Course({
          format: $scope.course.format_id,
          courseFormat: $scope.course.courseFormat,
          formatTypeId: $scope.course.course_format_type_id,
          id: parseInt($scope.course.id),
          name: $scope.course.title
        });
      }

      $scope.internalEdit = $state.get('edit.course') &&
        _.includes(supportedCourseFormats, $scope.course.formatTypeId) && ($scope.course.format > 100);

      setupRosterEndpoints();

      if (!$scope.courseRights && $scope.course && $scope.course.permissions) {
        initRights($scope.course.permissions);
      }

      $scope.$watch('isOpen', function (newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          if (!$scope.courseRights) {
            $scope.rightsLoading = true;

            Course.get({courseId: $scope.course.id}, function (course) {
              $scope.courseObj = course;
              $scope.course.hasDocuments = $scope.courseObj.hasDocuments;
              $scope.course.published = $scope.courseObj.published;
              initRights(course.permissions);
            })
              .$promise
              .finally(function () {
                $scope.rightsLoading = false;
              });
          }
        }
      });
    }

    function setupRosterEndpoints() {
      var rosterService = $injector.has('courseRosterService') && $injector.get('courseRosterService');

      if (rosterService) {
        $scope.rosterLink = rosterService.getRosterLink();
      }
    }

    function initRights(permissions) {
      var data = {};

      if (permissions && permissions.length) {
        $scope.courseRights = {};

        for (var i = 0; i < permissions.length; i++) {
          data[permissions[i]] = true;
        }

        $scope.courseRights = data;

        if ($scope.course.formatTypeId === 2) {
          permissions = _.without(permissions, 'courseRegistration.add');
        }

        $scope.hasEditPermissions = _.intersection(permissions, ['course.assessment.edit', 'courseRegistration.view',
          'course.session.edit', 'course.session.view', 'course.evaluation.edit', 'courseRegistration.edit',
          'courseRegistration.add']).length ||
          (_.includes(permissions, 'course.edit') && ($scope.internalEdit || $scope.projectName !== 'train')) ||
          (_.includes(permissions, 'course.clone') && isClonable());
      }
    }

    function cloneCourse() {
      courseService.cloneCourse($scope.course);
    }

    function isClonable() {
      return courseService.isClonable($scope.course);
    }

    function editTrainQuiz(quizTypeId) {
      var service = $injector.get('trainCourseQuizzesService');
      if (service) {
        service.showEditDialog($scope.course.id, quizTypeId);
      }
    }
  }
})();
