(function () {

  angular.module('kmi.lms.core')
    .factory('User', userService);

  /* @ngInject */
  function userService($http, $q, apiUrl, Group, Class, uuid, _, globalConfig) {
    var User;

    function processUserData(data) {
      var user = new User(data.user);
      user.groups = [];

      if (data.user.groups) {
        _.forEach(data.user.groups, function (g) {
          user.groups.push(new Group(g));
        });
      }

      user.initPermissions(data.permissions);

      return user;
    }

    User = Class.extend({
      _permissions: ['user.view'],
      init: function (attrs) {
        angular.extend(this, {
          globalId: uuid.v4(),
          files: {}
        });

        angular.extend(this, attrs);
      },
      save: function () {
        var user = angular.copy(this);
        user.groups = this.getGroupIds();

        user.activeOnly = _.get(globalConfig, 'settings.user.checkDuplicatesForActiveOnly', false);

        if (this.id) {
          var endpoint = apiUrl(User.endpoint(this.id));
          return $http.post(this.permitted_for ? [endpoint, '?permitted_for=', this.permitted_for].join('') :
            endpoint, user)
            .then(function (response) {
              angular.extend(this, response.data.user);
            }.bind(this));
        } else {
          return $http.put(apiUrl(User.endpoint()), user).then(function (response) {
            angular.extend(this, response.data.user || response.data);
          }.bind(this));
        }
      },
      update: function (updateProps) {
        if (this.id) {
          return $http.post(apiUrl(User.endpoint(this.id)), updateProps).then(function (response) {
            angular.extend(this, response.data.user);
          }.bind(this));
        } else {
          var def = $q.defer();
          def.resolve();
          return def.promise;
        }
      },
      getGroupIds: function () {
        return _.filter(_.map(this.groups || [], function (item) {
          if (typeof item === 'number') {
            return item;
          } else if (item && item.id) {
            return item.id;
          } else {
            return null;
          }
        }), function (g) {
          return g !== null;
        });
      },
      getAttributeIds: function () {
        return _.map(this.attributes || [], function (item) {
          return item.id;
        });
      },
      setGroups: function (groups) {
        this.groups = groups;
      },
      checkLogin: function (newLogin, options) {
        return $http.post(apiUrl(User.endpoint(this.id) + 'check_new_login/'), {login: newLogin}, options)
          .then(function (response) {
            return !response.data;
          });
      },
      checkEmail: function (newEmail, userActivated, options) {
        const parameters = {
          email: newEmail || this.email,
          activeOnly: _.get(globalConfig, 'settings.user.checkDuplicatesForActiveOnly', null),
          userActivated: userActivated
        };
        return $http.post(apiUrl(User.endpoint(this.id) + 'check_new_email/'), parameters, options)
          .then(function (response) {
            return response.data;
          });
      },
      getVerificationInfo: function () {
        return $http.get(apiUrl(User.endpoint() + 'email_verification/'))
          .then(function (response) {
            return response.data;
          });
      },
      changePassword: function (data) {
        if ((this.id || this.loginName) && data) {
          data.loginName = this.loginName;
          return $http.post(apiUrl(User.endpoint(this.id) + 'update_password/'), data);
        } else {
          var def = $q.defer();
          def.resolve();
          return def.promise;
        }
      },
      doResetPassword: function () {
        if (this.id) {
          return $http.get(apiUrl(User.endpoint() + 'security/' + this.id + '/reset_password/'));
        } else {
          var def = $q.defer();
          def.resolve();
          return def.promise;
        }
      },
      generatePassword: function () {
        return $http.get(apiUrl(User.endpoint() + 'security/generate_password/')).then(function (response) {
          return response.data;
        });
      },
      initPermissions: function (permissions) {
        if (permissions) {
          this._permissions = permissions;
          this._lowerCasePermissions = _.map(permissions, (p) => {return p.toLowerCase();});
        }
      },
      checkPermission: function (permission) {
        // permissions by user group roles
        return !!_.find(this._permissions, function (p) {
          return p.toLowerCase() === permission.toLowerCase();
        });
      },
      checkUserPermission: function (permission) {
        // full user permissions list
        return !!_.find(this.permissions, function (p) {
          return p.toLowerCase() === permission.toLowerCase();
        });
      },
      hasPermissions: function (permissions){
        // all data lowercase
        return _.intersection(this._lowerCasePermissions, _.map(permissions, p => {return p.toLowerCase();})).length;
      },
      getRoleIds: function () {
        if (!(this.roles && this.roles.length)) {
          return [];
        }
        return _.map(this.roles, function (role) {
          return role.roleId;
        });
      },
      isRegularUser: function () {
        return !(this.roles && this.roles.length);
      },
      isInRole: function (roles) {
        var tempRoles = [];
        if (angular.isArray(roles)) {
          tempRoles = roles;
        } else {
          tempRoles.push(roles);
        }
        return _.intersection(this.getRoleIds(), tempRoles).length > 0;
      },
      generateAuthorizationToken: function (data) {
        return $http.post(['/a/user/', this.id, '/authorization-token/'].join(''), data)
          .then(function (response) {
            return response.data.token;
          });
      },
      editable: function () {
        return this.checkPermission('user.edit') || this.checkPermission('user.super_edit');
      }
    }, {
      endpoint: function (id) {
        var basePath = '/a/user/';
        return id ? basePath + id + '/' : basePath;
      },
      get: function (id, query) {
        return $http.get(apiUrl(User.endpoint(id)), {params: query}).then(function (response) {
          return processUserData(response.data);
        });
      },
      getFromObj: function (data) {
        if (data && data.user) {
          return processUserData(data);
        }
        return new User();
      },
      newUser: function (attrs) {
        return new User(angular.extend({
          policiesSignOff: false,
          stayInformed: true,
          receiveEmails: true
        }, attrs));
      }
    });


    return User;
  }

})();
