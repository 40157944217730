(function () {

  angular.module('kmi.lms.course.registrations')
    .directive('courseRegRequiredUserAttributes', courseRegRequiredUserAttributes);

  function courseRegRequiredUserAttributes() {
    return {
      restrict: 'EA',
      scope: {
        workflow: '='
      },
      template: require('ajs/modules/course/registrations/components/required-user-attributes.html').default,
      controller: CourseRegRequiredUserAttributesController,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /* @ngInject */
  function CourseRegRequiredUserAttributesController(userAttributesService, attributesService, currentUser) {
    var vm = this,
      user = currentUser.get();

    vm.save = save;

    vm.$onInit = activate;

    function activate() {
      vm.userAttributes = vm.workflow.registration.missingUserAttributes;
      vm.submitted = false;
      vm.workflow.currentStateHandlers.nextHandler = save;
      vm.workflow.currentStateHandlers.stateDisabledHandler = isContinueDisabled;
    }

    function isContinueDisabled(){
      return vm.attributesForm.$invalid || vm.savePromise;
    }

    function save() {
      vm.submitted = true;

      if (vm.attributesForm.$valid) {
        attributesService.formatResultData(vm.userAttributes);
        vm.savePromise = userAttributesService.save(user.id, vm.userAttributes)
          .then(function () {
            vm.workflow.nextState();
            vm.savePromise = null;
          });
      }
    }
  }
})();
