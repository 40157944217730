(function () {

  angular
    .module('kmi.lms.course.common')
    .factory('CollectionComponent', CollectionComponent);

  /* @ngInject */
  function CollectionComponent($resource, $cacheFactory) {
    var courseCache = $cacheFactory.get('courseCache') || $cacheFactory('courseCache');

    return $resource('/a/course/:courseId/components/:componentCourseId/',
      {courseId: '@courseId', componentCourseId: '@componentCourseId'}, {
        get: {
          method: 'GET'
        },
        query: {
          method: 'GET',
          url: '/a/course/:courseId/components/',
          isArray: true,
          cache: courseCache
        },
        update: {method: 'PUT'}
      },
      {
        stripTrailingSlashes: false
      });
  }
})();
