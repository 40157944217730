(function () {

  angular.module('kmi.lms.course.details')
    .component('courseContacts', {
      template: require('ajs/modules/course/views/components/contacts.html').default,
      controller: CourseContactsController,
      controllerAs: 'vm',
      bindings: {
        contacts: '='
      }
    });

  /* @ngInject */
  function CourseContactsController() {
    var vm = this;

    vm.$onInit = activate;

    function activate() {
      // Expand first contact
      if (vm.contacts && vm.contacts.length) {
        vm.contacts[0].expanded = true;
      }
    }
  }
})();
