(function () {

  angular
    .module('kmi.lms.course.common')
    .component('courseCompetencyLevelNodes', {
      template: require('ajs/modules/course/common/competencies/course-competency-level-nodes.html').default,
      controllerAs: 'vm',
      bindings: {
        nodes: '=',
        level: '='
      }
    });
})();
