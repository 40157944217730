(function() {

  angular.module('kmi.lms.course.details')
    .directive('courseMultiplePricesInfo', function () {
      return {
        restrict: 'EA',
        scope: {
          course: '='
        },
        template: require('ajs/modules/course/views/components/course-multiple-prices-info.html').default,
        controller: CourseMultiplePricesInfo,
        bindToController: true,
        controllerAs: 'vm'
      };
    });

  /* @ngInject */
  function CourseMultiplePricesInfo(elms) {
    var vm = this;
    vm.formatPrice = elms.formatPrice;
  }
})();
