(function () {

  angular.module('kmi.lms.course.reviews')
    .component('modernCourseReviews', {
      bindings: {
        course: '=',
        view: '@',
        trackingName: '<?',
        visible: '=',
        userCourse: '='
      },
      template: require('ajs/modules/course/reviews/modern-reviews.html').default,
      controller: CourseReviewsController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function CourseReviewsController(rootScopeService, $scope, $uibModal, scrollingService, courseReviewsService,
    serverErrorHandlerService, globalConfig, courseReviewsConst,
    moment, elms, $window, _) {

    var vm = this,
      cacheName;

    vm.sortRules = courseReviewsConst.SORT_RULES;
    vm.reviewAdded = false;

    vm.reviewChanged = reviewChanged;
    vm.exportReviews = exportReviews;
    vm.reviewActivate = reviewActivate;
    vm.reviewDeactivate = reviewDeactivate;
    vm.createReview = createReview;
    vm.getPeriod = getPeriod;
    vm.$onInit = onInit;
    vm.reviewsVisible = reviewsVisible;
    vm.formatDigit = elms.formatDigit;

    function onInit() {
      cacheName = 'courses-' + vm.course.id + '-reviews';
      if (vm.view){
        cacheName += '-' + vm.view;
      }
      vm.adminView = globalConfig.settings.view === 'admin';
      vm.course_rating_available = _.get($window.elmsEnvironment, 'userSettings.course_rating_available', false);
      vm.minRatingCount = _.get(globalConfig, 'settings.courseDetails.minNumberOfReviewsToShowRating', 1);

      activate();
    }

    function reviewsVisible() {
      vm.visible = vm.course_rating_available &&
                   (vm.items && vm.items.length || !vm.reviewAdded || vm.course.ratings.allow_addition);
      return vm.visible;
    }

    function activate() {
      if (!vm.view || vm.view !== 'courseDetails') {
        setupScrollHandling();
      }
      loadReviews();

      courseReviewsService.updateStat(vm.course);

      $scope.$on('event:user:course:review:added', addReview);

      $scope.$on('event:course.registration.action.finished', function () {
        courseReviewsService.updateStat(vm.course);
      });

      function setupScrollHandling() {
        scrollingService.attachScrollListener();

        // Listen for the scroll event in order to load additional records
        $scope.$on('content.when-scrolled', function () {
          // Cancel if request is already in progress.
          if (vm.loadingPromise) {
            return;
          }

          loadMore();
        });
      }

      function loadMore() {

        vm.loadingPromise = courseReviewsService.next()
          .then(function (data) {
            //Append new items to the existing list
            for (var i = 0; i < data.items.length; i++) {
              vm.items.push(data.items[i]);
            }
          }, showHandledServerError)
          .finally(function () {
            vm.loadingPromise = null;
          });

        // Tells to UI progress indicator what kind of indication needed
        vm.loadingPromise.loadMore = true;
      }
    }

    function loadReviews() {
      var localCache = JSON.parse(sessionStorage.getItem(cacheName));
      if (localCache && localCache.items && localCache.items.length) {
        vm.itemCount = localCache.total;
        vm.items = localCache.items;
      }
      vm.sortedBy = localCache && localCache.sortId ? vm.sortRules[localCache.sortId] : vm.sortRules.newest;
      vm.filteredByStar = null;
      $scope.$watch('vm.filteredByStar', changeFilterByStar);
      $scope.$watch('vm.sortedBy', changeSort);
      loadReviewsQuery();
    }

    function changeFilterByStar(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue && vm.sortedBy.id !== vm.sortRules.newest.id) {
          vm.sortedBy = vm.sortRules.newest;
        } else {
          initLoadReviews();
        }
      }
    }

    function changeSort(newValue, oldValue) {
      if (newValue !== oldValue) {
        initLoadReviews();
      }
    }

    function initLoadReviews() {
      courseReviewsService.conditions.offset = 0;
      loadReviewsQuery();
    }

    function loadReviewsQuery() {
      var queryParams = {
        courseId: vm.course.id,
        includeInactive: !!vm.adminView,
        query: {sort: vm.sortedBy.rule},
        take: courseReviewsConst.REQUEST_ITEM_COUNT
      };

      if (vm.view === 'courseDetails'){
        queryParams.take = 5;
      }

      if (vm.filteredByStar) {
        queryParams.query.filters = [{
          field: 'rating',
          values: vm.filteredByStar
        }];
      }

      vm.loadingPromise = courseReviewsService.query(queryParams, vm.course.ratings.count)
        .then(function (data) {
          vm.itemCount = data.total;
          vm.items = data.items;
          sessionStorage.setItem(cacheName, JSON.stringify({
            items: vm.items,
            total: vm.itemCount,
            sortId: vm.sortedBy.id,
            filteredByStar: vm.filteredByStar
          }));
        }, showHandledServerError)
        .finally(function () {
          vm.loadingPromise = null;
        });
    }

    function showHandledServerError(reason) {
      return serverErrorHandlerService.handleForbiddenError(reason);
    }


    /**
     * @description
     * Appends provided review ot the course reviews collection
     * @param {Object} review review
     */
    function addReview(event, review) {
      vm.items.unshift(review); // Add review to the top of reviews collection
      courseReviewsService.conditions.offset++; // Increment offset in order to get properly data on additional data loading.
      courseReviewsService.updateStat(vm.course); // Recalculate average rating

      vm.newReview = null; // Turn edit mode off
      vm.reviewAdded = true;
    }

    /**
     * @description
     * Handles review changes and saves it to the server
     * @param {Object} review review
     */
    function reviewChanged(review) {
      review.$update().then(function () {
        // Recalculate average rating
        courseReviewsService.updateStat(vm.course);
      });
    }

    /**
     * @description
     * Activate review
     * @param {Object} review review
     */
    function reviewActivate(review) {
      review.active = 1;
      vm.reviewChanged(review);
    }

    /**
     * @description
     * Deactivate review
     * @param {Object} review review
     */
    function reviewDeactivate(review) {
      review.active = 0;
      vm.reviewChanged(review);
    }

    /**
     * @description
     * Export reviews to csv for admins
     */
    function exportReviews() {
      courseReviewsService.exportReviews(vm.course.id);
    }

    /**
     * @description
     * Add new review
     */
    function createReview() {
      rootScopeService.broadcast('event:course:review:new');
    }

    /**
     * @description
     * Add new review
     */
    function getPeriod(review) {
      return review.date ? moment(review.date).fromNow() : '';
    }
  }
})();
