(function () {

  /**
   * @ngdoc service
   * @name kmi.lms.core:urlVersionService
   *
   *
   * @description
   * Save search for course author admin list
   */

  angular.module('kmi.lms.core')
    .factory('urlVersionService', urlVersionService);

  /* @ngInject */
  function urlVersionService(rootScopeService) {
    var timestamp = null;

    return {
      get: function () {
        return timestamp;
      },
      update: function () {
        timestamp = new Date().getTime();

        rootScopeService.broadcast('event:urlVersion.updated');
      }
    };
  }
})();
