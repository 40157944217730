import _ from 'lodash';
import { IUser } from 'modules/user/models/user.model';
import {fromEventPattern} from 'rxjs';


export class CurrentUserService {
  user: any; $User: any; $csrfProtection: any; $rootScopeService: any;

  static $inject = ['User', 'csrfProtection', 'rootScopeService'];
  constructor(User: any, csrfProtection: any, rootScopeService: any) {
    this.user = new User();

    this.$User = User;
    this.$csrfProtection = csrfProtection;
    this.$rootScopeService = rootScopeService;
  }

  /**
  * returns new `AngularJS` User object.
  * @see `app/modules/app/models/user/user.ts`
  */
  get(): IUser {
    return new this.$User(this.user);
  }

  set(value: any) {
    if (!this.user.id) {
      this.user = new this.$User(value);
    } else {
      _.assign(this.user, _.pick(new this.$User(value), _.keys(this.user)));
    }
  }

  // Listen for 'event:userInfoLoaded' and expose it as an observable
  userInfoLoaded$ = fromEventPattern(
    handler => this.$rootScopeService.on('event:userInfoLoaded', handler)
  );


  loadData() {
    return this.$User.get(null, {full: false}).then((user: any) => {
      this.set(user);
      this.$rootScopeService.broadcast('event:userInfoLoaded');
      this.$csrfProtection.createCsrfHeaders();
    });
  }
}
