(function () {

  angular.module('kmi.lms.course.details')
    .component('enrolledLearnersModal', {
      template: require('ajs/modules/course/views/formats/live-event/components/enrolled-learners/modal/enrolled-learners-modal.html').default,
      controller: EnrolledLearnersModalController,
      controllerAs: 'vm',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  /* @ngInject */
  function EnrolledLearnersModalController(CourseSession) {
    var vm = this;

    vm.itemsPerPage = 10;
    vm.currentPage = 1;

    vm.$onInit = onInit;
    vm.cancel = cancel;
    vm.pageChanged = pageChanged;

    function onInit() {
      loadData();
    }

    function loadData() {
      vm.loading = true;

      var filters = {
        courseId: vm.resolve.courseId,
        sessionId: vm.resolve.sessionId,
        limit: vm.itemsPerPage,
        offset: (vm.currentPage - 1) * vm.itemsPerPage,
        group_categories: [140]
      };

      CourseSession.getLearners(filters).$promise.then(function (data) {
        vm.users = data.items;
        vm.count = data.count;
      }).finally(function () {
        vm.loading = null;
      });

    }

    function pageChanged() {
      if (vm.users && vm.count <= vm.users.length) {
        return;
      }

      loadData();
    }

    function cancel() {
      vm.modalInstance.dismiss('cancel');
    }
  }

})();
