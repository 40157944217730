(function () {

  angular.module('kmi.lms.courseProviders')
    .factory('CourseProviderOrganizationMember', courseProviderOrganizationMember);

  /* @ngInject */
  function courseProviderOrganizationMember($resource, $http) {
    var OrganizationMember = $resource('/a/course/providers/organizations/:organizationId/members/', {organizationId: '@id'}, {
      update: {method: 'PUT'}
    }, {
      stripTrailingSlashes: false
    });

    OrganizationMember.findPotentialMember = function (organizationId, params) {
      return $http.get('/a/course/providers/organizations/' + organizationId +
        '/find-potential-members/', {params: params})
        .then(function (response) {
          return response.data;
        });
    };

    return OrganizationMember;
  }
})();
