(function () {

  angular.module('kmi.lms.course.custom-list', [
    'kmi.lms.course.common'
  ]);
//     .config(moduleConfig);
//
//   /* @ngInject*/
//   function moduleConfig($stateProvider) {
//     $stateProvider
//       .state('main.recommendedCourses', {
//         url: '^/recommended-courses',
//         component: 'userFeaturedCourses',
//         data: {
//           label: 'Recommendations',
//           availableFor: ['regularUser', 'admin']
//         },
//         resolve: {
//           alwaysVisible: function () {
//             return true;
//           },
//           header: function ($state) {
//             var state = $state.get('main.recommendedCourses');
//             return state.data.label;
//           }
//         }
//       });
//   }
//
})();
