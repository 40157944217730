(function () {

  angular
    .module('elmsSpaApp')
    .factory('globalService', globalService)
    .config(moduleConfig);

  function globalService(currentUser, globalConfig, metaService, $transitions, rootScopeService, $window,
    lmsIntegrityService, backUrlService, $state, securityService, navigationService, elmsAuthService, menuService,
    locationHistory, $uiRouterGlobals, notificationService, serverErrorHandlerService, authorizationService,
    $uibModalStack, _, gtag, $timeout, $ngbModal, siteSettingService) {
    var service = {
      init: init,
      onAuthLoginRequired: onAuthLoginRequired
    };
    return service;

    function init() {
      const siteSetting = siteSettingService.getSiteSetting();
      metaService.description(siteSetting.description || '');

      // add handlers to ui-router events
      $transitions.onStart({}, onStateChangeStart);
      $transitions.onSuccess({}, onStateChangeSuccess);
      $transitions.onError({}, onStateChangeError);

      rootScopeService.on('$locationChangeSuccess', onLocationChangeSuccess);
      // add handlers to auth events
      rootScopeService.on('event:auth-loginRequired', onAuthLoginRequired);

      $window.addEventListener('online', function () {
        rootScopeService.broadcast('event:browserOnlineStatusChanged', true);
      });
      $window.addEventListener('offline', function () {
        rootScopeService.broadcast('event:browserOnlineStatusChanged', false);
      });
    }

    function onStateChangeError(transition) {
      var toState = transition.to(),
        toParams = transition.params(),
        fromState = transition.from(),
        error = transition.error();

      if (backUrlService.isSkipOnError() && _.includes([2, 6], error.type)) {
        backUrlService.goBack();
        return false;
      }


      //https://ui-router.github.io/ng1/docs/latest/enums/transition.rejecttype.html
      // ABORTED, INVALID
      if (_.includes([3, 4], error.type) && !securityService.isStateAvailable(fromState.name) || !fromState.name &&
        error.type === 6) {
        return accessDenied();
      }


      // ERROR
      if (error.type === 6) {
        if (_.get(error, 'detail.name', '') !== 'EmptyError') {
          serverErrorHandlerService.handleForbiddenError(error.detail)
            .catch(function (error) {
              if (error) {
                notificationService.error('The remote server is not available at this time. Try again later.', 3000);
              }
            });
        }
      }

      function accessDenied() {
        if (currentUser.get().anonymous) {
          navigationService.goToAccessDenied(toState, toParams, !!fromState.name);
        } else if (_.get(globalConfig, 'settings.accessDeniedEnabled')) {
          navigationService.goToAccessDenied(toState, toParams, !!fromState.name);
        } else {
          navigationService.goHome();
        }

        return false;
      }
    }

    function onStateChangeStart(transition) {
      var toState = transition.to(),
        toParams = transition.params(),
        fromState = transition.from(),
        fromParams = transition.params('from'),
        title;

      if (transition.dynamic()) {
        return;
      }

      title = getTitle(toState, toParams);
      metaService.title(_.capitalize((title || '').toLowerCase()));

      if (locationHistory.lastVisit) {
        navigationService.setTargetState(locationHistory.lastVisit.state);
        locationHistory.clearLastVisit();
      }

      /**
       * Close all modal windows. It'll work for the all existing situations.
       * If sometimes we'll need to leave some modals we'll must handle each modal separately
       */
      $uibModalStack.dismissAll();
      notificationService.dismissSuggestion();
      $ngbModal.dismissAll();

      //The code is to redirect to .net the pages, used only for train3
      if (toState.data && toState.data.external) {
        var params = angular.extend({
          from: backUrlService.external.getBackUrlParam($state.href(fromState.name, fromParams, {absolute: true}))
        }, toParams);

        var pathTemplate = _.template(toState.data.externalUrl), path = pathTemplate(params);

        if (path) {
          window.location.href = path;
          return;
        }
      }

      if (!securityService.isStateAvailable(toState.name)) {
        if (currentUser.get().anonymous) {
          navigationService.goToAccessDenied(toState, toParams, !!fromState.name);
        } else {
          navigationService.throwToEntryPoint(toState, toParams, !!fromState.name &&
            !_.isEqual(fromState.data.availableFor, ['anonymous']));
        }
        return false;
      } else {

        if (toState.data && toState.data.preventOpenByDirectLink && fromState.abstract) {
          navigationService.goHome();
        }

        if (!authorizationService.isStateAuthorized(toState.name, toParams)) {
          return authorizationService.checkRequirements()
            .then(function () {
              authorizationService.authorizeState(toState.name, toParams);
              transition.router.stateService.target(toState, toParams);
            }, function () {
              if (!authorizationService.isStateAuthorized(toState.name, toParams)) {
                navigationService.setTargetState({
                  name: toState.name,
                  params: toParams
                });
              }
            });
        }
      }
    }

    function onStateChangeSuccess(transition) {
      var toState = transition.to(),
        toParams = transition.params(),
        fromState = transition.from(),
        fromParams = transition.params('from');

      $timeout(gtag.sendPageView);

      if (transition.dynamic()) {
        return;
      }

      //scroll to TOP
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      backUrlService.stateChangeHandler(toState, toParams, fromState, fromParams);

      /**
       * Check for version changed and reload page if new version is available
       */
      lmsIntegrityService
        .checkVersionChanged()
        .then(function () {
        }, function () {
          locationHistory.holdLastVisit();
          $window.location.reload();
        });
    }

    function getTitle(state, stateParams) {
      var menuItems;
      if (_.get(globalConfig, ['customStateLabels', state.name]) || _.get(state, 'data.label')) {
        return _.get(globalConfig, ['customStateLabels', state.name]) || state.data.label;
      } else {
        menuItems = menuService.getMenuItems();

        for (var i = 0; i < menuItems.length; i++) {
          if (menuItems[i].state) {
            // if state has target param find title by target
            if (stateParams.target) {
              if (menuItems[i].stateParams && stateParams.target === menuItems[i].stateParams.target) {
                return menuItems[i].title;
              }
            } else if ($state.includes(menuItems[i].state, _.pickBy(menuItems[i].stateParams, function (n) {
              return !!n;
            }))) {
              return menuItems[i].title;
            }
          }
        }
      }

      return null;
    }

    function onLocationChangeSuccess(event, newUrl) {
      backUrlService.locationChangeHandler(event, newUrl);
    }

    function onAuthLoginRequired() {
      var toState = $state.transition && $state.transition.to(),
        toParams = $state.transition && $state.transition.params();

      navigationService.setTargetState({
        name: $state.current.name || toState,
        params: angular.copy(Object.keys($uiRouterGlobals.params).length > 0 ? $uiRouterGlobals.params : toParams)
      });

      if (!currentUser.get().anonymous) {
        elmsAuthService.logout()
          .then(() => currentUser.loadData())
          .then(goToLogin);
      } else {
        goToLogin();
      }

      function goToLogin() {
        if (!$state.is('prompt.login')) {
          $state.go('prompt.login');
        }
      }
    }
  }

  /* @ngInject */
  function moduleConfig($stateProvider, $urlRouterProvider, $httpProvider, globalConfig, $windowProvider) {
    var $window = $windowProvider.$get();

    // disable IE ajax request caching
    $httpProvider.defaults.cache = false;
    if (!$httpProvider.defaults.headers.get) {
      $httpProvider.defaults.headers.get = {};
    }
    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
    $httpProvider.defaults.headers.get.Pragma = 'no-cache';

    //http loading notification interceptors
    $httpProvider.interceptors.push('httpRequestStatusService');
    //http check permits interceptor
    $httpProvider.interceptors.push('httpRequestPermitService');
    $httpProvider.interceptors.push('httpRequestAuthentication');
    $httpProvider.interceptors.push('httpRequestCachingService');
    $httpProvider.interceptors.push('httpRequestLmsIntegrity');

    globalConfig.settings.deepLinkingExcludedStates.push('main.access-denied');

    $urlRouterProvider.otherwise(function ($injector) {
      var urlRouterDefaultView = $injector.get('urlRouterDefaultView');
      urlRouterDefaultView.get();
    });

    //bind project dependent ui router states from config
    for (var state in globalConfig.states) {
      if (globalConfig.states.hasOwnProperty(state) && globalConfig.states[state]) {
        $stateProvider.state(state, $window._.pickBy(globalConfig.states[state], function (objVal) {
          return objVal !== null;
        }));
      }
    }
  }
})();
