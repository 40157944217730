(function () {

  angular.module('kmi.lms.core')
    .directive('handleSearchSubmit', function () {
      return function (scope, element) {
        var textFields = element.find('input[type="text"]');

        element.bind('submit', function () {
          if (textFields.length > 0) {
            textFields[0].blur();
          }
        });
      };
    });
})();
