(function () {

  angular
    .module('kmi.lms.emails')
    .factory('emailTemplateService', emailTemplateService);

  /* @ngInject */
  function emailTemplateService($http) {
    return {
      sendSampleEmail: sendSampleEmail,
      getEmailPreview: getEmailPreview,
      getEmailTemplatePreview: getEmailTemplatePreview
    };

    function sendSampleEmail(data) {
      return $http.post('/a/emails/templates/' + data.id + '/sample-notification/', data);
    }

    function getEmailPreview(mailPatternId, variables) {
      return $http.put(['/a/emails/templates/', mailPatternId, '/sample-notification/'].join(''), {variables: variables})
        .then(function (response) {
          return response.data;
        });
    }

    function getEmailTemplatePreview(notification, courseId, variables) {
      // Please use this service instead of getEmailPreview
      return $http.put('/a/emails/templates/preview/' + notification, {courseId: courseId, variables: variables})
        .then(function (response) {
          return response.data;
        });
    }
  }
})();
