(function () {

  angular.module('kmi.lms.course.details')
    .factory('coursePrerequisitesService', coursePrerequisitesService);

  /* @ngInject */
  function coursePrerequisitesService($http) {
    return {
      getCoursePrerequisites: getCoursePrerequisites
    };

    function getCoursePrerequisites(courseId) {
      return $http.get(['/a/course/', courseId, '/prerequisites/'].join(''))
        .then(function (response) {
          return response.data;
        });
    }
  }

})();
