(function(){

  angular.module('kmi.lms.course.registrations')
    .component('changeTargetCompletionDateModal', {
      template: require('ajs/modules/course/registrations/components/modal/change-target-completion-date.html').default,
      controllerAs: 'vm',
      controller: ChangeTargetCompletionDateModalController,
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  /* @ngInject */
  function ChangeTargetCompletionDateModalController(userCoursesService, currentUser, notificationService) {
    var vm = this;

    vm.closeModal = closeModal;
    vm.changeDate = changeDate;
    vm.$onInit = $onInit;

    function $onInit() {
      vm.targetCompletionDate = vm.resolve.registration.target_completion_date;
    }

    function closeModal() {
      vm.modalInstance.close();
    }

    function changeDate(){
      if(vm.resolve.registration && !vm.changeTargetDateLocked) {
        vm.changeTargetDateLocked = userCoursesService.setTargetCompletionDate({
          registrationId: vm.resolve.registration.id ? vm.resolve.registration.id : null,
          courseId: vm.resolve.registration.course.id,
          userId: currentUser.get().id,
          targetCompletionDate: vm.targetCompletionDate
        }).then(function(res){
          if(res.status_id){
            vm.registration.statusId = res.status_id;
          }
        })
          .catch(function(error){
            notificationService.error(error.data, 5e3);
          })
          .finally(function(){
            vm.changeTargetDateLocked = false;
            closeModal();
          });
      }
    }
  }
})();

