(function () {

  angular
    .module('kmi.lms.credits')
    .factory('creditTypesService', creditTypesService);

  /* @ngInject */
  function creditTypesService($http) {
    return {
      getCourseCreditTypes: getCourseCreditTypes
    };

    function getCourseCreditTypes(courseId) {
      return $http.get(['/a/course/', courseId, '/credit-types/'].join(''))
        .then(function (response) {
          return response.data;
        });
    }
  }

})();
