(function () {

  angular.module('kmi.lms.course.common')
    .component('coursePublishDate', {
      template: require('ajs/modules/course/common/course-publish-date.html').default,
      controllerAs: 'vm',
      bindings: {
        course: '<'
      }
    });
})();
