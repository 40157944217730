(function () {

  angular.module('kmi.lms.course.details')
    .component('conferenceSessionSelectedCredits', {
      template: require('ajs/modules/course/views/formats/collection/conference/components/credits/session-credits.html').default,
      controller: ConferenceSessionSelectedCreditsController,
      controllerAs: 'vm',
      bindings: {
        session: '=',
        sessionUserCourse: '='
      }
    });

  /* @ngInject */
  function ConferenceSessionSelectedCreditsController($scope, courseRegistrationStatus, _) {
    const vm = this;

    vm.$onInit = onInit;
    vm.status = courseRegistrationStatus;

    vm.canReceiveCredits = canReceiveCredits;

    function onInit() {
      initCredits();

      $scope.$watch('vm.sessionUserCourse', function (){
        initCredits();
      });
    }

    function canReceiveCredits() {
      if (vm.sessionUserCourse && vm.sessionUserCourse.lastRegistration){
        return !vm.status.declinedSet.includes(vm.sessionUserCourse.lastRegistration.statusId) && vm.sessionUserCourse.lastRegistration.creditTypes.length;
      }

    }

    function initCredits() {
      vm.session.creditTypes = _.sortBy(angular.copy(vm.session.course.creditTypes), (ct) => {
        return _.get(ct, 'creditType.name', '').toLowerCase();
      });

      if (canReceiveCredits()) {
        const creditDict = _.keyBy(vm.session.creditTypes, 'creditType.id');

        _.get(vm.sessionUserCourse, 'lastRegistration.creditTypes', [])
          .forEach(function (selectedCredit) {
            try {
              if (!creditDict[selectedCredit.creditType.id]) {
                vm.session.creditTypes.push(angular.extend({selected: true}, selectedCredit));
              } else {
                creditDict[selectedCredit.creditType.id].selected = true;

                creditDict[selectedCredit.creditType.id].originalAmount = creditDict[selectedCredit.creditType.id].amount;
                creditDict[selectedCredit.creditType.id].amount = selectedCredit.amount;

                creditDict[selectedCredit.creditType.id].originalAccreditationAgency = creditDict[selectedCredit.creditType.id].accreditationAgency;
                creditDict[selectedCredit.creditType.id].accreditationAgency = selectedCredit.accreditationAgency;

                creditDict[selectedCredit.creditType.id].originalAccreditationAgencyId = creditDict[selectedCredit.creditType.id].accreditationAgencyId;
                creditDict[selectedCredit.creditType.id].accreditationAgencyId = selectedCredit.accreditationAgencyId;
              }
            } catch (e) {
              console.error(e);
            }
          });
      }
    }
  }
})();
