(function () {

  angular.module('kmi.lms.course.details')
    .component('courseCollectionDetails', {
      template: require('ajs/modules/course/views/formats/collection/collection-details.html').default,
      controller: CourseCollectionDetailsController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        userCourse: '='
      }
    });

  /* @ngInject */
  function CourseCollectionDetailsController() {
    var vm = this;

    vm.$onInit = onInit;
    vm.contentIsHidden = contentIsHidden;

    function onInit() {

    }

    function contentIsHidden(){
      return vm.course.showBlsAfterRegistration && (!vm.userCourse?.statusId || [10, 2, 42].includes(vm.userCourse.statusId));
    }
  }
})();
