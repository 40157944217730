(function () {

  angular.module('kmi.lms.courseProviders')
    .component('courseProviderOrganizationComponent', {
      template: require('ajs/modules/course/providers/organizations/view/organization.html').default,
      controller: CourseProviderOrganizationController,
      controllerAs: 'vm',
      bindings: {
        organization: '='
      }
    });

  /* @ngInject */
  function CourseProviderOrganizationController($state, _, searchService, backUrlService) {
    const vm = this;
    let cacheKey, courseSearchService;

    vm.$onInit = onInit;

    vm.state = $state;
    vm.backUrl = backUrlService;

    function onInit() {
      cacheKey = 'CPOrganization' + vm.organization.id;
      courseSearchService = searchService.get({mode: 'public'});

      findCourses();
    }

    function findCourses() {
      var cachedValue = JSON.parse(sessionStorage.getItem(cacheKey));

      if (cachedValue) {
        vm.coursesCount = cachedValue;
      }

      courseSearchService.search({
        stat_only: true,
        type: 'course',
        expired: true,
        cp_organization_id: vm.organization.id
      })
        .then(function (response) {
          vm.coursesCount = response.data.count;
          sessionStorage.setItem(cacheKey, JSON.stringify(vm.coursesCount));
        });
    }
  }
})();
