(function () {

  angular.module('kmi.lms.course', [
    'ui.router',
    'kmi.lms.core',
    'globalDependencies', 'ui.bootstrap'
  ])
    .value('desktopCoursePathRegex', /(<a\s+(?:[^>]*?\s+)?){1}href=(["'])(\/d\/course\/(\d+)\/[^'"]*)\2/g)
    .config(moduleConfig);

  /* @ngInject */
  function moduleConfig($provide) {
    $provide.decorator('$sce', function myServiceDecorator($state, $delegate, desktopCoursePathRegex) {
      var sce = $delegate;
      var getTrustedHtml = sce.getTrustedHtml;

      sce.getTrustedHtml = function (value) {
        var html = getTrustedHtml(value);

        if (desktopCoursePathRegex && html) {
          html = html.replace(desktopCoursePathRegex, '$1 href=' + decodeURIComponent($state.href('main.course', {id: '$4'})));
        }

        return html;
      };

      return sce;
    });
  }
})();
