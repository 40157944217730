(function () {

  angular.module('kmi.lms.course.details')
    .component('courseLiveEventDetails', {
      template: require('ajs/modules/course/views/formats/live-event/live-event.html').default,
      controller: CourseLiveEventDetailsController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        userCourse: '='
      }
    });

  /* @ngInject */
  function CourseLiveEventDetailsController(
    $scope, rootScopeService, $timeout, courseComponentsService, trainingService, _, courseDetailsState,
    courseRegistrationStatus, sessionsService
  ) {
    var vm = this;

    vm.registeredSessionsCompleted = false;

    vm.$onInit = onInit;

    function onInit() {
      vm.loading = true;

      vm.showExpiredAllSessions = courseDetailsState.modernFeaturesEnabled(vm.course);

      $scope.$on('event:course.registration.action.finished', updateAllSessions);

      $scope.$on('event:course.registration.updated', updateAllSessions);

      courseComponentsService.initSessions(vm.course.id)
        .then(function (sessions) {
          vm.course.sessions = sessions;
          vm.courseSessions = sessions;
          vm.course.hasOnlineMeetings = sessionsService.hasOnlineMeetings(vm.courseSessions);

          updateAllSessions(null, angular.extend({}, _.get(vm, 'workflow.registration')));
        });

      $scope.$on('event:course.details.ready', function () {
        vm.loading = null;
      });

      $scope.$on('event:course.registration.workflow.initialized', function (event, workflow) {
        if (workflow.registration.courseId === vm.course.id) {
          vm.workflow = workflow;
          updateAllSessions(null, workflow.registration);
        }
      });

      $scope.$on('event:liveEvent:scrollToSession', function () {
        var nextSession = trainingService.findNextSession(vm.availableSessions);
        if (nextSession) {
          rootScopeService.broadcast('event:scrollTo', 'courseSession' + nextSession.id, 'center');
        }
        vm.highlightedSession = nextSession;
      });

    }

    function updateAllSessions(event, registration) {
      prepareScheduledSessions(registration);
      prepareAllSessions();
    }

    function prepareScheduledSessions(registration) {
      trainingService.mergeSessionsData(vm.courseSessions, registration);

      vm.scheduledSessions = _.filter(vm.courseSessions, function (session) {
        // Return session with schedules and with associated non-withdrawn registration
        return (session.status_id > 0 && session.status_id !== 6 && session.status_id !== 3) && !!_.get(session, 'schedules.length');
      });

      // user has only completed (5, 40, 41, 42, 3, 6) sessions or has no at all
      vm.registeredSessionsCompleted = !_.some(vm.scheduledSessions, function (session) {
        return !_.includes([5, 40, 41, 42, 3, 6], session.status_id);
      });

      var completedSessionStatuses = angular.copy(courseRegistrationStatus.completedSet);
      completedSessionStatuses.push(courseRegistrationStatus.assessmentPending);
      completedSessionStatuses.push(courseRegistrationStatus.expired);
      vm.userHasCompletedSessions = _.some(vm.scheduledSessions, function (session) {
        return _.includes(completedSessionStatuses, session.status_id);
      });

      vm.userHasIncompletedSessions = sessionsService.userHasIncompletedSessions(vm.scheduledSessions);

      vm.scheduledSessions = _.sortBy(vm.scheduledSessions, function (session) {
        return new Date(session.schedules[0].start_date);
      });

      vm.scheduledSessionsAllExpired = !_.some(vm.scheduledSessions, {expired: false});
    }

    function prepareAllSessions() {
      vm.availableSessions = _.filter(vm.courseSessions, function (session) {
        return !!_.get(session, 'schedules.length');
      });

      vm.availableSessions = _.orderBy(vm.availableSessions, [function (session) {
        return new Date(session.schedules[0].start_date);
      }], 'desc');

      vm.sessionsAllExpired = !_.some(vm.availableSessions, {expired: false});
    }
  }
})();
