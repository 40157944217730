(function () {

  angular.module('kmi.lms.course.common')
    .component('conferenceSessionTags', {
      template: require('ajs/modules/course/common/session-tags.html').default,
      controller: ConferenceSessionTagsController,
      controllerAs: 'vm',
      bindings: {
        session: '=',
        clickHandler: '&?'
      }
    });

  /* @ngInject */
  function ConferenceSessionTagsController() {
    const vm = this;

    vm.selectTag = selectTag;

    function selectTag(tag) {
      if (vm.clickHandler) {
        vm.clickHandler({tag: tag});
      }

    }
  }
})();
