(function () {

  angular.module('kmi.lms.courseProviders')
    .component('courseProviderSurvey', {
      template: require('ajs/modules/course/providers/survey/provider-survey.html').default,
      controller: CourseProviderSurveyController,
      controllerAs: 'vm',
      bindings: {
        provider: '=',
        formOptions: '=',
        shortViewMode: '='
      }
    });

  /* @ngInject*/
  function CourseProviderSurveyController() {
    var vm = this;

    vm.changeAccredited = changeAccredited;
    vm.changeCreditsOffered = changeCreditsOffered;

    function changeAccredited(){
      if(!vm.provider.accredited){
        vm.provider.accreditation = null;
      }
    }

    function changeCreditsOffered(){
      if(!vm.provider.creditsOffered){
        vm.provider.credits = null;
      }
    }

  }

})();
