(function(){

  angular.module('kmi.lms.course.registrations')
    .component('workflowModalComponent', {
      template: require('ajs/modules/course/registrations/components/modal/workflow-modal.html').default,
      controller: WorkflowModalController,
      controllerAs: 'vm',
      bindings: {
        resolve: '=',
        modalInstance: '='
      }
    });

  /* @ngInject */
  function WorkflowModalController($scope, courseQuizTypes, courseRegistrationStatus, globalConfig, _) {
    const vm = this;
    vm.$onInit = onInit;

    vm.closeModal = closeModal;
    vm.nextState = nextState;
    vm.prevState = prevState;
    vm.getPrevButtonName = getPrevButtonName;
    vm.getNextButtonName = getNextButtonName;
    vm.projectName = globalConfig.projectName;
    vm.isNextStateDisabled = isNextStateDisabled;

    function onInit() {
      vm.workflow = vm.resolve.workflow;
      vm.workflow.stepsInProgress = true;
      vm.staticHeight = vm.workflow.stepsCount() > 1 &&
        !vm.workflow.actionRequiredPrerequisitesSequence.find(state => !!state.nextRestricted);
      vm.courseQuizTypes = courseQuizTypes;
      $scope.$on('event:course.registration.action.closeDialogs', closeSuccess);
    }

    function getPrevButtonName() {
      if (vm.workflow.currentState){
        if (vm.workflow.currentState.name === 'executionConfirmation' && vm.workflow.actionRequiredPrerequisitesIndex >=0){
          return 'Back';
        }

        if (vm.workflow.actionRequiredPrerequisitesSequence.length>1 && vm.workflow.actionRequiredPrerequisitesIndex > 0){
          return 'Back';
        }

        if (vm.workflow.currentStateHandlers && vm.workflow.currentStateHandlers.prevStateNameHandler &&
          vm.workflow.currentStateHandlers.prevStateNameHandler()){
          return vm.workflow.currentStateHandlers.prevStateNameHandler();
        }

        return vm.workflow.currentState.cancelButtonName || 'Cancel';
      }
    }

    function getNextButtonName() {
      // if step is not last show next
      if (vm.workflow.currentState){
        if (vm.workflow.currentState.nextRestricted){
          return vm.workflow.currentState.nextButtonName;
        }

        if (vm.workflow.actionRequiredPrerequisitesIndex < vm.workflow.actionRequiredPrerequisitesSequence.length - 1) {
          return 'Next';
        } else {
          // if current step is last we need to check confirmation step required, if yes return next
          if (vm.workflow.currentState.name !== 'executionConfirmation' && vm.workflow.confirmationState.required(vm.workflow.registration, vm.workflow.actionData)) {
            return 'Next';
          }

          if (vm.workflow.currentStateHandlers.nextButtonNameHandler && vm.workflow.currentStateHandlers.nextButtonNameHandler()) {
            // In current case for last step name of the button depends on data that selected on step.
            // No data means that confirmation step can be skipped, and can be calculated only inside step
            return vm.workflow.currentStateHandlers.nextButtonNameHandler();
          }

          if (!vm.workflow.currentState.destructive &&
            (!vm.workflow.registration.id || vm.workflow.registration.statusId === courseRegistrationStatus.notStarted)) {
            return 'Register';
          } else {
            return vm.workflow.currentState.nextButtonName || 'Continue';
          }
        }
      }
    }

    function isNextStateDisabled() {
      return vm.workflow.currentStateHandlers.stateDisabledHandler && vm.workflow.currentStateHandlers.stateDisabledHandler();
    }

    function nextState() {
      if (vm.isNextStateDisabled()) {
        return;
      } else {
        if (vm.workflow.currentStateHandlers.nextHandler){
          vm.workflow.currentStateHandlers.nextHandler(vm.workflow);
        } else {
          vm.workflow.nextState();
        }
      }
    }

    function prevState(){
      if (_.get(vm.workflow, 'currentStateHandlers.prevStateHandler')){
        vm.workflow.currentStateHandlers.prevStateHandler();
      } else {
        vm.workflow.prevState();
      }
    }

    function closeSuccess() {
      vm.workflow.stepsInProgress = false;
      vm.modalInstance.close();
    }

    function closeModal() {
      vm.workflow.stepsInProgress = false;
      vm.modalInstance.dismiss('cancel');
    }
  }
})();

