(() => {
  angular.module('kmi.lms.course.details')
    .component('conferencePrintableLocation', {
      template: require('ajs/modules/course/views/printable/components/conference-printable-location.html').default,
      controllerAs: 'vm',
      controller: ConferencePrintableLocationController,
      bindings: {
        course: '=',
        session: '=',
      }
    });

  /* @ngInject */
  function ConferencePrintableLocationController(_, $state, courseEnums) {
    const vm = this;

    vm.locationType = courseEnums.locationType;
    vm.isVirtual = isVirtual;
    vm.isInPerson = isInPerson;

    function isVirtual(sessionCourse) {
      return [vm.locationType.virtual, vm.locationType.inPersonAndVirtual].includes(_.get(sessionCourse, 'courseLocation.typeId'))
        && _.get(vm.session,'registration.lastRegistration.attendanceTypeId') === vm.locationType.virtual;
    }

    function isInPerson(sessionCourse) {
      return [vm.locationType.inPerson, vm.locationType.inPersonAndVirtual].includes(_.get(sessionCourse, 'courseLocation.typeId'))
        && _.get(vm.session,'registration.lastRegistration.attendanceTypeId') === vm.locationType.inPerson;
    }

    vm.getLocationUrl = () => {
      return $state.href('main.course',
        {
          id: vm.course.id,
          '#': 'session_' + vm.session.id
        },
        {
          absolute: true,
          inherit: false
        });
    };
  }

})();
