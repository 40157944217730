(function () {

  angular.module('kmi.lms.course.registrations')
    .component('selectRegistrationAttendanceTypeDialog', {
      bindings: {
        workflow: '='
      },
      template: require('ajs/modules/course/registrations/components/dialogs/select-attendance-type.html').default,
      controller: RegistrationAttendanceTypeDialogController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function RegistrationAttendanceTypeDialogController(courseEnums, _) {
    const vm = this;

    vm.$onInit = onInit;
    vm.locationType = courseEnums.locationType;

    function onInit() {
      vm.workflow.currentStateHandlers.stateDisabledHandler = stepProcessingDisabled;
      vm.virtualAvailable = vm.workflow.registration.course.attendVirtualCapacity - vm.workflow.registration.virtualAllocated;
      vm.inPersonAvailable = vm.workflow.registration.course.attendCap - vm.workflow.registration.inPersonAllocated;

      if (vm.inPersonAvailable < 0) {
        vm.inPersonAvailable = 0;
      }
      if (vm.virtualAvailable < 0) {
        vm.virtualAvailable = 0;
      }

      vm.virtualDisabled = vm.workflow.registration.course.attendVirtualCapacity > 0 && vm.virtualAvailable === 0 ||
        vm.workflow.currentState.name === 'changeAttendanceType' &&
        vm.workflow.actionData.attendanceTypeId === vm.locationType.virtual;
      vm.inPersonDisabled = vm.workflow.registration.course.attendCap > 0 && vm.inPersonAvailable === 0 ||
        vm.workflow.currentState.name === 'changeAttendanceType' &&
        vm.workflow.actionData.attendanceTypeId === vm.locationType.inPerson;

      if (!vm.workflow.actionData.attendanceTypeId) {
        // Init default value for attendance if not preselected
        vm.workflow.actionData.attendanceTypeId = !vm.inPersonDisabled ? vm.locationType.inPerson :
          (vm.virtualDisabled ? null : vm.locationType.virtual);
      }
    }

    function stepProcessingDisabled() {
      return !_.get(vm.workflow, 'actionData.attendanceTypeId') || vm.virtualDisabled && vm.inPersonDisabled ||
        vm.workflow.actionData.attendanceTypeId === vm.locationType.inPerson &&
        vm.workflow.registration.course.attendCap && vm.inPersonAvailable === 0 ||
        vm.workflow.actionData.attendanceTypeId === vm.locationType.virtual &&
        vm.workflow.registration.course.attendVirtualCapacity && vm.virtualAvailable === 0;
    }
  }
})();
