(function () {

  angular.module('kmi.lms.core')
    .component('entityAuthor', {
      template: require('ajs/modules/entities/entity-author.html').default,
      controller: EntityAuthorController,
      controllerAs: 'vm',
      bindings: {
        entity: '<',
        mode: '@?',
        viewMode: '<?'
      }
    });

  function EntityAuthorController() {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.createdByUser = vm.entity.createdByUser || vm.entity.createdBy;
      vm.updatedByUser = vm.entity.updatedByUser || vm.entity.updatedBy;
    }
  }
})();
