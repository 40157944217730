(function () {
  angular.module('kmi.lms.course.details')
    .component('trainingCategoriesListInfo', {
      bindings: {
        trainingCategories: '='
      },
      template: require('ajs/modules/course/views/components/training-categories-list-info.html').default,
      controllerAs: 'vm'
    });
})();
