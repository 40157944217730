
(function(){

  angular.module('kmi.lms.core')
    .factory('csrfProtection', csrfProtection);

  function csrfProtection($cookies, $http){
    var CSRF_TOKEN_NAME = 'X-CSRFToken';

    return {
      createCsrfHeaders: createCsrfHeaders
    };

    function createCsrfHeaders(){
      var csrfCookieValue = $cookies.get('csrftoken');

      $http.defaults.headers.common[CSRF_TOKEN_NAME] = csrfCookieValue;

      (<any>window).$.ajaxSetup({
        beforeSend: function(xhr) {
          xhr.setRequestHeader(CSRF_TOKEN_NAME, csrfCookieValue);
        }
      });
    }
  }
})();
