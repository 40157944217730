(function () {

  angular
    .module('kmi.lms.course.registrations')
    .component('enrollmentSessionResultModal', {
      template: require('ajs/modules/course/registrations/bulk-enrollment/modal/enrollment-session-result.html').default,
      controller: EnrollmentSessionResultModalController,
      controllerAs: 'vm',
      bindings: {
        resolve: '=',
        modalInstance: '='
      }
    });

  function EnrollmentSessionResultModalController($scope, $interval, courseBulkEnrollmentService,
    CourseBulkEnrollmentSession, serverErrorHandlerService, registrationWorkflowService, _) {
    const vm = this;
    let statusChecker, statusCheckerCanceler;

    vm.cancel = cancel;
    vm.$onInit = onInit;
    vm.processProgress = processProgress;
    vm.pageChanged = pageChanged;
    vm.loadStats = loadStats;
    vm.loadRecords = loadRecords;
    vm.getErrorMessage = getErrorMessage;
    vm.itemsPerPage = 5;
    vm.currentPage = 1;

    function onInit(): void {
      vm.enrollmentSession = vm.resolve.enrollmentSession;
      vm.showDetails = vm.resolve.showDetails;

      if (!vm.enrollmentSession.isProcessing()) {
        bindDetailedData();
      } else {
        statusChecker = $interval(function () {
          if (statusCheckerCanceler && statusCheckerCanceler.$cancelRequest) {
            statusCheckerCanceler.$cancelRequest();
          }

          statusCheckerCanceler = CourseBulkEnrollmentSession.get({sessionId: vm.enrollmentSession.id}).$promise
            .then(function (enrollmentSession) {
              _.extend(vm.enrollmentSession, enrollmentSession);

              if (!vm.enrollmentSession.isProcessing()) {
                bindDetailedData();
                $interval.cancel(statusChecker);
              }
            }, function (reason) {
              if (statusChecker) {
                $interval.cancel(statusChecker);
              }

              serverErrorHandlerService.handleForbiddenError(reason);
            });
        }, 10e3);

        $scope.$on('$destroy', function () {
          if (statusChecker) {
            $interval.cancel(statusChecker);
          }
        });
      }
    }

    function cancel(): void {
      vm.modalInstance.close();
    }

    function processProgress() {
      return vm.enrollmentSession.isProcessing() ?
        parseInt(((vm.enrollmentSession.recordsProcessed || 0) / (vm.enrollmentSession.recordsCount || 1) * 100), 10) :
        100;
    }

    function bindDetailedData() {
      if (!vm.enrollmentSession.isComplete()) {
        return;
      }

      loadStats();
      if (vm.showDetails) {
        loadRecords();
      }
    }

    function loadRecords() {
      courseBulkEnrollmentService.getRecords(vm.enrollmentSession.id).then((data) => {
        vm.records = data.items;
        pageChanged();
      });
    }

    function loadStats() {
      courseBulkEnrollmentService.getStats(vm.enrollmentSession.id).then(function (stats) {
        vm.stats = stats;
      });
    }

    function getErrorMessage(record) {
      if (record.errorMessage){
        if (registrationWorkflowService.isCheckState(record.errorMessage)){
          return registrationWorkflowService.getCheckStateNotification(record.errorMessage,
            record.enrollmentSessionCourse.course);
        }

        return record.errorMessage;
      } else {
        return 'Registration is ot allowed';
      }
    }

    function pageChanged() {
      vm.pageItems = _.slice(vm.records, (vm.currentPage - 1) * vm.itemsPerPage, vm.currentPage * vm.itemsPerPage);
    }
  }
})();
