(function () {

  angular.module('kmi.lms.core')
    .component('approvalStatusTag', {
      template: require('ajs/modules/common/approval-status-tag.html').default,
      controllerAs: 'vm',
      controller: ApprovalStatusTagController,
      bindings: {
        status: '<'
      }
    });

  /* @ngInject */
  function ApprovalStatusTagController(approvalStatus) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.statusName = approvalStatus.getStatusName(vm.status);
    }
  }
})();
