(function () {

  angular.module('kmi.lms.course.details')
    .component('conferenceSessionVenue', {
      template: require('ajs/modules/course/views/formats/collection/conference/components/session-venue.html').default,
      controller: ConferenceSessionVenueController,
      controllerAs: 'vm',
      bindings: {
        session: '=',
        sessionUserCourse: '='
      }
    });

  /* @ngInject */
  function ConferenceSessionVenueController(conferenceDetailsService, courseEnums, _){
    const vm = this;

    vm.isVirtual = isVirtual;
    vm.isInPerson = isInPerson;
    vm.isSessionJoinAllowed = conferenceDetailsService.isSessionJoinAllowed;
    vm.locationType = courseEnums.locationType;
    vm.sessionEnded = conferenceDetailsService.sessionEnded;

    function isVirtual(sessionCourse) {
      return [vm.locationType.virtual,
        vm.locationType.inPersonAndVirtual].includes(_.get(sessionCourse, 'courseLocation.typeId')) &&
        _.get(sessionCourse, 'courseLocation.virtualLocationUrl');
    }

    function isInPerson(sessionCourse) {
      return [vm.locationType.inPerson,
        vm.locationType.inPersonAndVirtual].includes(_.get(sessionCourse, 'courseLocation.typeId')) &&
        _.get(sessionCourse, 'courseLocation.locationDescription');
    }
  }
})();
