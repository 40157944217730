(function () {

  angular.module('kmi.lms.course.registrations')
    .component('registrationMenu', {
      bindings: {
        course: '<',
        registration: '=?',
        showDetails: '=?',
        showHistory: '=?',
        showRemoveAction: '=?',
        showTargetActions: '=?',
        alignMenu:'@?',
        mode: '@?',
      },
      template: require('ajs/modules/course/registrations/components/registration-menu.html').default,
      controller: RegistrationMenuController,
      controllerAs: 'vm',
    });

  /* @ngInject */
  function RegistrationMenuController($scope, $q, Course, CourseRegistration, LearningObjectRegistrationWorkflow,
    LearningObjectRegistration, currentUser, _, $location, globalConfig) {
    var vm = this;

    vm.settings = _.get(globalConfig, 'settings.courseDetails');
    vm.isAnonymous = currentUser.get().anonymous;
    vm.currentBlsId = $location.search().currentBlsId;

    vm.$onInit = activate;

    function activate() {
      if (!vm.mode) {
        vm.mode = 'link';
      }

      vm.currentBlsId = $location.search().currentBlsId;

      // Retrieve full registration info when options DDL becomes opened.
      $scope.$watch('vm.actionsOpen', function (newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
          setupRegistrationWorkflow();
        }
      });

      $scope.$on('event:course.registration.updatedAll', function () {
        vm.registrationData = null;
        vm.workflow = null;
        vm.actionsOpen = false;
      });

      $scope.$on('event:course.registration.updated', function (event, registration) {
        if (registration.courseId === _.get(vm, 'workflow.registration.courseId') &&
          _.get(vm, 'workflow.registration.status_id') !== registration.status_id &&
          !_.isEqual(vm.workflow.registration.actions, registration.actions)) {
          // When registration status is changed, clear workflow
          // It needs when we make changes from history or other registration workflow
          vm.registrationData = null;
          vm.workflow = null;
          vm.actionsOpen = false;
        } else {
          vm.registrationExecution = null;
        }
      });

      if (!vm.course || !vm.course.courseFormat) {
        var courseId = _.get(vm, 'course.id') || _.get(vm, 'registration.course.id');

        if (courseId) {
          Course.get({courseId: courseId}).$promise
            .then(function (course) {
              angular.extend(vm.course, course);
            });
        }
      }
    }

    function setupRegistrationWorkflow() {
      // Skip loading if registration data has already been retrieved
      if (!vm.registrationData) {
        // Get by Registration Id
        if (vm.registration && vm.registration.id) {
          CourseRegistration.get({registrationId: vm.registration.id}, function (registration) {
            vm.registrationData = LearningObjectRegistration.getFromReg(registration, vm.course);
            vm.registrationData.current_bls_id = vm.course.current_bls_id || vm.currentBlsId;
            vm.workflow = new LearningObjectRegistrationWorkflow(vm.registrationData);
          });
        } else { // Get by CourseId & UserId
          var user = currentUser.get();
          var userId = user ? user.id : null;

          LearningObjectRegistration.get(vm.course, userId)
            .then(function (registration) {
              vm.registrationData = registration;
              vm.registrationData.current_bls_id = vm.course.current_bls_id || vm.currentBlsId;
              vm.workflow = new LearningObjectRegistrationWorkflow(vm.registrationData);
            });
        }
      }
    }
  }
})();
