(function () {

  angular.module('kmi.lms.course.details')
    .component('courseArticleDetails', {
      controller: CourseArticleDetailsController,
      controllerAs: 'vm',
      template: require('ajs/modules/course/views/formats/online/article/article.html').default,
      bindings: {
        course: '='
      }
    });

  /* @ngInject */
  function CourseArticleDetailsController(CourseMediaTypes, _, currentUser) {
    var vm = this;

    vm.$onInit = activate;

    function activate() {
      vm.isAnonymous = currentUser.get().anonymous;

      vm.article = _.find(vm.course.media, function (mediaRecord) {
        return mediaRecord.type === CourseMediaTypes.article;
      });
    }
  }
})();
