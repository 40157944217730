(function () {

  angular.module('kmi.lms.course.custom-list')
    .controller('accompanyingCoursesCtrl', function($scope, promotionService){
      function loadCourses() {
        promotionService.getAccompanyingCourses($scope.course.id).then(function (response) {
          $scope.course.accompanyingCourses = response.data.courses;
        });
      }

      loadCourses();
    })
    .directive('accompanyingCourses', function() {
      return {
        restrict: 'EA',
        scope: {
          course: '='
        },
        template: require('ajs/modules/course/custom_list/accompanying-courses.html').default,
        controller: 'accompanyingCoursesCtrl'
      };
    });
})();
