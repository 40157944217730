(function () {

  angular.module('kmi.lms.core')
    .component('entityDetails', {
      template: require('ajs/modules/entities/entity-details.html').default,
      controller: EntityDetailsController,
      controllerAs: 'vm',
      bindings: {
        item: '<',
        type: '@?',
        itemUpdated: '&'
      }
    });

  /* @ngInject */
  function EntityDetailsController(LearningObjectRegistration, LearningObjectRegistrationWorkflow,
    courseRegistrationStatus, _, globalConfig) {
    var vm = this;

    vm.$onInit = onInit;
    vm.getStatusName = courseRegistrationStatus.getStatusName;

    function onInit() {
      vm.type = vm.type || vm.item.type;
      vm.minRatingCount = _.get(globalConfig, 'settings.courseDetails.minNumberOfReviewsToShowRating', 1);

      if (vm.type === 'course' && _.get(vm.item, 'courseFormat.registrationRequired')) {
        LearningObjectRegistration.get(vm.item)
          .then(function (registration) {
            vm.item.registration = registration;
            vm.item.workflow = new LearningObjectRegistrationWorkflow(registration);
            vm.item.registration.on('updated', function () {
              vm.itemUpdated({registrationId: vm.item.registration.id});
            });
          });
      }
    }
  }

})();


