(function () {

  angular.module('kmi.lms.course.registrations')
    .factory('CourseRegistration', CourseRegistrationService);

  /* @ngInject */
  function CourseRegistrationService($resource) {
    return $resource('/a/course_registrations/:registrationId/', {registrationId: '@id'}, {
      query: {
        method: 'GET',
        isArray: true
      },
      update: {method: 'PUT'},
      bulkUpdate: {
        method: 'PUT',
        isArray: true
      },
      getHistory: {
        method: 'GET',
        params: {registrationId: '@id'},
        isArray: true,
        url: '/a/course_registrations/:registrationId/history/',
        transformResponse: function (data, headersGetter, status) {
          if (status === 200) {
            return angular.fromJson(data);
          }

          if (headersGetter('Content-Type') === 'application/json') {
            return angular.fromJson(data);
          }
          return data;
        }
      }
    }, {
      stripTrailingSlashes: false
    });
  }
})();
