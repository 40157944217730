(function () {

  angular
    .module('kmi.lms.course.quizzes')
    .component('courseQuizResultViewComponent', {
      template: require('ajs/modules/course/quizzes/result-view.html').default,
      controller: CourseQuizResultViewController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        quizResult: '='
      }
    });

  /* @ngInject */
  function CourseQuizResultViewController(courseService, $window, backUrlService) {
    var vm = this;
    vm.$onInit = onInit;
    vm.backUrlService = backUrlService;

    vm.printResult = printResult;
    vm.copyCourseLink = copyCourseLink;

    function onInit() {
      vm.result = vm.quizResult;
    }

    function printResult() {
      $window.print();
    }

    function copyCourseLink() {
      courseService.copyCourseLink(vm.course.id);
    }
  }

})();
