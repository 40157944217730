(function () {

  angular.module('kmi.lms.core')
    .service('fileService', function ($window) {
      this.isImage = function (file) {
        var imageTypes = /(\.|\/)(gif|jpe?g|png|bmp|svg)$/i;
        return (imageTypes.test(file.type) || imageTypes.test(file.name));
      };

      this.isFile = function (item) {
        return angular.isObject(item) && item instanceof $window.File;
      };

      this.fileReaderSupported = function () {
        return !!($window.FileReader && $window.CanvasRenderingContext2D);
      };

      this.isHTML5 = !!($window.File && $window.FormData);
    });
})();
