(function () {

  angular.module('kmi.lms.courseProviders')
    .factory('CourseProvider', courseProviderService)
    .run(moduleRun);

  /* @ngInject */
  function courseProviderService($resource) {
    var CourseProvider = $resource('/a/course/providers/:providerId/', {providerId: '@id'}, {
      query: {
        method: 'GET',
        url: '/a/course/providers/',
        transformResponse: transformProvidersResponse,
        isArray: false
      },
      update: {method: 'PUT'}
    }, {
      stripTrailingSlashes: false
    });


    return CourseProvider;

    function transformProvidersResponse(data, headersGetter, status) {
      if (status === 200) {
        var wrapped = angular.fromJson(data);

        angular.forEach(wrapped.items, function (item, idx) {
          wrapped.items[idx] = new CourseProvider(item);
        });
        return wrapped;
      }

      return data;
    }
  }

  /* @ngInject */
  function moduleRun(entityDataService, $injector) {
    entityDataService.registerLoader('course_provider', function (id) {
      var Provider = $injector.get('CourseProvider');

      return Provider.get({providerId: id}).$promise;
    });
  }
})();
