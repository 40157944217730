(function(){

  angular.module('kmi.lms.ecommerce')
    .factory('Purchase', PurchaseService);

  /* @ngInject */
  function PurchaseService($resource, $state) {
    var Purchase = $resource('/a/ecommerce/purchases/:purchaseId/',
      {purchaseId: '@id'},
      {
        update: {method: 'PUT'}
      },
      {stripTrailingSlashes: false}
    );

    /**
     * @description
     * Instantiates Purchase with default parameters
     * @param attrs
     * @returns {*}
       */
    Purchase.newInstance = function(attrs){
      /**
       * PayPal needs to know a path where return user after payment submission. We need generate such path before payment
       * in order to send it to PayPal during obtaining security token
       */

      var receiptUrl = [
        '{0}',
        $state.href('main.checkout.confirmation', {purchaseId: '{1}'})
      ].join('');

      return new Purchase(angular.extend({
        payment: {
          urlMethod: 'GET'
        },
        returnUrl: receiptUrl
      }, attrs));
    };

    return Purchase;
  }

})();

