(function () {

  angular.module('kmi.lms.course.details')
    .component('conferenceSessionAlerts', {
      template: require('ajs/modules/course/views/formats/collection/conference/components/session-alerts.html').default,
      controller: ConferenceSessionAlertsController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        session: '=',
        sessionUserCourse: '=',
        conferenceUserCourse: '='
      }
    });

  /* @ngInject */
  function ConferenceSessionAlertsController(conferenceDetailsService, courseEnums, _) {
    const vm = this;

    vm.locationType = courseEnums.locationType;
    vm.getSessionAlert = getSessionAlert;

    function getSessionAlert() {
      let alerts = [];
      if (!vm.session) {
        return alerts;
      }

      let lastRegistration = _.get(vm.sessionUserCourse, 'lastRegistration', {});
      // if session is not support selected attendance show this message
      if (_.get(vm.session, 'course.courseLocation.typeId') !== vm.locationType.inPersonAndVirtual &&
        _.get(vm.session, 'course.courseLocation.typeId') !== lastRegistration.attendanceTypeId &&
        lastRegistration.attendanceTypeId) {
        alerts.push('attendanceIncorrect');
      }

      // if user is not accessed to the session and session has credits we need to add notification to select credits
      if (!conferenceDetailsService.isSessionRegistrationAllowed(vm.course, vm.session, vm.conferenceUserCourse) &&
        conferenceDetailsService.isSessionPlayButtonAllowed(vm.course, vm.session, vm.sessionUserCourse,
          vm.conferenceUserCourse)) {
        alerts.push('sessionEnded');
      }

      return alerts;
    }
  }
})();
