(function () {

  angular.module('kmi.lms.course.registrations')
    .component('courseRegistrationInfoExternal', {
      template: require('ajs/modules/course/registrations/view/components/registration-info-external.html').default,
      controller: CourseRegistrationInfoExternalController,
      controllerAs: 'vm',
      bindings: {
        registration: '='
      }
    });

  /* @ngInject */
  function CourseRegistrationInfoExternalController(globalConfig, elms, currentUser, backUrlService,
    courseRegistrationStatus, _) {
    var vm = this;

    vm.appSettings = globalConfig.settings;
    vm.backUrl = backUrlService;

    vm.isNumber = angular.isNumber;
    vm.formatDigit = elms.formatDigit;
    vm.superAdmin = currentUser.get().isInRole(1);
    vm.collectionProgressBarEnabled = _.get(globalConfig, 'courseRegistration.collectionProgressBarEnabled', true);
    vm.courseRegistrationStatus = courseRegistrationStatus;

    vm.$onInit = onInit;

    function onInit() {
      vm.registrationCredit = {
        creditType: vm.registration.creditType,
        amount: vm.registration.creditAmount
      };
    }
  }

})();
