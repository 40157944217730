(function () {

  angular.module('kmi.lms.core')
    .component('accessDenied', {
      template: require('ajs/modules/app/access-denied.html').default,
      controllerAs: 'vm',
      controller: AccessDeniedController
    });

  /* @ngInject */
  function AccessDeniedController(siteSettingService, $state, currentUser, globalConfig, navigationService) {
    var vm = this;

    vm.signInWording = globalConfig.signInWording;
    vm.externalDomain = globalConfig.externalDomain;
    vm.isAnonymous = currentUser.get().anonymous;
    vm.state = $state;
    vm.$onInit = onInit;

    function onInit() {
      if (!vm.isAnonymous) {
        navigationService.clearTargetState();
      }
      vm.siteSettings = siteSettingService.getSiteSetting();
    }
  }
})();
