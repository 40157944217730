(function(){

  angular.module('kmi.lms.core')
    .factory('metaService', MetaService);

  /* @ngInject */
  function MetaService($location) {
    var _title, _description, _url, _image, _type;

    var service = {
      title: title,
      description: description,
      url: url,
      image: image,
      type: type,
      setMeta:  setMeta,
      clearMeta: clearMeta
    };

    return service;

    //////////

    function title(str) {
      var originTitle = _title;
      if(str !== null && typeof str !== 'undefined') {
        _title = str;
      }
      return originTitle;
    }

    function description(str) {
      if(str !== null && typeof str !== 'undefined') {
        _description = str;
      }
      return _description;
    }

    function url(str) {
      if(str !== null && typeof str !== 'undefined') {
        _url = str ? _getFullUrl(str) : '';
      }
      return _url;
    }

    function image(str) {
      if(str !== null && typeof str !== 'undefined') {
        _image = str ? _getFullUrl(str) : '';
      }
      return _image;
    }

    function type(str) {
      if(str !== null && typeof str !== 'undefined') {
        _type = str;
      }
      return _type;
    }

    function setMeta(meta) {
      title(meta.title || '');
      _description = meta.description || '';
      _url = url(meta.url || '');
      _image = image(meta.image || '');
      _type = meta.type || '';
    }

    function clearMeta() {
      setMeta({});
    }

    function _getFullUrl(url) {
      if(url.indexOf('http') !== 0){
        var domain = [
          $location.protocol(),'://',
          $location.host(),
          $location.port() !== 80 && $location.port() !== 443 ? ':'+$location.port() : ''
        ].join('');
        url = domain + url;
      }
      return url;
    }
  }

})();
