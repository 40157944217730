(function () {

  /**
   * @ngdoc service
   * @name kmi.lms.core.service:authorizationRequirementsService
   *
   *
   * @description
   * Get login required steps
   */

  angular.module('kmi.lms.core')
    .factory('authorizationRequirementsService', authorizationRequirementsService);

  /* @ngInject */
  function authorizationRequirementsService($q, $state, $http, currentUser, apiUrl) {
    var requirementStates = {
      resetPassword: {name: 'prompt.resetPassword', params: {}},
      expiredPassword: {name: 'prompt.expiredPassword', params: {}},
      policiesSignOff: {name: 'policiesSignOff', params: {}},
      emailConfirmation: {name: 'prompt.emailVerificationRequired', params: {}},
      emsCheck: {name: 'edit.ems-checkup', params: {}},
      emsAttributes: {name: 'edit.account', params: {activeView: 'customAttributeForm480'}},
      userAttributes: {name: 'edit.account', params: {activeView: 'mainAttributesForm'}},
      userGroups: {name: 'edit.account', params: {activeView: 'mainAttributesForm'}},
      userAccount: {name: 'edit.account', params: {activeView: 'mainDescriptionForm'}},
      userContacts: {name: 'edit.account', params: {activeView: 'mainContactInfoForm'}},
      portalCompliance: {name: 'prompt.portalCompliance', params: {}},
      accountSurvey: {name: 'accountSurvey', params: {}},
      trainMandatorySurveys: {name: 'trainPendingCourseSurveys', params: {}}
    };


    return {
      verifyFulfillment: verifyFulfillment,
      getRequirementStates: getRequirementStates
    };

    function getRequirement() {
      return $http.get(apiUrl('/a/login/requirements/'))
        .then(function (response) {
          return response.data;
        });
    }

    function verifyFulfillment() {
      var defer = $q.defer();

      if (currentUser.get().anonymous) {
        defer.resolve(true);
        return defer.promise;
      }

      return getRequirement().then(promptForRequirement);

      function promptForRequirement(requirements) {
        if (requirements.length) {
          for (var i = 0; i < requirements.length; i++) {
            var requirement = requirements[i],
              requirementState = requirementStates[requirement];

            if ($state.get(requirementState.name) && $state.current.name !== requirementState.name) {
              defer.reject(requirementState);
              return defer.promise;
            }
          }

          defer.reject({name:'authorizationRequirement'}); // Default state
          return defer.promise;
        }

        defer.resolve(true);
        return defer.promise;
      }
    }

    function getRequirementStates() {
      return requirementStates;
    }
  }
})();
