(function () {

  angular.module('kmi.lms.core')
    .factory('groupService', groupService);

  /* @ngInject */
  function groupService($http, apiUrl) {
    return {
      getDefaultSelfRegGroups: getDefaultSelfRegGroups,
      getGroupAncestors: getGroupAncestors,
      getGroupCategories: getGroupCategories,
      checkGroupName: checkGroupName,
      validateCorporateLicenseCode: validateCorporateLicenseCode
    };

    function getDefaultSelfRegGroups(params) {
      return $http.get('/a/groups/self_registration/', {
        params: params,
        cache: true
      })
        .then(function (response) {
          return response.data;
        });
    }

    function validateCorporateLicenseCode(code) {
      return $http.get(`/a/groups/corporate_code/${code}/`, {cache: true})
        .then(function (response) {
          return response.data;
        });
    }

    function getGroupAncestors(groupId) {
      return $http.get('/a/groups/' + groupId + '/ancestors/', {cache: true})
        .then(function (response) {
          return response.data;
        });
    }

    function getGroupCategories() {
      return $http.get('/a/groups/categories/', {cache: true})
        .then(function (response) {
          return response.data;
        });
    }

    function checkGroupName(group, options) {
      return $http.post(apiUrl('/a/groups/check_name/'), {
        name: group.name,
        id: group.id,
        parentId: group.parentId
      }, options)
        .then(function (response) {
          return response.data;
        });

    }
  }
})();
