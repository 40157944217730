(function () {

  angular.module('kmi.lms.core')
    .factory('backUrlService', backUrlService);

  /* @ngInject */
  function backUrlService($state, navigationService, $location, $window, menuService, sjcl, locationHistory, _,
    EventDispatcher, securityService) {
    var skipOnError = false;
    var preventLocationCall = [];
    var preventStateCall = [];
    var initialBackUrl = null;
    var scope = {};
    var intentionToGo = null;

    var service = {
      transitionToLastState: transitionToLastState,
      stateChangeHandler: stateChangeHandler,
      locationChangeHandler: locationChangeHandler,
      hasHistory: hasHistory,
      forgetState: forgetState,
      updateState: updateState,
      isSkipOnError: isSkipOnError,
      passThroughRedirect: passThroughRedirect,
      isForwardMovement: isForwardMovement,
      bypassHistory: bypassHistory,
      clearDuplicateLastStateForRedirect: clearDuplicateLastStateForRedirect,
      goBack: goBack,
      restoreIntention: restoreIntention,
      goBackInHistory: goBackInHistory,
      external: {
        getBackUrlParam: getBackUrlParam,
        getBackUrlParamValue: getBackUrlParamValue,
        setExternalBackUrl: setExternalBackUrl
      }
    };

    EventDispatcher.call(service);

    return service;

    //////////////
    function goBack(skipOnErrorValue) {
      var targetState = navigationService.getTargetState();

      if (targetState) {
        navigationService.transitionToTargetState();
      } else {
        skipOnError = angular.isDefined(skipOnErrorValue) ? skipOnErrorValue : false;
        service.dispatchEvent('navigateBack');
      }
    }

    function goBackInHistory(skipOnErrorValue) {
      var state = locationHistory.pop();

      if (state) {
        return $state.go(state.name, state.params);
      }

      goBack(skipOnErrorValue);
    }

    function forgetState(state, params) {
      locationHistory.remove(state, params);
    }

    function updateState(stateName, oldParams, newParams) {
      locationHistory.replaceParams(stateName, oldParams, newParams);
    }

    function setExternalBackUrl() {
      var urlParams = getUrlParams($location.search());
      if (urlParams.backurl) {
        initialBackUrl = urlParams.backurl;
      }
    }

    function getExternalBackUrl() {
      return initialBackUrl;
    }

    function getUrlParams(queryParams) {
      // convert param keys to lowercase
      var newObj = _.mapKeys(queryParams, function (v, k) {
        return k.toLowerCase();
      });
      return newObj;
    }

    function clearDuplicateLastStateForRedirect(stateTo) {
      // When redirect to the state that same with last, remove last state.
      var state = locationHistory.getLastState();
      if (state && _.startsWith(state.name, stateTo)) {
        locationHistory.pop();
      }
    }

    function passThroughRedirect(to, params, options) {
      bypassHistory();

      $state.go(to, params, options);
    }

    function transitionToLastState(params) {
      var targetState;

      preventStateCall.length = 0;
      preventLocationCall.length = 0;

      targetState = navigationService.getTargetState();

      if (targetState) {
        navigationService.transitionToTargetState();
      } else {
        intentionToGo = locationHistory.pop();


        if (intentionToGo && intentionToGo.name && securityService.isStateAvailable(intentionToGo.name)) {
          bypassHistory();

          $state.go(intentionToGo.name, angular.extend(intentionToGo.params, params || {}));
        } else {
          // redirect to external back url if specified
          var externalBackUrl = getExternalBackUrl();
          if (externalBackUrl) {
            $window.location.href = sjcl.codec.utf8String.fromBits(sjcl.codec.base64.toBits(externalBackUrl));
            return;
          }
          navigationService.goHome();
        }
      }
    }

    function hasHistory() {
      return locationHistory.getLength() > 0;
    }

    function getBackUrlParam(absUrl) {
      return 'BackUrl=' + getBackUrlParamValue(absUrl);
    }

    function getBackUrlParamValue(absUrl) {
      return sjcl.codec.base64.fromBits(sjcl.codec.utf8String.toBits(absUrl || $location.absUrl()));
    }

    function isSkipOnError() {
      return skipOnError;
    }

    function stateChangeHandler(toState, toParams, fromState, fromParams) {
      skipOnError = false;

      if (toState.name === 'logout') {
        // didn't find the usage of this variable in the project.
        scope.lastPath = [];
      }

      if (preventStateCall.pop()) {
        return;
      }

      if (!fromState || !fromState.name) {
        return;
      }

      if (menuService.isMenuItem(toState.name)) {
        locationHistory.clear();
      } else {
        // if states or params are different
        if ($state.href(fromState.name, fromParams) !== $state.href(toState.name, toParams)) {
          locationHistory.push({
            name: fromState.name,
            params: angular.extend({}, _.omit(fromParams, '#'))
          });
        }
      }

      intentionToGo = null;
    }

    function locationChangeHandler() {
      if (preventLocationCall.pop()) {
        return;
      }

      // get last state from history
      var lastState = locationHistory.pop();
      if (!lastState) {
        return;
      }

      // if current url and the last url from history are the same
      if ($state.href(lastState.name, lastState.params) === '#' + $location.url()) {
        // do not save the state in the history in stateChangeHandler
        preventStateCall.push('locationChangeHandler');
      } else {
        // push the last url back to the history
        locationHistory.push(lastState);
      }
    }

    function isForwardMovement() {
      return !preventStateCall.length;
    }

    function bypassHistory() {
      // skip saving state in the history
      preventStateCall.push('transitionToLastState');
      // skip location event
      preventLocationCall.push('transitionToLastState');
    }

    function restoreIntention(){
      if (intentionToGo){
        locationHistory.push(intentionToGo);
        intentionToGo = null;
      }
    }
  }
})();
