(function () {

  angular.module('kmi.lms.courseProviders')
    .component('editCourseProviderSurveyComponent', {
      template: require('ajs/modules/course/providers/survey/edit-survey.html').default,
      controller: EditCourseProviderSurveyController,
      controllerAs: 'vm',
      bindings: {
        provider: '=',
        targetPage: '='
      }
    });

  /* @ngInject */
  function EditCourseProviderSurveyController(CourseProvider, backUrlService, notificationService, currentUser,
    $uiRouterGlobals, $state) {
    var vm = this, providerDraft, savePromise;
    vm.$onInit = onInit;

    vm.savePending = false;
    vm.formOptions = {submitted: false};
    vm.cancel = cancelEditCourseProvider;
    vm.submit = submitCourseProvider;

    function onInit() {
      vm.provider = vm.provider || new CourseProvider();
    }

    function cancelEditCourseProvider(){
      if($uiRouterGlobals.params.stepCourseProvider && $uiRouterGlobals.params.stepAccountCreated) {
        $state.go('prompt.accountCreated');
      } else if (vm.targetPage) {
        backUrlService.passThroughRedirect(vm.targetPage, {activeView:'mainDescriptionForm'});
        vm.savePending = false;
      }
    }

    function submitCourseProvider(){
      vm.formOptions.submitted = true;
      if (vm.editCourseProviderSurveyForm.$valid){
        vm.savePending = true;
        notificationService.info('Saving ...');
        providerDraft = angular.copy(vm.provider);
        providerDraft.userId = currentUser.get().id;
        savePromise = providerDraft.id ? providerDraft.$update() : providerDraft.$save();
        savePromise.then(function(){
          notificationService.hideInfo();
          cancelEditCourseProvider();
        }).catch(function(error){
          var message = 'An error occurred sending role request. Please try again later.';
          if (error.status === 403 && error.data && error.data.code) {
            message = error.data.message;
          }
          notificationService.hideInfo();
          notificationService.error(message, 5e3);
        }).finally(function(){
          vm.formOptions.submitted = false;
          vm.savePending = false;
        });
      } else {
        notificationService.error('Please fill in all required fields', 2e3);
      }
    }

  }
})();
