(function () {

  angular
    .module('kmi.lms.course.posts', [
      'kmi.lms.core',
      'kmi.lms.network',
      'kmi.lms.components',
      'kmi.lms.course.details'
    ]);

})();

