(function () {

  angular.module('kmi.lms.course.registrations')
    .component('courseRegistrationInfo', {
      template: require('ajs/modules/course/registrations/view/components/registration-info.html').default,
      controller: CourseRegistrationInfoController,
      controllerAs: 'vm',
      bindings: {
        registration: '=',
        permittedFor: '='
      }
    });

  /* @ngInject */
  function CourseRegistrationInfoController(
    $state, Course, globalConfig, currentUser, backUrlService, courseRegistrationStatus,
    courseService, _, quizEnums, LearningObjectRegistration, sessionsService, courseEnums, $window,
    userCertificatesService
  ) {
    const vm = this;

    vm.appSettings = globalConfig.settings;
    vm.backUrl = backUrlService;
    vm.locationType = courseEnums.locationType;

    vm.isNumber = angular.isNumber;
    vm.superAdmin = currentUser.get().isInRole(1);
    vm.courseFormatType = courseEnums.formatType;

    vm.reviewQuiz = reviewQuiz;
    vm.edit = showEdit;
    vm.copyCourseLink = copyCourseLink;
    vm.hasQuizResults = hasQuizResults;
    vm.startAdminAssessment = startAdminAssessment;
    vm.isActionAllowed = isActionAllowed;
    vm.isPrintScheduleAvailable = isPrintScheduleAvailable;
    vm.isReceiptAvailable = isReceiptAvailable;
    vm.hasMultipleActions = hasMultipleActions;
    vm.isCertificateResetAvailable = isCertificateResetAvailable;
    vm.resetCertificate = resetCertificate;

    vm.$onInit = onInit;

    function isActionAllowed(actionName) {
      return _.includes(vm.allowedActions, actionName);
    }

    function isCertificateResetAvailable(){
      return vm.showCertificates && vm.hasCertificates && vm.sertificateCachingEnabled;
    }

    function resetCertificate(){
      return userCertificatesService.resetCertificate(vm.registration.id);
    }

    function onInit() {
      // Get Course details.
      Course.get({
        courseId: vm.registration.courseId,
        full: true,
        allowSystemFormat: true,
        permitted_for: vm.appSettings.view === 'admin' ?
          vm.permittedFor.concat(['course.edit']) : null
      })
        .$promise
        .then(function (course) {
          vm.course = course;
          vm.quizIntendedForAdmin = vm.course.$checkPermission('courseRegistration.edit') &&
            vm.registration.statusId === courseRegistrationStatus.assessmentPending &&
            vm.registration.postAssessmentIntendedForModeId === quizEnums.quizIntendedFor.administrators;
        });

      if (vm.registration.sessionRoleId && vm.registration.sessionId) {
        sessionsService.getCourseSessionRoles(vm.registration.courseId, vm.registration.sessionId)
          .then(function (roles) {
            vm.sessionRoles = roles;
            vm.sessionRoleIdOriginal = vm.registration.sessionRoleId;
            vm.registrationSessionRole = _.find(vm.sessionRoles, {id: vm.registration.sessionRoleId});

            vm.registration.sessionRoleName = vm.registrationSessionRole.name;
          });
      }

      vm.sertificateCachingEnabled = $window.elmsEnvironment.settings.registrationCertificatesCachingEnabled;
      vm.showCertificates = !_.includes(courseRegistrationStatus.withdrawnSet, vm.registration.statusId);
    }

    function reviewQuiz(assessmentResult) {
      $state.go('main.courseQuizResult', {
        courseId: vm.registration.courseId,
        resultId: assessmentResult.id
      });
    }

    function showEdit() {
      backUrlService.passThroughRedirect('edit.registration', {
        courseId: vm.registration.courseId,
        id: vm.registration.id
      });
    }

    function copyCourseLink() {
      courseService.copyCourseLink(vm.course.id);
    }

    function hasQuizResults() {
      return vm.registration.preAssessment?.id || vm.registration.postAssessment?.id || vm.registration.preEvaluation?.id ||
        vm.registration.evaluation?.id || vm.registration.followUpEvaluation?.id ||
        vm.registration.exam?.id ||
        vm.isNumber(vm.registration.courseGradePoints) || vm.isNumber(vm.registration.courseGradePercentage) ||
        vm.isNumber(vm.registration.preAssessmentPoints) || vm.isNumber(vm.registration.preAssessmentPercentage);
    }

    function startAdminAssessment() {
      let url = ['/a/course_registrations/', vm.registration.id, '/workflow_actions/'].join('');
      let registration = LearningObjectRegistration.getFromReg(vm.registration);

      registration.executeAction('LaunchPostAssessmentAction', null, url)
        .then(function () {
          $state.go('view.course_content', {
            courseId: vm.registration.courseId,
            type: 'post',
            registrationId: vm.registration.id,
            current_bls_id: vm.registration.current_bls_id
          });
        });
    }

    function isPrintScheduleAvailable() {
      return !_.includes([3, 6], _.get(vm.registration, 'statusId', 6)) && vm.registration.active &&
        _.includes(_.get(globalConfig, 'settings.registration.printableSchedulesCourseFormats', []),
          _.get(vm.registration, 'course.format'));
    }

    function isReceiptAvailable() {
      return vm.registration && vm.registration.accessHasBeenGrunted;
    }

    function hasMultipleActions() {
      return isPrintScheduleAvailable() || isReceiptAvailable() || vm.isCertificateResetAvailable();
    }
  }
})();













