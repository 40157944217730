(function () {

  angular.module('kmi.lms.course.registrations')
    .component('userCourseHistoryModalComponent', {
      template: require('ajs/modules/course/registrations/components/modal/history-modal.html').default,
      controller: UserCourseHistoryModalController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        readOnly: '<?',
        hideCourseDetails: '<?'
      }
    });

  /* @ngInject */
  function UserCourseHistoryModalController(_, courseRegistrationService, elms, $scope, globalConfig,
    assessmentResultsService, currentUser, backUrlService, $window) {
    let vm = this;
    vm.$onInit = onInit;

    vm.backUrl = backUrlService;
    vm.isNumber = angular.isNumber;
    vm.formatDigit = elms.formatDigit;
    vm.creditTypeSelectionEnabled = _.get($window, 'elmsEnvironment.settings.creditTypeSelectionEnabled', false);
    vm.globalCourseRegistrationSettings = globalConfig.courseRegistration || {};


    function onInit() {
      vm.userId = currentUser.get().id;

      $scope.$on('event:course.registration.action.finished', function () {
        initHistory();
      });

      initHistory();
    }

    function initHistory() {
      courseRegistrationService.getHistory(vm.course.id, vm.userId).then(function (history) {
        vm.history = history;
      });
    }
  }
})();

