(function () {

  angular.module('kmi.lms.customAttributes.common')
    .component('customAttributesCategoryForms', {
      restrict: 'AE',
      replace: true,
      bindings: {
        model: '=',
        categories: '=',
        currentTab: '=',
        formOptions: '=',
        isFormIncomplete: '=',
        strictlyRequired: '=?',
        cacheEnabled: '=?'
      },
      template: require('ajs/modules/custom-attributes/common/category-form-template.html').default,
      controller: CustomAttributesCategoryForms,
      controllerAs: 'vm',
      bindToController: true
    });

  /* @ngInject */
  function CustomAttributesCategoryForms($scope, _, attributesService) {
    var vm = this;
    vm.$onInit = onInit;

    vm.initForm = attributesService.initForm;

    function onInit(){
      _.each(vm.categories, function(cat){
        attributesService.mergeWithValues(cat.attributes, vm.model.attributes, 'id');
      });
      $scope.$on('event:customAttributes.saving', prepareAttributesForSave);
    }

    function prepareAttributesForSave(event, model) {
      model.attributes = [];

      attributesService.prepareAttributesForSave(model, vm.categories);
    }

  }

})();
