(function () {

  angular.module('kmi.lms.courseProviders')
    .component('organizationSettingsView', {
      template: require('ajs/modules/course/providers/organizations/view/components/organization-settings-view.html').default,
      controller: OrganizationSettingsViewController,
      controllerAs: 'vm',
      bindings: {
        organization: '='
      }
    });

  /* @ngInject */
  function OrganizationSettingsViewController() {
    const vm = this;

    vm.$onInit = onInit;


    function onInit() {

    }
  }
})();
