(function () {

  angular
    .module('kmi.lms.core')
    .component('mainPortalActions', {
      template: require('ajs/modules/app/navigation/components/main-portal-actions.html').default,
      controller: MainPortalActionsController,
      controllerAs: 'vm',
      bindings: {
        onAction: '&?',
        showSeparator: '<?',
        viewportBreakpoint: '<?',
        hideSearchIcon: '<?',
      }
    });

  function MainPortalActionsController(rootScopeService, globalConfig, currentUser, securityService) {
    const vm = this;

    vm.userSettings = currentUser.get();
    vm.appSettings = globalConfig.settings;
    vm.login = login;
    vm.signInWording = globalConfig.signInWording.SignIn;
    vm.isStateAvailable = securityService.isStateAvailable;

    function login() {
      rootScopeService.broadcast('event:auth-loginRequired');
    }
  }
})();
