(function(){

  angular.module('kmi.lms.course.common')
    .factory('courseQuizTypes', courseQuizTypes);

  function courseQuizTypes(quizOptionsService, _){
    var types = {
      length: 0
    };
    var typeNames = {
      pre: 1,
      post: 2,
      evaluation: 3,
      preEvaluation: 7,
      followUpEvaluation: 8
    };

    quizOptionsService.getOptions().then(function (options) {
      var quizTypes = _.keyBy(_.filter(options.courseQuizTypes, {system: false}), 'id');
      _.map(quizTypes, function(type){
        var category = _.find(options.courseQuizTypeCategories, { id: type.categoryId });
        type.categoryName = category.name;
      });
      angular.extend(types, {pre: quizTypes[typeNames.pre]});
      angular.extend(types, {post: quizTypes[typeNames.post]});
      angular.extend(types, {evaluation: quizTypes[typeNames.evaluation]});
      angular.extend(types, {length: 3});
      if (quizTypes[typeNames.preEvaluation]){
        angular.extend(types, {preEvaluation: quizTypes[typeNames.preEvaluation]});
        types.length += 1;
      }
      if (quizTypes[typeNames.followUpEvaluation]){
        angular.extend(types, {followUpEvaluation: quizTypes[typeNames.followUpEvaluation]});
        types.length += 1;
      }
    });

    return types;
  }

})();
