(function () {

  angular
    .module('kmi.lms.course.common')
    .component('courseCompetenciesView', {
      template: require('ajs/modules/course/common/competencies/course-competencies.html').default,
      controller: CourseCompetenciesViewController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        adminView: '=',
        count: '=?',
        hideTitle: '=?',
      }
    });

  /* @ngInject */
  function CourseCompetenciesViewController($scope, $state, $uibModal, courseCompetencyService, _) {
    const vm = this;

    vm.addCompetencyModel = addCompetencyModel;
    vm.removeModel = removeModel;
    vm.$onInit = onInit;
    vm.coreCompetencies = [];

    function onInit() {
      vm.editable = vm.adminView && vm.course.editable();
      loadModelsStructure();

      $scope.$on('event:course:saved', function () {
        loadModelsStructure();
      });
    }

    function addCompetencyModel() {
      if (!vm.editable){
        return;
      }

      let modalInstance = $uibModal.open({
        component: 'selectCompetencyModelDialog',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          groups: function () {
            return _.map(vm.course.groups, 'id');
          }
        }
      });

      modalInstance.result.then((model) => {
        $state.go('edit.courseCompetencyModel', {modelId: model.id, id: vm.course.id});
      });
    }

    function loadModelsStructure() {
      if (vm.course.id){
        courseCompetencyService.get(vm.course.id, vm.adminView).then(responseData => {
          vm.coreCompetencies = responseData;
          vm.count = vm.coreCompetencies.length;
        });
      }
    }

    function removeModel(model) {
      if (!vm.editable){
        return;
      }

      courseCompetencyService.removeCourseModel(vm.course.id, model.id).then(() => {
        loadModelsStructure();
      });
    }
  }

})();
