(function () {

  angular.module('kmi.lms.course.common')
    .factory('CourseReview', CourseReviewService);

  /* @ngInject */
  function CourseReviewService($resource, currentUser, $uibModal, notificationService) {
    var CourseReview = $resource('/a/course/:courseId/reviews/:reviewId/', {
      courseId: '@courseId',
      reviewId: '@id'
    }, {
      query: {
        method: 'GET',
        params: {courseId: '@courseId'},
        url: '/a/course/:courseId/reviews/',
        transformResponse: function (data, headers, status) {
          if (status === 200) {
            var wrapped = angular.fromJson(data);
            angular.forEach(wrapped.items, function (item, idx) {
              wrapped.items[idx] = new CourseReview(item);
            });

            return wrapped;
          }

          //When status is not 200 response body may contain error message or some useful data.
          if (headers('Content-Type') === 'application/json') {
            return angular.fromJson(data);
          }
          return data;
        },
        isArray: false
      },
      update: {method: 'PUT'}
    }, {
      stripTrailingSlashes: false
    });

    CourseReview.newReview = function (attrs) {
      var user = currentUser.get();

      return new CourseReview(angular.extend({
        userName: user.firstName + ' ' + user.lastName,
        showName: true,
        rating: 0,
        date: new Date(),
        recommendCourse: true
      }, attrs));
    };

    CourseReview.shareCourse = function (course) {
      var modal = $uibModal.open({
        component: 'recommendCourse',
        keyboard: false,
        resolve: {
          course: function () {
            return course;
          }
        }
      });

      modal.result.then(function () {
        notificationService.info('Shared in the Community feed', 3e3);
      });
    };

    return CourseReview;
  }
})();
