(function () {

  angular.module('kmi.lms.course.details')
    .component('courseOnlineDetails', {
      template: require('ajs/modules/course/views/formats/online/online-course-format-details.html').default,
      controller: CourseOnlineDetailsController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        userCourse: '='
      }
    });

  /* @ngInject */
  function CourseOnlineDetailsController() {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {

    }
  }
})();
