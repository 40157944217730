declare const angular: angular.IAngularStatic;

export function configureApplicationSettings(
  $qProvider, laddaProvider, $provide,
  uibDatepickerConfig, $locationProvider, $resourceProvider, $urlServiceProvider
) {
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false
  });

  laddaProvider.setOption({
    style: 'expand-left'
  });

  $resourceProvider.defaults.stripTrailingSlashes = false;
  $urlServiceProvider.deferIntercept();
  $qProvider.errorOnUnhandledRejections(false);
  configureDatePicker($provide, uibDatepickerConfig);
}

function configureDatePicker($provide, uibDatepickerConfig) {
  uibDatepickerConfig.showWeeks = false;
  uibDatepickerConfig.minDate = new Date('1/1/1900');

  if (uibDatepickerConfig.minDate) {
    $provide.decorator('uibDatepickerPopupDirective', function ($delegate) {
      var directive = $delegate[0];

      var originalLink = directive.link;

      directive.compile = function () {
        return function (scope, element, attrs, ctrls) {
          var ngModel = ctrls[0],
            dateValidator;

          originalLink.apply(this, arguments);

          dateValidator = ngModel.$validators.date;

          ngModel.$validators.date = function (modelValue, viewValue) {
            var value = modelValue || viewValue;

            value = value && new Date(value);

            if (angular.isDate(value) && value) {
              if (value.getFullYear() < 100) { // don't process years like 0001 as 2001 etc
                return false;
              }

              const first = +new Date(value.getFullYear(), value.getMonth(), value.getDate());
              const second = +new Date(
                uibDatepickerConfig.minDate.getFullYear(),
                uibDatepickerConfig.minDate.getMonth(),
                uibDatepickerConfig.minDate.getDate()
              );

              if ((first - second) < 0) {
                return false;
              }
            }

            return dateValidator(modelValue, viewValue);
          };
        };
      };

      return $delegate;
    });
  }
}

export function configureApplicationRuntimeSettings(
  rootScopeService, backUrlService, csrfProtection, User, globalService,
  currentUser, gtag, moment, $window, lmsIntegrityService, elmsAuthService, _
) {
  elmsAuthService.authorised = _.get($window, 'elmsEnvironment.user.authorised');
  if (_.get($window, 'elmsEnvironment.user.authToken')) {
    elmsAuthService.authToken = $window.elmsEnvironment.user.authToken;
    delete $window.elmsEnvironment.user.authToken;
  }
  else {
    elmsAuthService.authToken = null;
  }

  currentUser.set(new User.getFromObj($window.elmsEnvironment.user)); // eslint-disable-line new-cap
  delete $window.elmsEnvironment.user;

  rootScopeService.on('$locationChangeSuccess', function () {
    gtag.sendPageView();
  });

  backUrlService.external.setExternalBackUrl();
  backUrlService.on('navigateBack', function () {
    backUrlService.transitionToLastState();
  });

  csrfProtection.createCsrfHeaders();
  moment.locale($window.navigator.userLanguage || $window.navigator.language);
  lmsIntegrityService.initBasePath();

  globalService.init();
}
