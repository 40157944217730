(function () {

  angular.module('kmi.lms.course.details')
    .component('courseRegistrationDueDateState', {
      template: require('ajs/modules/course/views/components/state-messages/due-date-indicator.html').default,
      controller: CourseRegistrationDueDateStateController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        userCourse: '='
      }
    });

  /* @ngInject */
  function CourseRegistrationDueDateStateController() {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.show = vm.course.courseFormat.registrationRequired && vm.userCourse.lastRegistration.dueDate
        && vm.userCourse.lastRegistration.statusId !== 6 && vm.userCourse.lastRegistration.statusId !== 3;
    }
  }
})();
