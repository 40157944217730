(function () {

  angular.module('kmi.lms.course.details')
    .controller('CourseDetailsBaseController', CourseDetailsBaseController);

  /* @ngInject */

  // controller used as part of directive
  function CourseDetailsBaseController(rootScopeService, $scope, $uiRouterGlobals, globalConfig, _, currentUser,
    courseDetailsState, moment, courseRegistrationStatus, $location, $timeout, gtag, courseRegistrationService,
    courseService, courseEnums, contentSectionsService) {
    const vm = this;
    vm.$onInit = onInit;

    vm.ready = false;
    vm.activeTab = 'about';
    vm.trackingName = 'course_details';
    vm.format = courseEnums.format;
    vm.checkSectionIsAvailable = contentSectionsService.checkSectionIsAvailable;

    vm.user = currentUser.get();
    vm.settings = globalConfig.settings;
    vm.network = globalConfig.settings.network;
    vm.adminView = globalConfig.settings.view === 'admin';
    vm.heroView = _.get(globalConfig, 'settings.courseDetails.heroImageDetails');
    vm.reviewsVisible = _.get(globalConfig.settings, 'courseDetails.reviewsVisible');
    vm.reviewsVisible = angular.isDefined(vm.reviewsVisible) ? vm.reviewsVisible : true;
    vm.certificatesTabVisible = _.get(globalConfig.settings, 'courseDetails.certificatesTabVisible');
    vm.registrationExpirationEnabled = globalConfig.settings.registrationExpirationEnabled;

    var filterStatuses = [courseRegistrationStatus.pending, courseRegistrationStatus.declined];

    vm.isExpired = isExpired;
    vm.willExpire = willExpire;
    vm.isAccessExpired = isAccessExpired;
    vm.isUserCourseExpired = isUserCourseExpired;
    vm.isResourcesTabVisible = isResourcesTabVisible;
    vm.isReviewsTabVisible = isReviewsTabVisible;
    vm.isRequiredInfoTabVisible = isRequiredInfoTabVisible;
    vm.expireCountdown = expireCountdown;
    vm.regNotDeclined = regNotDeclined;

    function onInit() {
      vm.modernFeatures = courseDetailsState.modernFeaturesEnabled(vm.course);
      vm.courseExpired = vm.course.deactivationDate && moment(vm.course.deactivationDate).diff(moment(), 'hours') <= 0;
      vm.notAvailableForRegistration = vm.course.publishDate && moment(vm.course.publishDate).diff(moment(), 'hours') >
        0;

      if (vm.heroView && vm.course.heroImage) {
        vm.heroBackground
          = 'linear-gradient(180deg, rgba(0, 0, 0, 0.7) -24.69%, rgba(0, 0, 0, 0.7) 100%), url(/a/course/' +
          vm.course.id + '/hero/)';
      }

      loadRegistrationData();

      $scope.$on('event:course.openReviews', onCourseOpenReviewsAction);
      $scope.$on('event:course.details.ready', onCourseDetailsReadyAction);
      $scope.$on('event:course.registration.updated', onCourseRegistrationUpdatedAction);

      $scope.$watch('vm.activeTab', function (newValue, oldValue) {
        if (newValue !== oldValue) {
          var search = $location.search();
          angular.extend(search, {activeTab: vm.activeTab});
          $location.search(search);
        }
      });

      $scope.$watch(function () {
        return $location.search();
      }, function () {
        var search = $location.search();
        if (search.activeTab) {
          vm.activeTab = search.activeTab;
        }
      });

      if ($uiRouterGlobals.params.activeTab) {
        vm.activeTab = $uiRouterGlobals.params.activeTab;
      } else if (vm.formatTabName) {
        vm.activeTab = 'registration';
      }

      courseService.setMetaInfo(vm.course);
      gtag.sendPageView();
    }

    function onCourseRegistrationUpdatedAction() {
      loadRegistrationData();
    }

    function onCourseOpenReviewsAction() {
      vm.activeTab = 'reviews';
    }

    function onCourseDetailsReadyAction() {
      var scrollTo = courseDetailsState.getScrollPosition();
      if (scrollTo && scrollTo !== 'discussions') {
        $timeout(function () {
          rootScopeService.broadcast('event:scrollTo', scrollTo);
          courseDetailsState.setScrollPosition(null);
        });
      }
    }

    function loadRegistrationData() {
      if (!vm.course.courseFormat.registrationRequired) {
        return;
      }

      courseRegistrationService.updateUserCourse(vm.userCourse, currentUser.get().id, vm.course)
        .then(function (newUserCourse) {
          vm.userCourse = newUserCourse;
          angular.extend(vm.course, vm.userCourse.course);
        })
        .finally(function () {
          vm.ready = true;
        });
    }

    function isResourcesTabVisible() {
      return (vm.course.resources && vm.course.resources.length > 0) ||
        (vm.course.documents && vm.course.documents.length > 0);
    }

    function isRequiredInfoTabVisible() {
      return vm.userCourse && vm.userCourse.workflow && vm.userCourse.workflow.registration &&
        (vm.userCourse.workflow.registration.status_id &&
          vm.userCourse.workflow.registration.status_id !== courseRegistrationStatus.notStarted);
    }

    function willExpire() {
      // TODO: Need to be refactor because calling near 100 times
      return vm.userCourse && vm.userCourse.accessExpirationDate && regNotDeclined();
    }

    function expireCountdown() {
      if (!vm.userCourse || !vm.userCourse.accessExpirationDate) {
        return null;
      }

      return moment(vm.userCourse.accessExpirationDate).fromNow();
    }

    function isAccessExpired() {
      // TODO: Need to be refactor because calling over 100 times
      if (!vm.userCourse || !vm.userCourse.accessExpirationDate) {
        return false;
      }
      return regNotDeclined() && isExpired(vm.userCourse.accessExpirationDate);
    }

    function isExpired(date) {
      return moment(date).diff(moment(), 'seconds') <= 0;
    }

    function isUserCourseExpired() {
      if (!vm.userCourse || !vm.userCourse.accessExpirationDate) {
        return false;
      }
      return regNotDeclined() && moment(vm.userCourse.accessExpirationDate).diff(moment(), 'seconds') <= 0;
    }

    function regNotDeclined() {
      return filterStatuses.indexOf(vm.userCourse.statusId) === -1;
    }

    function isReviewsTabVisible() {
      if (!vm.reviewsVisible) {
        // Setting on project level
        return false;
      } else if (!vm.course.ratings.visible) {
        // Course is not approved
        // or
        // Ratings disabled on backend side
        // or
        // User is not admin or user have not Course Reviews Manager role
        // or
        // User does not belong to the course group or reviews is disabled at the group level
        return false;
      }

      return true;
    }
  }
})();
