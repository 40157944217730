(function () {

  angular.module('kmi.lms.core')
    .factory('httpRequestAuthentication', httpRequestAuthentication);

  /* @ngInject */
  function httpRequestAuthentication($q, $injector) {
    return {
      'request': function (config) {
        var elmsAuthService = $injector.get('elmsAuthService');

        if (config && config.headers && !config.anonymous && config.url !== '/a/login/') {
          if (elmsAuthService.authToken) {
            config.headers.ElmsAuthorization = 'Token ' + elmsAuthService.authToken;
          }
        }

        return config || $q.when(config);
      },
      'response': function (response) {
        var elmsAuthService = $injector.get('elmsAuthService');

        if (response && response.headers) {
          var authToken = response.headers().authorization;
          if (authToken) {
            elmsAuthService.authToken = authToken;
          }
        }

        return response || $q.when(response);
      },
      'responseError': function (rejection) {
        var elmsAuthService = $injector.get('elmsAuthService');

        if (rejection && rejection.status === 403 && rejection.data === 'err_authentication_ticket_compliance') {
          var defer = $q.defer();
          elmsAuthService.showAuthTicketComplianceError();

          //Return a new unresolved promise to prevent erroneous data processing
          return defer.promise;
        }
        return $q.reject(rejection);
      }
    };
  }
})();
