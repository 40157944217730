(function () {

  angular.module('kmi.lms.course.registrations')
    .factory('registrationWorkflowDialogService', registrationWorkflowDialogsService);

  function registrationWorkflowDialogsService(moment, globalConfig, _) {
    return {
      isCheckState: isCheckState,
      getCheckStateNotification: getCheckStateNotification
    };

    function isCheckState(state) {
      return _.includes(['RegistrationDeadlineCheck', 'CourseRegOpenCheck', 'CoursePublishedCheck', 'SeatsCheck',
        'AvailabilityCheck', 'CourseExpirationCheck', 'RegistrationDeadlineCheck', 'EditRegistrationDeadlineCheck',
        'CourseLockedForUserCheck', 'HasRegRestriction', 'LimitedCoursesExceeded',
        'ComponentRegistrationRestrictedCheck', 'AnonymousUserCheck', 'CourseAttemptsCheck',
        'PreRequisitesCheck'], state);
    }

    function getCheckStateNotification(state, course) {
      switch (state) {
        case 'AvailabilityCheck':
          if (course && course.formatName){
            return 'Sorry, you don\'t have access to this "' + course.formatName + '"';
          } else {
            return 'Sorry, you don\'t have access.';
          }
        case 'ComponentRegistrationRestrictedCheck':
          return 'Registration for the collection is required to access this component.';
        case 'CoursePublishedCheck':
          if (course && course.publishDate) {
            return 'Available for registration on ' + moment(course.publishDate).format('ll');
          } else {
            return 'Not available for registration';
          }
        case 'RegistrationDeadlineCheck':
          if (course && course.registrationDeadline){
            return 'Registration closed on '+ moment(course.registrationDeadline).format('ll');
          } else {
            return 'Registration closed';
          }
        case 'CourseRegOpenCheck':
          if (course && course.registrationOpensDate) {
            return 'Available for registration on ' + moment(course.registrationOpensDate).format('ll');
          } else {
            return 'Not available for registration';
          }
        case 'SeatsCheck':
          return 'There are no seats available';
        case 'CheckCollectionRestrictionsState':
          return 'Registration is not allowed';
        case 'LimitedCoursesExceeded':
          if (course && course.formatName) {
            return 'You have reached the maximum number of registrations allowed for this "' +
              course.formatName + '".';
          } else {
            return 'You have reached the maximum number of registrations';
          }
        case 'HasRegRestriction':
          if (course && course.formatName){
            return 'You have already completed this "' + course.formatName + '".';
          } else {
            return 'You have already completed';
          }
        case 'PreRequisitesCheck':
          return 'Prerequisites are not met.';
        case 'CourseLockedForUserCheck':
          if (course && course.formatName) {
            return 'You are locked out of this "' + course.formatName +
              '", your administrator will need to unlock your registration before you can take this "' +
              course.formatName + '"';
          } else {
            return 'You are locked';
          }
        case 'CourseExpirationCheck':
          if (course && course.deactivationDate) {
            return 'Expired since ' + moment(course.deactivationDate).format('ll');
          } else {
            return 'Course expired';
          }
        case 'AnonymousUserCheck':
          if (course && course.formatTypeId){
            return (course.formatTypeId === 2 || course.formatTypeId === 7) ?
              'To access available sessions, please ' + globalConfig.signInWording.signIn + '.' :
              'To access this content, please ' + globalConfig.signInWording.signIn + '.';
          } else {
            return 'User can not register to the course';
          }
        case 'CourseAttemptsCheck':
          return 'You have reached the allowed number of attempts.';
      }
    }
  }
})();
