(function () {

  angular.module('kmi.lms.course.details')
    .component('collectionPrerequisites', {
      template: require('ajs/modules/course/views/components/prerequisites/collection-prerequisites.html').default,
      controller: CollectionPrerequisitesController,
      controllerAs: 'vm',
      bindings: {
        course: '<',
        registration: '=?',
        workflow: '=?'
      }
    });

  /* @ngInject */
  function CollectionPrerequisitesController(coursePrerequisitesService, _, moment) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      var isExpired = vm.course.deactivationDate && moment(vm.course.deactivationDate).diff(moment(), 'hours') < 0;

      if(vm.course.active && !isExpired && vm.course.courseFormat.registrationRequired){
        coursePrerequisitesService.getCoursePrerequisites(vm.course.id)
          .then(function (prerequisites) {
            vm.collectionPrerequisites = _.uniqBy(prerequisites.bls_prerequisites.concat(prerequisites.ls_prerequisites), 'course_id');
            vm.directPrerequisites = prerequisites.prerequisites;
          });
      }
    }
  }
})();
