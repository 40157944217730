(function () {

  angular.module('kmi.lms.course.registrations')
    .directive('courseRegistrationActions', courseRegistrationActions);

  function courseRegistrationActions() {
    return {
      restrict: 'EA',
      scope: {
        workflow: '=',
        showDetails: '=',
        showHistory: '='
      },
      template: require('ajs/modules/course/registrations/components/registration-actions.html').default,
      controller: CourseRegistrationActionsController
    };
  }

  function CourseRegistrationActionsController($scope, rootScopeService, userCertificatesService, currentUser,
    courseQuizTypes, $injector, courseRegistrationService, notificationService, _, trainingService) {

    $scope.anonymous = currentUser.get().anonymous;
    $scope.courseQuizTypes = courseQuizTypes;
    $scope.addComponentRegistrations = addComponentRegistrations;
    $scope.trainingService = trainingService;

    $scope.compilationStructureExportUrl
      = courseRegistrationService.compilationStructureExportUrl($scope.workflow.registration.course.id);


    // Feature availability checks

    $scope.countActions = function () {
      //counts workflow.registration.actions
      var count = 0;
      if ($scope.workflow && $scope.workflow.registration && $scope.workflow.registration.actions) {
        for (var i = 0; i < arguments.length; i++) {
          if ((typeof arguments[i] === 'string' && $scope.workflow.registration.actions.indexOf(arguments[i]) >= 0) ||
            (typeof arguments[i] === 'boolean' && arguments[i])) {
            count++;
          }
        }
      }
      return count;
    };

    $scope.moreActionsState = function () {
      var moreActionsCount = $scope.countActions('PrintAction', 'ChangeCreditTypeAction', 'WithdrawAction', $scope.isHistoryAvailable(), $scope.workflow.registration.isAddAllAvailable());

      if (moreActionsCount === 0) {
        //if there are no active 'more' actions
        return 'empty';
      } else if (moreActionsCount <= 1 || $scope.workflow.registration.actions.length - moreActionsCount <= 0) {
        //if there is only one or less 'more' action, or there are only 'more' actions
        return 'buttons';
      }

      return 'dropdown';
    };

    $scope.isHistoryAvailable = function () {
      return $scope.showHistory && !$scope.anonymous && $scope.workflow.registration.has_registration_history;
    };

    $scope.printCert = function () {
      userCertificatesService.printCertificate($scope.workflow.registration.certificates_data.registration_id);
    };

    function addComponentRegistrations() {
      return courseRegistrationService
        .addComponentRegistrations($scope.workflow.registration.course.id).then(function () {
          rootScopeService.broadcast('event:course.registration.updated', $scope.workflow.registration);
          $scope.workflow.registration.hasNonRegisteredComponents = false;
          notificationService.info('Courses have been added', 3e3);
        });
    }

  }
})();
