(function () {

  angular.module('kmi.lms.course.registrations')
    .component('enterRegistrationCodeDialog', {
      bindings: {
        workflow: '='
      },
      template: require('ajs/modules/course/registrations/components/dialogs/enter-registration-code.html').default,
      controller: EnterRegistrationCodeDialogController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function EnterRegistrationCodeDialogController() {
    const vm = this;
    vm.$onInit = onInit;

    function onInit() {
      vm.workflow.currentStateHandlers.stateDisabledHandler = isContinueDisabled;
    }

    function isContinueDisabled() {
      return vm.approvalCodeForm && vm.approvalCodeForm.$invalid;
    }

  }
})();
