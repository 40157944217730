(function () {

  angular.module('kmi.lms.course.registrations')
    .factory('UserCourse', UserCourseService);

  /* @ngInject */
  function UserCourseService($resource) {
    var UserCourse = $resource('/a/user/:userId/courses/:courseId/', {
      userId: '@userId',
      courseId: '@courseId'
    }, {
      query: {
        method: 'GET',
        params: {userId: '@userId'},
        cancellable: true
      },
      update: {method: 'PUT'}
    }, {
      stripTrailingSlashes: false
    });

    return UserCourse;

  }
})();

