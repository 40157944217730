(function () {

  angular.module('kmi.lms.course.details')
    .component('courseExpirationDateState', {
      template: require('ajs/modules/course/views/components/state-messages/expiration-date-indicator.html').default,
      controller: CourseExpirationDateStateController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        expiresSoonIndicator: '=?'
      }
    });

  /* @ngInject */
  function CourseExpirationDateStateController(moment) {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.courseExpired = vm.course.deactivationDate && moment(vm.course.deactivationDate).diff(moment(), 'minutes') < 0;
      vm.courseExpireSoon = vm.course.deactivationDate && vm.expiresSoonIndicator &&
        moment(vm.course.deactivationDate).diff(moment(), 'weeks') <= 2 && !vm.courseExpired;
    }
  }
})();
