(function () {

  angular.module('kmi.lms.core')
    .component('promptView', {
      controller: PromptViewController,
      controllerAs: 'vm',
      template: require('ajs/modules/app/prompt-view.html').default
    });

  function PromptViewController(siteSettingService){
    var vm = this;
    vm.siteSettings = siteSettingService.getSiteSetting();

    vm.currentDate = new Date();
    vm.siteSettings = siteSettingService.getSiteSetting();
  }

})();
