(function () {

  angular.module('kmi.lms.core')
    .component('staticPagesComponent', {
      template: function($state, globalConfig) {
        var templates = {
          'train': {
            'main.help.faq': require('ajs/custom_modules/train/static/faq.html').default,
            'main.help.about': require('ajs/custom_modules/train/static/about.html').default,
            'main.help.requirements': require('ajs/custom_modules/train/static/requirements.html').default,
            'main.help.accessibility': require('ajs/custom_modules/train/static/accessibility-statement.html').default
          }
        };

        return templates[globalConfig.projectName][$state.current.name];
      },
      controller: StaticPagesController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function StaticPagesController($scope, siteSettingService, backUrlService, globalConfig, currentUser) {
    var vm = this;

    vm.backUrl = backUrlService;
    vm.appSettings = globalConfig.settings;
    vm.currentUser = currentUser.get();
  }
})();
