(function () {

  angular.module('kmi.lms.course.registrations')
    .directive('actionButton', function () {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.css('display', 'none');

          scope.actionExists = function (actionName, actions) {
            return typeof angular.isDefined(actions) && actions && actions.indexOf(actionName) > -1;
          };

          function setButtonVisibility(el, s, actions, actionButton, additionalRestriction) {
            var visible = actions && s.actionExists(actionButton, actions) &&
              (!angular.isDefined(additionalRestriction) || additionalRestriction || additionalRestriction === true);

            el.css('display', visible ? '' : 'none');

          }

          var watchActions = attrs.watchActions;

          scope.$watch(angular.isDefined(watchActions) ? watchActions :
            'workflow.registration.actions', function (value) {
            setButtonVisibility(element, scope, value, attrs.actionButton, scope.$eval(attrs.restriction));
          });

          scope.$watch(attrs.restriction, function (value) {
            if (angular.isDefined(value)) {
              setButtonVisibility(element, scope, scope.$eval(angular.isDefined(watchActions) ? watchActions :
                'workflow.registration.actions'), attrs.actionButton, value);
            }
          });

          element.bind('click', function () {
            scope.$apply(function () {
              var workflow = scope.$eval(attrs.workflow) || scope.workflow;
              if (workflow) {
                workflow.exec(attrs.actionButton, scope.$eval(attrs.actionData));
              }
            });
          });

          if (scope.trackingName) {
            element.attr('id', scope.trackingName + '_' + attrs.actionButton);
          }

          scope.actionName = attrs.actionButton;
          scope.text = attrs.text;
        }
      };
    });
})();
