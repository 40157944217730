(function() {

  angular.module('kmi.lms.core')
    .component('mandatoryLabel', {
      template: require('ajs/modules/app/settings/mandatory-label.html').default,
      controller: MandatoryLabelController,
      controllerAs: 'vm',
      bindings: {
        isUpper: '<?',
        isLower: '<?'
      }
    });

  /* @ngInject */
  function MandatoryLabelController(globalConfig, _) {
    var vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.labelValue = _.get(globalConfig, 'settings.mandatoryLabel') || 'Mandatory';

      if (vm.isUpper) {
        vm.labelValue = _.toUpper(vm.labelValue);
      }

      if(vm.isLower) {
        vm.labelValue = _.toLower(vm.labelValue);
      }
    }
  }
})();
