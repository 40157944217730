(function () {

  angular.module('kmi.lms.course')
    .directive('actionButtonContainer', function (_) {
      return {
        restrict: 'A',
        link: function (scope, element) {
          element.css('display', 'none');
          scope.$watch(function () {
            return _.some(element[0].querySelectorAll('[action-button]'), function (e) {
              return (e.style.display !== 'none');
            });
          }, function (value) {
            element.css('display', value ? '' : 'none');
          });
        }
      };
    });
})();
