(function () {

  angular.module('kmi.lms.course.registrations')
    .component('collectionGenericRegistrationDetails', {
      template: require('ajs/modules/course/registrations/view/formats/collection/generic-collection-registration-details.html').default,
      controller: CollectionGenericRegistrationDetailsController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        registration: '='
      }
    });

  /* @ngInject */
  function CollectionGenericRegistrationDetailsController(
    courseComponentsService, collectionsService, globalConfig, _, elms
  ) {
    var vm = this;

    vm.collectionProgressBarEnabled = _.get(globalConfig, 'courseRegistration.collectionProgressBarEnabled', true);

    vm.formatDigit = elms.formatDigit;
    vm.$onInit = onInit;

    function onInit() {
      courseComponentsService.getCompilationStructure(vm.course.id, vm.registration.userId, {view: 'user'})
        .then(function (response) {
          vm.compilationStructure = response.structure;
          vm.compilationStat = collectionsService.aggregateRegistrationsInfo(vm.compilationStructure);
        });
    }
  }
})();
