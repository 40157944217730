(function () {

  angular.module('kmi.lms.course.common')
    .factory('Course', courseService)
    .run(moduleRun);

  /* @ngInject */
  function courseService($http, $q, $timeout, apiUrl, $resource, courseComponentsService, markdownService, _, moment,
    globalConfig, uuid, $cacheFactory, rootScopeService) {
    var courseCache = $cacheFactory.get('courseCache') || $cacheFactory('courseCache');

    //Prepare course resource
    var Course = $resource('/a/course/:courseId/', {
      courseId: '@id',
      application: globalConfig.settings.view
    }, {
      get: {
        method: 'GET',
        cache: courseCache,
        transformResponse: function (data, headers, status) {
          if (status === 200) {
            return Course.create(data);
          }
          if (headers('Content-Type') === 'application/json') {
            return angular.fromJson(data);
          }
          return data;
        }
      },
      delete: {
        method: 'DELETE',
        interceptor: {
          'response': function() {
            clearCache();
          }
        }
      },
      update: {
        method: 'PUT',
        interceptor: {
          'response': function() {
            clearCache();
          }
        }
      },
      clone: {
        method: 'POST',
        params: {clone: true, cloneOptions: '@cloneOptions'},
        hasBody: false,
        interceptor: {
          'response': function(response) {
            clearCache();
            return response.data;
          }
        }
      }
    }, {stripTrailingSlashes: false});

    Course.create = function (data) {
      var course = angular.extend(Course.defaults, angular.fromJson(data));
      Object.defineProperty(course, 'published', {
        get: function () {
          return !course.publishDate || moment(course.publishDate).isBefore(moment());
        },
        enumerable: true
      });

      course.prerequisites = _.map(_.get(course, 'prerequisites', []), function (item) {
        return Course.create(item);
      });

      return course;
    };

    Object.defineProperty(Course, 'defaults', {
      get: function () {
        return {
          files: {},
          active: true,
          visible: true,
          approvalStatusId: 5, //Incomplete
          scorm: false,
          prerequisiteTypeId: 2,
          autoRegisterComponents: true,
          allowRegisterComponents: false,
          documents: [],
          collectResultsAnonymously: false,
          registrationsAutoExpire: false,
          providesMultipleCredits: false,
          mayHaveMultipleQuizzes: false,
          autoVerify: true,
          skillLevelId: null,
          globalId: uuid.v4()
        };
      }
    });

    angular.extend(Course.prototype, {
      $checkPermission: function (permission) {
        return !!_.find(this.permissions, function (p) {
          return p.toLowerCase() === permission.toLowerCase();
        });
      },

      $processTempFiles: function () {
        var that = this;
        if (this.id && that.files && !_.isEmpty(that.files)) {
          return $http.post(apiUrl(['/a/course/media/', this.id,
            '/process_temp_files/'].join('')), {courseMedia: that.files, globalId: that.globalId}).then(function (response) {
            for (var property in response.data) {
              if (response.data.hasOwnProperty(property)) {
                if (response.data[property].success) {
                  delete that.files[property];
                }

                if (response.data[property].course) {
                  angular.extend(that, response.data[property].course);
                }
              }
            }

            return response;
          }, function (reason) {
            if (!angular.isString(reason.data)) {
              for (var property in reason.data) {
                if (reason.data.hasOwnProperty(property)) {
                  if (reason.data[property].success) {
                    delete that.files[property];
                  }

                  if (reason.data[property].course) {
                    angular.extend(that, reason.data[property].course);
                  }
                }
              }
            }
            return $q.reject(reason);
          });
        } else {
          var defer = $q.defer();
          defer.resolve();
          return defer.promise;
        }
      },
      $processTempImages: function () {
        var that = this;
        if (this.id) {
          return $http.post(apiUrl(['/a/course/media/', this.id,
            '/process_temp_images/'].join('')), that).then(function (response) {
            for (var property in response.data) {
              if (response.data.hasOwnProperty(property)) {
                if (response.data[property].success) {
                  delete that.files[property];
                }

                if (response.data[property].course) {
                  angular.extend(that, response.data[property].course);
                }
              }
            }

            return response;
          }, function (reason) {
            if (!angular.isString(reason.data)) {
              for (var property in reason.data) {
                if (reason.data.hasOwnProperty(property)) {
                  if (reason.data[property].success) {
                    delete that.files[property];
                  }

                  if (reason.data[property].course) {
                    angular.extend(that, reason.data[property].course);
                  }
                }
              }
            }
            return $q.reject(reason);
          });
        } else {
          var defer = $q.defer();
          defer.resolve();
          return defer.promise;
        }
      },
      $processTempDocuments: function () {
        var that = this;
        if (this.id && that.files && !_.isEmpty(that.files)) {
          return $http.post(apiUrl(['/a/course/media/', this.id,
            '/process_temp_documents/'].join('')), {documents: that.files}).then(function (response) {
            for (var property in response.data) {
              if (response.data.hasOwnProperty(property)) {
                if (response.data[property].success) {
                  delete that.files[property];
                }

                if (response.data[property].course) {
                  angular.extend(that, response.data[property].course);
                }
              }
            }

            return response;
          }, function (reason) {
            if (!angular.isString(reason.data)) {
              for (var property in reason.data) {
                if (reason.data.hasOwnProperty(property)) {
                  if (reason.data[property].success) {
                    delete that.files[property];
                  }

                  if (reason.data[property].course) {
                    angular.extend(that, reason.data[property].course);
                  }
                }
              }
            }
            return $q.reject(reason);
          });
        } else {
          var defer = $q.defer();
          defer.resolve();
          return defer.promise;
        }
      },
      setApprovalStatus: function (statusId) {
        var that = this;

        if (that.approvalStatusId === statusId) {
          var defer = $q.defer();
          defer.resolve();
          return defer.promise;
        } else {
          return $http.put(apiUrl('/a/course/' + this.id + '/'), {approvalStatusId: statusId})
            .then(function (response) {
              that.approvalStatusId = statusId;
              that.version = response.data.version;
              angular.extend(that.permissions, response.data.permissions);
              clearCache();
            }, function (reason) {
              that.approvalStatusId = 5;
              return $q.reject(reason);
            });
        }
      },
      editable: function () {
        return this.$checkPermission('course.edit') || this.$checkPermission('course.super_edit');
      },
      updateSection: function (courseSectionApi) {
        let url = ['/a/course', this.id, 'update', courseSectionApi.api, ''].join('/');
        let that = this;
        if (_.get(courseSectionApi, 'fields', []).includes('jobFiles')){
          that.jobFiles = that.files;
        }

        let dataToUpdate = courseSectionApi.fields ? _.pick(that, ['id'].concat(courseSectionApi.fields)) : that;

        return $http.put(apiUrl(url), angular.extend({version: that.version}, dataToUpdate))
          .then(function (response) {
            angular.extend(that, response.data);
            rootScopeService.broadcast('event:course:section:saved', that);
            clearCache();
          })
          .catch(function (reason) {
            return $q.reject(reason);
          });
      },
      updateActivity: function (active) {
        let that = this;
        return $http.put(apiUrl('/a/course/' + this.id + '/'), {active: active})
          .then(function (response) {
            angular.extend(that, response.data);
            clearCache();
          })
          .catch(function (reason) {
            return $q.reject(reason);
          });
      },
      $getHtmlField: function (name) {
        return markdownService.toHtml(this[name]);
      },
      initDocuments: function () {
        var that = this;
        return $timeout(function () {
          return $http.get(apiUrl('/a/course/' + that.id + '/documents/')).then(function (response) {
            angular.extend(that, {documents: response.data});
            return that.documents;
          });
        });
      },
      getCompilationStructure: function (includeInactive) {
        if (this.id) {
          var that = this;
          return courseComponentsService
            .getCompilationStructure(this.id, null, {
              includeInactive: includeInactive,
              permitted_for: 'course.edit'
            })
            .then(function (response) {
              function convertItemToCourse(item) {
                if (_.get(item, 'type') === 1) {
                  return Course.create(item);
                } else if (_.get(item, 'type') === 2) {
                  item.items = _.map(_.get(item, 'items', []), convertItemToCourse);
                }
                return item;
              }

              return angular.extend(that, {compilationStructure: _.map(response, convertItemToCourse)});
            });
        } else {
          this.compilationStructure = this.compilationStructure || [];
          return $q.when(this);
        }
      },
      getTagsString: function () {
        return (this.tags && this.tags.length > 0) ? _.map(this.tags, function (tag) {
          return tag.name;
        }).join(',') : null;
      },
      isCollection: function () {
        return _.get(this, 'format', 0) === 104 || _.get(this, 'format', 0) === 117;
      },
      isRelatedContentAvailable: function () {
        return this.isCollection() && _.size(this.tags) > 0;
      },
      updateTempFile: function (videoType, fileName) {
        if (fileName) {
          this.files[videoType] = fileName;
        } else {
          if (this.files && this.files[videoType]) {
            delete this.files[videoType];
          }
        }
      },
      updateMedia: function (mediaData) {
        if (mediaData) {
          let mediaItem = _.find(this.media, {id: mediaData.id}) || mediaData.data &&
            _.find(this.media, function (item) {
              return item.id === undefined && mediaData.id === undefined && item.type === mediaData.type;
            });
          if (mediaItem) {
            angular.extend(mediaItem, mediaData);
          } else if (mediaData.data) {
            if (!this.media){
              this.media = [];
            }
            this.media.push(mediaData);
          }
        }
      },
      clearCache() {
        clearCache();
      }
    });

    function clearCache() {
      let courseCache = $cacheFactory.get('courseCache') || $cacheFactory('courseCache');
      courseCache.removeAll();
    }

    return Course;
  }

  /* @ngInject */
  function moduleRun(entityDataService, $injector) {
    entityDataService.registerLoader('course', function (id) {
      var Course = $injector.get('Course');
      return Course.get({courseId: id}).$promise;
    });
  }
})();
