(function () {

  angular.module('kmi.lms.coursePresenters')
    .component('coursePresenterView', {
      template: require('ajs/modules/course/presenters/components/presenter-view.html').default,
      controller: CoursePresenterViewController,
      controllerAs: 'vm',
      bindings: {
        presenter: '='
      }
    });

  /* @ngInject */
  function CoursePresenterViewController() {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {

    }
  }
})();
