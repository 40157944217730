(function () {

  angular
    .module('kmi.lms.course.registrations')
    .directive('registrationActionMenuVisible', registrationActionMenuVisible);
  /* @ngInject*/
  function registrationActionMenuVisible() {
    var directive = {
      restrict: 'A',
      link: link,
      scope: {
        visibleActionsCount: '=',
        registrationActionMenuVisible:'='
      }
    };
    return directive;

    function link(scope, element) {
      scope.$watch('registrationActionMenuVisible', function (value) {
        var hiddenActionsCount = element.parent().find('a[style*="display: none"]').length;
        var allActionsCount = element.parent().find('a').length;

        scope.visibleActionsCount = allActionsCount - hiddenActionsCount;

        if (value && hiddenActionsCount === allActionsCount) {
          element.append('<span>No available actions</span>');
        }

      });
    }
  }
})();
