(function () {

  angular.module('kmi.lms.course.details')
    .component('modernCourseDetails', {
      template: require('ajs/modules/course/views/modern-course-details.html').default,
      controller: 'CourseDetailsBaseController',
      controllerAs: 'vm',
      bindings: {
        course: '=',
        userCourse: '=?',
        formatTabName: '@',
        modernView: '<?'
      }
    });
})();
