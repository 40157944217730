(function () {

  angular.module('kmi.lms.core')
    .constant('adminViewAllowedAnonymousStates', [
      'prompt.login', 'main.access-denied', 'prompt.forgotPassword',
      'main.contacts', 'main.support'])
    .factory('securityService', securityService);

  /* @ngInject */
  function securityService(
    $state, $injector, globalConfig, currentUser, userSettingsService,
    adminViewAllowedAnonymousStates, _
  ) {
    return {
      isStateAvailable: isStateAvailable,
      isPageAvailable: isPageAvailable
    };

    function isStateAvailable(stateName) {
      var state = $state.get(stateName);

      return state && isPageAvailable(state);
    }

    function isPageAvailable(options) {
      const adminView = _.get(globalConfig, 'settings.view') === 'admin';

      let user = currentUser.get(),
        hasPermission, envProhibited, settingEnabled = true, availableFor;

      if (adminView && !adminViewAllowedAnonymousStates.includes(options.name)) {
        availableFor = ['admin'];
      } else {
        availableFor = _.get(options, 'data.availableFor');
      }

      const adminRoleLimits = _.get(options, 'data.adminRoleLimits');
      const adminRoleExclusions = _.get(options, 'data.adminRoleExclusions');
      const adminPermittedFor = _.get(options, 'data.permittedFor');
      const environment = _.get(options, 'data.environment');

      hasPermission = !availableFor ||
          (_.includes(availableFor, 'anonymous') && user.anonymous) ||
          (_.includes(availableFor, 'regularUser') && user.isRegularUser() && !user.anonymous) ||
        (_.includes(availableFor, 'admin') && user.roles && user.roles.length &&
          (!_.get(adminRoleLimits, 'length') || _.intersection(adminRoleLimits, user.getRoleIds()).length) &&
          (!_.get(adminRoleExclusions, 'length') || !_.intersection(adminRoleExclusions, user.getRoleIds()).length) &&
          (!_.get(adminPermittedFor, 'length') || user.hasPermissions(adminPermittedFor))
        );

      if (_.get(options, 'data.dependsOn')) {
        if (typeof options.data.dependsOn === 'string') {
          settingEnabled = userSettingsService[options.data.dependsOn];
        }
        if (typeof options.data.dependsOn === 'function') {
          settingEnabled = options.data.dependsOn($injector);
        }
      }

      envProhibited = environment !== 'local' && environment && !_.includes(environment, globalConfig.environment);

      return hasPermission && settingEnabled && !envProhibited;
    }
  }
})();
