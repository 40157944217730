(function () {

  angular.module('kmi.lms.core')
    .directive('lmsMeta', lmsMeta);

  function lmsMeta(siteSettingService, metaService) {
    return {
      restrict: 'A',
      link: link
    };

    function link(scope, element, attrs) {
      const siteSetting = siteSettingService.getSiteSetting();
      metaService.description(siteSetting.description || '');
      scope.$watch(function () {
        if (attrs.lmsMeta === 'description') {
          return metaService.description();
        }
      }, function (newValue) {
        if (newValue && !angular.equals(newValue, element[0].content)) {
          element[0].content = newValue;
        }
      }, true);
    }
  }
})();
