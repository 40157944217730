(function () {

  angular.module('kmi.lms.course.common')
    .factory('courseDetailsState', courseDetailsState);

  /* @ngInject */
  function courseDetailsState(rootScopeService, $state, backUrlService, _, $location, globalConfig) {
    var DEFAULT_COURSEDETAILS_STATE = 'main.course.default';
    var scrollTo = null;

    return {
      go: go,
      modernFeaturesEnabled: modernFeaturesEnabled,
      setScrollPosition: setScrollPosition,
      getScrollPosition: getScrollPosition
    };

    function setScrollPosition(value) {
      scrollTo = value;
    }

    function getScrollPosition() {
      return scrollTo;
    }

    function go(course, params) {
      var formatDetailsState = getCourseFormatState(course);
      var formatStateName = formatDetailsState ? formatDetailsState.name : $state.get(DEFAULT_COURSEDETAILS_STATE).name;
      if (!$state.includes(formatStateName)) {
        redirectToState(formatStateName, angular.extend(params || {}, {id: course.id}));
      }
    }

    function getCourseFormatState(course) {
      var formatState = _.find($state.get(), function (state) {
        return isCourseViewState(state) && state.data.courseFormat === course.format;
      });

      return formatState || _.find($state.get(), function (state) {
        return isCourseViewState(state) && _.includes(state.data.courseFormatTypes, course.formatTypeId);
      });

      function isCourseViewState(state) {
        return _.startsWith(state.name, 'main.course.') && state.data;
      }
    }

    function redirectToState(state, params) {
      rootScopeService.evalAsync(function () {
        var opts = {};
        //this happens when we get to wrong/abstract 'main.course.' sate by location rather than $state.go.
        //in case of $state.go redirect this code is executed prior to updating browser's location property and
        //saving wrong/abstract state url to history, so no location:'replace' option is required.
        //this behavior may be changed depending on ui-router version.
        if ($location.absUrl() === window.location.href) {
          opts = {location: 'replace'};
        }

        backUrlService.passThroughRedirect(state, params, opts);
      }, 0);
    }

    function isModernFeaturesEnabledFor(formatType) {
      return _.get(globalConfig, 'settings.courseDetails.modernFeatures') && (
        !_.get(globalConfig, 'settings.courseDetails.modernFeaturesFormatTypes') ||
        _.includes(globalConfig.settings.courseDetails.modernFeaturesFormatTypes, formatType)
      );
    }

    function modernFeaturesEnabled(course) {
      return _.get(globalConfig, 'settings.courseDetails.modernFeatures') &&
        (isModernFeaturesEnabledFor(course.formatTypeId) ||
          (_.get(globalConfig, 'settings.courseDetails.modernFeaturesFormats') &&
            _.includes(globalConfig.settings.courseDetails.modernFeaturesFormats, course.formatId)
          )
        );
    }
  }
})();
