(function () {

  angular
    .module('kmi.lms.course.reviews', [
      'kmi.lms.core',
      'kmi.lms.course.common',
      'kmi.lms.user.common',
      'kmi.lms.network'
    ])
    .constant('courseReviewsConst', {
      REQUEST_ITEM_COUNT: 30,
      SORT_RULES: {
        newest: {
          id: 'newest',
          name: 'Newest',
          rule: null
        },
        highest: {
          id: 'highest',
          name: 'Highest',
          rule: [{field: 'rating', direction: 'desc'}]
        },
        lowest: {
          id: 'lowest',
          name: 'Lowest',
          rule: [{field: 'rating'}]
        }
      }
    });

})();

