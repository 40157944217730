(function () {

  angular.module('kmi.lms.core')
    .component('lmsNotifications', {
      template: require('ajs/modules/app/lms/lms-notifications.html').default,
      controllerAs: 'vm',
      controller: ElmsNotificationsController
    });

  /* @ngInject */
  function ElmsNotificationsController(notificationService) {
    var vm = this;

    vm.notification = notificationService;
  }
})();
