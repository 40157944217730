(function () {

  angular.module('kmi.lms.course.details')
    .component('courseRegistrationTargetDateState', {
      template: require('ajs/modules/course/views/components/state-messages/target-date-indicator.html').default,
      controller: CourseRegistrationTargetDateStateController,
      controllerAs: 'vm',
      bindings: {
        course: '=',
        userCourse: '='
      }
    });

  /* @ngInject */
  function CourseRegistrationTargetDateStateController() {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.show = vm.course.courseFormat.registrationRequired && vm.userCourse.lastRegistration.targetCompletionDate &&
        vm.userCourse.lastRegistration.statusId !== 6 && vm.userCourse.lastRegistration.statusId !== 3;
    }
  }
})();
