(function () {

  angular.module('kmi.lms.course.registrations')
    .component('registrationCustomInfoEditForm', {
      template: require('ajs/modules/course/registrations/components/custom-info/edit/custom-info-edit-form.html').default,
      controllerAs: 'vm',
      bindings: {
        infoFields: '=',
        course: '='
      }
    });
})();
