(function () {

  angular
    .module('kmi.lms.core')
    .factory('drawerService', drawerService);

  function drawerService($rootScope, $state){
    $rootScope.drawerState = false;

    return {
      getDrawerState: getDrawerState,
      toggleDrawer: toggleDrawer,
      closeDrawer: closeDrawer,
      openDrawer: openDrawer,
      drawerSearch: drawerSearch
    };

    function getDrawerState() {
      return $rootScope.drawerState;
    }

    function setDrawerState(state) {
      $rootScope.drawerState = state;
    }

    function drawerSearch(drawerQuery) {
      $state.go('main.search', {query: drawerQuery}, {inherit: false});
      closeDrawer();
    }

    function openDrawer() {
      setDrawerState(true);
    }

    function closeDrawer() {
      setDrawerState(false);
    }

    function toggleDrawer() {
      setDrawerState(!getDrawerState());
    }
  }
})();
