(function () {

  angular.module('kmi.lms.course.details')
    .component('baseCoursePosts', {
      template: require('ajs/modules/course/posts/base-course-posts.html').default,
      controller: BaseCoursePosts,
      controllerAs: 'vm',
      bindings: {
        courseObj: '=',
        from: '=',
        query: '='
      }
    }
    );

  /* @ngInject */
  function BaseCoursePosts($scope, _, currentUser, backUrlService, courseDetailsState, globalConfig) {
    var vm = this;

    vm.user = currentUser.get();
    vm.networkSettings = globalConfig.settings.network;

    vm.back = back;
    vm.$onInit = init;

    function init() {
      vm.trackingName = 'course_details_' + vm.courseObj.id + '_discussions';
    }

    function back() {
      if (vm.from === 'courseDetails'){
        backUrlService.clearDuplicateLastStateForRedirect('main.course');
        courseDetailsState.setScrollPosition('discussions');
        backUrlService.passThroughRedirect('main.course', {id: vm.courseObj.id});
      } else {
        backUrlService.goBack();
      }
    }
  }
})();
