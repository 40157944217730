(function () {

  angular.module('kmi.lms.course.common')
    .component('baseCourseDetailsComponent', {
      template: '<ui-view />',
      controller: BaseCourseDetailsController,
      controllerAs: 'vm',
      bindings: {
        courseObj: '='
      }
    });

  /* @ngInject */
  function BaseCourseDetailsController(courseDetailsState, $uiRouterGlobals, gtag) {
    var vm = this;
    vm.$onInit = onInit;

    function onInit() {
      gtag.skipNextPWEvent();
      courseDetailsState.go(vm.courseObj, $uiRouterGlobals.params);
    }
  }
})();


