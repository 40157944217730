(function () {

  angular.module('kmi.lms.course.details')
    .component('enrolledLearners', {
      template: require('ajs/modules/course/views/formats/live-event/components/enrolled-learners/enrolled-learners.html').default,
      controller: EnrolledLearnersController,
      controllerAs: 'vm',
      bindings: {
        courseId: '<',
        sessionId: '<',
        maxCount: '<'
      }
    });

  /* @ngInject */
  function EnrolledLearnersController($uibModal, CourseSession) {
    var vm = this;

    vm.$onInit = onInit;
    vm.viewAll = viewAll;

    function onInit() {
      var filters = {
        courseId: vm.courseId,
        sessionId: vm.sessionId,
        limit: vm.maxCount,
        group_categories: [140]
      };

      CourseSession.getLearners(filters).$promise.then(function (data) {
        vm.users = data.items;
        vm.count = data.count;
      });
    }

    function viewAll() {
      $uibModal.open({
        component: 'enrolledLearnersModal',
        keyboard: false,
        resolve: {
          courseId: function () {
            return vm.courseId;
          },
          sessionId: function () {
            return vm.sessionId;
          }
        }
      });
    }
  }

})();
