(function () {
  angular.module('kmi.lms.core')
    .component('portalLogo', {
      template: require('ajs/modules/app/navigation/components/portal-logo.html').default,
      controller: PortalLogoController,
      controllerAs: 'vm',
      bindings: {
        displayType: '@'
      }
    });


  /* @ngInject */
  function PortalLogoController(userGroupBrandingService, $window, currentUser, siteSettingService, _) {
    var vm = this;
    vm.logoFile = null;

    vm.siteSettings = siteSettingService.getSiteSetting();

    vm.$onInit = onInit;

    function onInit() {
      vm.logoFile = _.get($window.elmsEnvironment, 'siteSettings.logoFile', null);

      const user = currentUser.get();

      userGroupBrandingService.getInfo(user.id)
        .then(function (data) {
          if (data && data.image) {
            vm.logoFile = data.image;
          }
        })
        .finally(function () {
          vm.ready = true;
        });
    }
  }
})();
