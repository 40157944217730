(function () {

  angular.module('kmi.lms.course.registrations')
    .service('assessmentResultsService', function ($state, courseRegistrationService, notificationService) {

      return {
        getPostAssessmentResult: getPostAssessmentResult
      };


      function getPostAssessmentResult(registration, type) {
        if (registration.loading) {
          return;
        }

        registration.loading = courseRegistrationService.getRegistrationAssessmentResult(registration.id, (type || 2))
          .then(function (response) {
            if (response.quizResult && response.quizResult.result.quiz.resultsReviewAllowed) {
              return $state.go('main.courseQuizResult', {
                courseId: registration.conferenceCourseId || registration.courseId,
                resultId: response.quizResult.result.id
              });
            }

            showAssessmentReviewDenyMessage();
          })
          .finally(function () {
            registration.loading = false;
          });
      }

      function showAssessmentReviewDenyMessage() {
        notificationService.error('Result review is not allowed', 3e3);
      }
    });
})();
