(function () {

  angular.module('kmi.lms.course.registrations')
    .component('enterCompletionCodeDialog', {
      bindings: {
        workflow: '='
      },
      template: require('ajs/modules/course/registrations/components/dialogs/enter-completion-code.html').default,
      controller: EnterCompletionCodeDialogController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function EnterCompletionCodeDialogController() {
    const vm = this;
    vm.$onInit = onInit;

    function onInit() {
      vm.workflow.currentStateHandlers.stateDisabledHandler = isContinueDisabled;
    }

    function isContinueDisabled() {
      return vm.completionCodeForm && vm.completionCodeForm.$invalid;
    }
  }
})();
