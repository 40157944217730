(function () {

  angular.module('kmi.lms.course.registrations')
    .component('selectParentCourseRegistrationCreditTypesDialog', {
      bindings: {
        workflow: '='
      },
      template: require('ajs/modules/course/registrations/components/dialogs/select-parent-course-credit-types.html').default,
      controller: ParentCourseRegistrationCreditTypesDialogController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function ParentCourseRegistrationCreditTypesDialogController($q, currentUser, attributesService, collectionsService,
    userAttributesService, LearningObjectRegistration, LearningObjectRegistrationWorkflow, $scope, _) {
    const vm = this;

    vm.save = save;
    vm.$onInit = onInit;

    function onInit() {
      // load conference registration
      vm.loadingWorkflow = true;
      vm.attributes = [];
      $scope.$watch('vm.workflow.currentState', (newValue) => {
        if (newValue && ['selectCreditType', 'changeCreditType'].includes(newValue.name)) {
          vm.workflow.currentStateHandlers.nextHandler = save;
          vm.workflow.currentStateHandlers.stateDisabledHandler = isContinueDisabled;
        }
      });

      collectionsService.getCourseCollections(vm.workflow.registration.course.id)
        .then(function (collections) {
          vm.collection = collections[0];
          LearningObjectRegistration.get(vm.collection)
            .then(function (registration) {
              vm.collectionWorkflow = new LearningObjectRegistrationWorkflow(registration);
              vm.collectionWorkflow.actionData = {creditTypes: registration.creditTypes};
            }).finally(function () {
              vm.loadingWorkflow = null;
            });
        });
    }

    function isContinueDisabled() {
      return vm.selectParentCreditsForm && vm.selectParentCreditsForm.$invalid || vm.loading || vm.loadingWorkflow || vm.processing;
    }

    function save() {
      vm.processing = true;

      saveAttributes()
        .then(vm.collectionWorkflow.exec('ChangeCreditTypeAction', vm.collectionWorkflow.actionData, {
          skipPrerequisites: true
        }).then(() => {
          vm.workflow.nextState();
        }).catch(()=> {
          vm.workflow.cancelAction();
        }))
        .finally(() => {
          vm.processing = null;
        });
    }

    function saveAttributes() {
      if (vm.attributes.length && vm.selectParentCreditsForm.$valid) {
        vm.attributes = _.uniq(vm.attributes);
        attributesService.formatResultData(vm.attributes);
        return userAttributesService.save(currentUser.get().id, vm.attributes);
      }
      return $q.resolve();
    }
  }
})();
