(function () {

  angular.module('kmi.lms.course.common')
    .factory('courseComponentsService', courseComponentsService)
    .value('collectionTerminalPrerequisiteStatuses', [5, 40]);

  /* @ngInject */
  function courseComponentsService(
    $http, playCourseService, courseRegistrationStatus, collectionTerminalPrerequisiteStatuses, _
  ) {
    return {
      initSessions: initSessions,
      getCompilationStructure: getCompilationStructure,
      isCourseNotCompleted: isCourseNotCompleted,
      getFlattenCourseList: getFlattenCourseList,
      isCourseNotInTerminalPrerequisiteStatus: isCourseNotInTerminalPrerequisiteStatus,
      getCollectionComponentsRegistrations: getCollectionComponentsRegistrations,
      getComponentsCredits: getComponentsCredits,
      getComponentsCertificates: getComponentsCertificates,
      getComponentsTrainingOptions: getComponentsTrainingOptions
    };

    function initSessions(courseId) {
      return $http.get('/a/course_details/' + courseId + '/course_sessions/')
        .then(function (response) {
          return response.data.sessions;
        });
    }

    function getCompilationStructure(courseId, userId, params) {
      return $http.get('/a/course_details/' + courseId +
        '/compilation_structure/', {params: angular.extend({userId: userId}, params)})
        .then(function (response) {
          return response.data;
        });
    }

    function isCourseNotCompleted(course) {
      return (playCourseService.isBuyNowAvailable(course, course.registration, 'component') || !course.registration ||
        !_.includes(courseRegistrationStatus.strictCompletedSet, course.registration.statusId));
    }

    function isCourseNotInTerminalPrerequisiteStatus(course) {
      return course.courseFormat.registrationRequired && (playCourseService.isBuyNowAvailable(course, course.registration, 'component') || !course.registration ||
        !_.includes(collectionTerminalPrerequisiteStatuses, course.registration.statusId));
    }

    function getFlattenCourseList(structure, index) {
      return _.reduce(structure, function (result, item) {
        if (item.type === 1) {
          result.push(angular.extend(item, {number: result.length + 1 + (index || 0)}));
        } else {
          result.push.apply(result, getFlattenCourseList(item.items, result.length + (index || 0)));
        }
        return result;
      }, []);
    }

    function getCollectionComponentsRegistrations(collectionCourseId, userId = '') {
      return $http.get('/a/course/' +
        collectionCourseId + '/collections/components-registrations/' +
        (userId ? userId + '/' : ''))
        .then(function (response) {
          return response.data;
        });
    }

    function getComponentsCredits(courseId, permittedFor) {
      var params = {};
      if (permittedFor) {
        params.permitted_for = permittedFor;
      }

      return $http.get(['/a/course/', courseId, '/collections/components/credits/'].join(''), {params: params})
        .then(function (response) {
          return response.data;
        });
    }

    function getComponentsTrainingOptions(courseId) {
      return $http.get(['/a/course/', courseId, '/collections/components/training-options/'].join(''))
        .then(function (response) {
          return response.data;
        });
    }

    function getComponentsCertificates(courseId, permittedFor) {
      var params = {};
      if (permittedFor) {
        params.permitted_for = permittedFor;
      }

      return $http.get(['/a/course/', courseId, '/collections/components/certificates/'].join(''), {params: params})
        .then(function (response) {
          return response.data;
        });
    }

  }
})();
