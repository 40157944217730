(function () {

  angular.module('kmi.lms.coursePresenters')
    .component('coursePresenterAvatar', {
      template: require('ajs/modules/course/presenters/components/presenter-avatar.html').default,
      controller: CoursePresenterShortViewController,
      controllerAs: 'vm',
      bindings: {
        presenter: '=',
        size: '='
      }
    });

  /* @ngInject */
  function CoursePresenterShortViewController($scope, $filter) {
    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
      vm.initials = getInitials();

      loadAvatarImage();
    }

    function loadAvatarImage() {
      const img = new Image();
      const avatarUrl = $filter('urlVersion')([
        '/a/course/presenters/', vm.presenter.id,
        '/avatar/'
      ].join(''));

      img.onload = function () {
        $scope.$evalAsync(function () {
          if (img.width > 1) {
            vm.initials = null;
            vm.backgroundImageUrl = ['url(', avatarUrl, ')'].join('');
            vm.backgroundColor = 'transparent';
          } else {
            vm.initials = getInitials();
            vm.backgroundImageUrl = null;
            vm.backgroundColor = null;
          }
        });
      };

      img.src = avatarUrl;
    }

    function getInitials() {
      const nameParts = vm.presenter.name.split(' ');
      if (nameParts.length > 1) {
        return nameParts.map(function (part) {
          return part[0];
        }).slice(0, 2).join('').toUpperCase();
      } else {
        return [vm.presenter.name[0].toUpperCase(), vm.presenter.name[0].toUpperCase()].join('');
      }
    }
  }
})();
