(()=>{
  angular.module('kmi.lms.course.details')
    .component('conferencePrintableDetails', {
      template: require('ajs/modules/course/views/printable/components/conference-printable-details.html').default,
      controllerAs: 'vm',
      bindings: {
        course: '=',
      }
    });
})();
