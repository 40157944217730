(function () {

  angular.module('kmi.lms.course.details')
    .directive('coursePriceInfo', function () {
      return {
        restrict: 'EA',
        scope: {
          course: '=',
          userCourse: '='
        },
        template: require('ajs/modules/course/views/components/course-price-info.html').default,
        controller: CoursePriceInfo,
        bindToController: true,
        controllerAs: 'vm'
      };
    });

  /* @ngInject */
  function CoursePriceInfo(playCourseService, elms) {
    var vm = this;

    vm.formatPrice = elms.formatPrice;
    vm.isBuyNowAvailable = playCourseService.isBuyNowAvailable;
  }
})();
