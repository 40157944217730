(function () {

  angular.module('kmi.lms.course.details')
    .component('modernCourseCommonInfoHero', {
      bindings: {
        course: '=',
        userCourse: '=',
        hideRating: '=?',
        hasSidebar: '<?'
      },
      template: require('ajs/modules/course/views/components/modern/common-info-hero.html').default,
      controller: CourseCommonInfoController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function CourseCommonInfoController(currentUser, globalConfig, courseDetailsState, courseRegistrationStatus, _) {
    var vm = this;

    vm.hideAnonymousWarnings = globalConfig.settings && globalConfig.settings.courseDetails &&
      globalConfig.settings.courseDetails.hideAnonymousWarnings;
    vm.courseDetailsSettings = globalConfig.settings.courseDetails;
    vm.verificationEnabled = _.get(globalConfig, 'courseRegistration.verificationEnabled', false);

    vm.$onInit = activate;
    vm.statusVisible = statusVisible;
    vm.verificationVisible = verificationVisible;
    vm.getOrganization = getOrganization;

    function activate() {
      vm.userSettings = currentUser.get();
      vm.description = vm.course.$getHtmlField('description');
      vm.modernFeatures = courseDetailsState.modernFeaturesEnabled(vm.course);
      vm.heroView = _.get(globalConfig, 'settings.courseDetails.heroImageDetails');
      vm.minRatingCount = _.get(globalConfig, 'settings.courseDetails.minNumberOfReviewsToShowRating', 1);
    }

    function statusVisible() {
      const statusId = _.get(vm, 'userCourse.lastRegistration.statusId') ||
        _.get(vm, 'userCourse.lastCompletedRegistration.statusId');

      return vm.userCourse && statusId && statusId !== 10 &&
        !_.includes(courseRegistrationStatus.declinedSet, statusId);
    }

    function verificationVisible() {
      if (vm.verificationEnabled && vm.statusVisible() && _.get(vm, 'userCourse.lastCompletedRegistration.verified')) {
        if (vm.userCourse.lastCompletedRegistration.id === vm.userCourse.lastRegistration.id ||
          _.includes(courseRegistrationStatus.declinedSet, vm.userCourse.lastRegistration.statusId)) {
          return true;
        }
      }
      return false;

    }

    function getOrganization(typeId) {
      return _.find(vm.course.organizations, {'typeId': typeId});
    }
  }
})();
