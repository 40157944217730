(function () {

  angular.module('kmi.lms.course.reviews')
    .component('editCourseReviewModalComponent', {
      template: require('ajs/modules/course/reviews/modal/edit-review-window.html').default,
      controller: EditCourseReviewModalController,
      controllerAs: 'vm',
      bindings: {
        resolve: '=',
        modalInstance: '='
      }
    });

  /* @ngInject */
  function EditCourseReviewModalController() {
    var vm = this;
    vm.$onInit = onInit;

    vm.close = cancelModal;

    function onInit() {
      vm.course = vm.resolve.course;
      vm.newReview = vm.resolve.newReview;
    }

    function cancelModal() {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
