(function () {

  angular.module('kmi.lms.course.registrations')
    .component('selectRoleDialog', {
      bindings: {
        workflow: '='
      },
      template: require('ajs/modules/course/registrations/components/dialogs/select-role-dialog.html').default,
      controller: SelectRoleDialogController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function SelectRoleDialogController() {
    const vm = this;
    vm.$onInit = onInit;

    function onInit() {
      vm.workflow.currentStateHandlers.stateDisabledHandler = isContinueDisabled;
    }

    function isContinueDisabled() {
      return vm.workflow.actionData && !vm.workflow.actionData.session_role_id;
    }
  }
})();
