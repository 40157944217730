(function () {

  angular
    .module('kmi.lms.core')
    .factory('authorizationService', authorizationService);

  /* @ngInject */
  function authorizationService(currentUser, $cookies, $q, backUrlService, authorizationRequirementsService,
    cookiePrefix, globalConfig, _) {
    var authorizedStates = [], baseCookieName = cookiePrefix + '.SIDa', portalCookieName = cookiePrefix + '.PSIDa';

    // Remove cookie for each application load.
    removeCookie();

    return {
      authorizeState: authorizeState,
      isStateAuthorized: isStateAuthorized,
      removeCookie: removeCookie,
      checkRequirements: checkRequirements,
      getAuthorizedStates: getAuthorizedStates,
      getAuthorizationCookie: getAuthorizationCookie
    };

    function checkRequirements() {
      flushAuthorizedStates();

      return authorizationRequirementsService.verifyFulfillment().then(null, function (requirementState) {
        var defer = $q.defer();

        authorizeState(requirementState.name, requirementState.params);

        backUrlService.passThroughRedirect(requirementState.name, requirementState.params);
        defer.reject();

        return defer.promise;
      });
    }

    function isStateAuthorized(stateName, params) {
      var nonEmptyParams = _.pickBy(params, function (n) {
        return !!n;
      });

      return currentUser.get().anonymous || !!getAuthorizationCookie() ||
        _.find(globalConfig.settings.authorizedStates.concat(authorizedStates), function (item) {
          return item.name === stateName && angular.equals(item.params, nonEmptyParams);
        });
    }

    function authorizeState(stateName, params) {
      authorizedStates.push({
        name: stateName,
        params: params
      });
    }

    function flushAuthorizedStates() {
      authorizedStates.length = 0;
    }

    function getAuthorizationCookie() {
      var baseCookie = $cookies.get(baseCookieName);
      if (!baseCookie) {
        removeCookie();
      }
      return $cookies.get(portalCookieName);
    }

    function removeCookie() {
      $cookies.remove(portalCookieName);
    }

    function getAuthorizedStates() {
      return globalConfig.settings.authorizedStates.concat(authorizedStates)
        .concat(authorizationRequirementsService.getRequirementStates());
    }
  }
})();
