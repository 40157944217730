(function () {

  angular
    .module('kmi.lms.customAttributes.user')
    .factory('customUserAttributesService', customUserAttributesService);

  /* @ngInject */
  function customUserAttributesService($http, $q, attributesService, _, $cacheFactory) {
    var baseUrl = '/a/custom_attributes/users';
    const userAttributesCache = $cacheFactory('userAttributeCategoriesByParamsCache');

    return {
      getAttributeCategories: getAttributeCategories,
      getAttributeTypes: getAttributeTypes,
      loadCustomAttributes: loadCustomAttributes,
      groupUserAttributesByCategory: groupUserAttributesByCategory
    };

    function getAttributeCategories(params, userId, timeout, cache) {
      return $http.get(
        [baseUrl, userId ? '/' + userId : '', '/categories/'].join(''),
        {params: params, paramSerializer: '$httpParamSerializerJQLike', timeout: timeout, cache: cache?userAttributesCache:undefined }
      ).then(function (response) {
        return response.data;
      }).catch(function(err){
        if(err.status === 404) {
          return [];
        }
        return $q.reject(err);
      });
    }

    function getAttributeTypes(categoryId, userId, params) {
      return $http.get([baseUrl, userId ? '/' + userId : '', '/category/', categoryId, '/types/'].join(''), {params: params})
        .then(function (response) {
          return response.data;
        }).catch(function(err){
          if(err.status === 404) {
            return [];
          }
          return $q.reject(err);
        });
    }

    function loadCustomAttributes(user) {
      attributesService.clearCache();
      if(user.attributes) {
        _.each(user.attributes, function(attribute){
          attributesService.saveToCache(attribute);
        });
      }
      return user;
    }

    function groupUserAttributesByCategory(user, attributes) {
      if (attributes && attributes.length) {
        let attributesByCategories = _.groupBy(attributes, 'categoryId');

        let parameters = {
          groups: angular.toJson(_.map(user.groups, 'id')),
          withAttributes: true,
          filterSystem: true,
          categoryIds: JSON.stringify(Object.keys(attributesByCategories)),
          permitted_for: 'user.edit'
        };

        return getAttributeCategories(parameters, null, null)
          .then((categories) => {
            categories.forEach(category => {
              attributesService.mergeWithValues(category.attributes, user.attributes, 'id');
            });

            return categories;
          });
      } else {
        return $q.resolve([]);
      }
    }
  }

})();
