(function () {

  /**
   * @ngdoc controller
   * @name kmi.lms.course.details.controller:CourseListInfoController
   *
   * @requires elms
   *
   * @description
   * Shows CourseInfo, like a Target Audience, Subject Areas, Course Credits
   */

  angular.module('kmi.lms.course.details')
    .directive('courseListInfo', courseListInfo);

  function courseListInfo() {
    return {
      restrict: 'EA',
      scope: {
        title: '@',
        listInfo: '<',
        amountPrecision: '<?',
        textProperty: '@?'
      },
      template: function (elem, attr) {
        return attr.modernView ?
          require('ajs/modules/course/views/components/modern/course-list-info.html').default :
          require('ajs/modules/course/views/components/course-list-info.html').default;
      },
      controller: CourseListInfoController,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /*@ngInject*/
  function CourseListInfoController(elms, _) {
    var vm = this;

    vm.formatDigit = elms.formatDigit;
    vm.expanded = false;
    vm.limit = 2;
    vm.quantity = vm.limit;

    vm.toggle = toggle;
    vm.getText = getText;

    function getText(row) {
      return _.get(row, vm.textProperty || 'name');
    }

    function toggle() {
      vm.expanded = !vm.expanded;
      vm.quantity = vm.expanded ? vm.listInfo.length : vm.limit;
    }
  }

})();
