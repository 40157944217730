(function () {

  angular.module('kmi.lms.course.details')
    .component('modernCourseResources', {
      bindings: {
        course: '='
      },
      template: require('ajs/modules/course/views/components/modern/resources.html').default,
      controller: CourseResourcesController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function CourseResourcesController($scope) {
    var vm = this;

    vm.documents = [];

    vm.$onInit = onInit;
    vm.areResourcesVisible = areResourcesVisible;

    function readDocuments() {
      vm.course.initDocuments()
        .then(function (docs) {
          vm.documents = docs;
        });
    }

    function onInit() {
      readDocuments();

      $scope.$on('event:course.registration.action.finished', function () {
        readDocuments();
      });
    }

    function areResourcesVisible() {
      return (vm.course.resources && vm.course.resources.length > 0) ||
        (vm.course.documents && vm.course.documents.length > 0);
    }
  }
})();
