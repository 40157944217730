(function () {

  angular.module('kmi.lms.course.common')
    .config(commonCourseConfiguration);

  /* @ngInject */
  function commonCourseConfiguration($stateProvider) {

    var courseObjResolver = function ($transition$, Course) {
      return Course.get({
        courseId: $transition$.params().id,
        full: true
      }).$promise;
    };

    // Generic course details page state declaration
    $stateProvider
      .state('main.course', {
        url: '^/course/{id}?from&activeTab&currentBlsId',
        params: {
          id: null,
          from: null,
          collectionCourseId: null,
          activeTab: {
            dynamic: true,
            value: null,
            inherit: false
          }
        },
        resolve: {courseObj: courseObjResolver},
        data: {
          label: 'Course',
          availableFor: ['anonymous', 'regularUser', 'admin']
        },
        component: 'baseCourseDetailsComponent'
      });
  }
})();
