(() => {
  angular.module('kmi.lms.course.details')
    .component('conferencePrintableSessions', {
      template: require('ajs/modules/course/views/printable/components/conference-printable-sessions.html').default,
      controllerAs: 'vm',
      controller: CoursePrintableScheduleController,
      bindings: {
        course: '=',
        user: '='
      }
    });

  /* @ngInject */
  function CoursePrintableScheduleController(_, $q, CollectionComponent, courseComponentsService, CoursePresenter, moment, elms) {
    const vm = this;

    vm.$onInit = onInit;
    vm.registrations = {};
    vm.formatDigit = elms.formatDigit;

    function onInit() {
      vm.loading = true;
      loadSessions()
        .finally(() => vm.loading = false);
    }

    function loadSessions() {
      return $q.all([
        CollectionComponent.query({courseId: vm.course.id}).$promise,
        courseComponentsService.getCollectionComponentsRegistrations(vm.course.id, vm.user.id),
        CoursePresenter.query({courseId: vm.course.id}).$promise
      ]).then(function (results) {
        processSessions(results[0], results[1], results[2]);
      });
    }

    function processSessions(componentsResponse, registrations, presentersResponse) {
      _.forEach(registrations, (registration) => {
        if (registration.statusId !== 6) {
          vm.registrations[registration.courseId] = registration;
        }
      });

      let presenters = _.keyBy(presentersResponse, 'id');

      vm.conferenceDescendants = componentsResponse.map((component) => {
        component.course.presenters = _.map(component.presenters, (presenter) => {
          return presenters[presenter.presenterId];
        });
        component.registration = vm.registrations[component.blsCourseId];
        return component;
      });

      vm.conferenceDescendants = _.filter(vm.conferenceDescendants, (component) => {
        return !!vm.registrations[component.blsCourseId];
      });

      const dates = _.groupBy(vm.conferenceDescendants, (collectionComponent) => {
        return moment(collectionComponent.course.startDate).startOf('day');
      });

      vm.sessionDates = Object.keys(dates).map(key => ({date: dates[key][0].course.startDate, sessions: dates[key]}));
    }
  }

})();
