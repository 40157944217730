(function(){

  angular.module('kmi.lms.core')
    .factory('Profile', profileService);

  /* @ngInject */
  function profileService($resource) {
    return $resource('/a/user/:userId/profile/', {userId: '@id'}, {},
      {
        stripTrailingSlashes: false
      });
  }

})();
