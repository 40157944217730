(function () {

  angular.module('kmi.lms.course.details')
    .config(courseDetailsConfiguration);

  /* @ngInject */
  function courseDetailsConfiguration($stateProvider) {
    $stateProvider
      .state('main.course.default', {
        url: '/?track',
        params: {
          track: {
            value: null,
            dynamic: true
          }
        },
        component: 'modernCourseDetails',
        data: {
          label: 'Course',
          availableFor: ['anonymous', 'regularUser', 'admin']
        },
        resolve: {
          course: function (courseObj) {
            return courseObj;
          }
        }
      })
      .state('main.courseDiscussions', {
        url: '^/course/{id}/discussions?from&query',
        params: {
          id: null,
          from: null
        },
        data: {
          label: 'Course Discussions',
          availableFor: ['anonymous', 'regularUser', 'admin']
        },
        resolve: {
          courseObj: function ($q, $transition$, Course) {
            return Course.get({courseId: $transition$.params().id}).$promise
              .then(function (course) {
                return course;
              });
          },
          from: function ($transition$) {
            return $transition$.params().from;
          },
          query: function ($transition$) {
            return $transition$.params().query;
          }
        },
        component: 'baseCoursePosts'
      })
      .state('main.courseReviews', {
        url: '^/course/{id}/reviews?from',
        data: {
          label: 'Course Reviews',
          availableFor: ['anonymous', 'regularUser', 'admin']
        },
        params: {
          id: null,
          from: null
        },
        resolve: {
          courseObj: function ($q, $transition$, Course) {
            return Course.get({courseId: $transition$.params().id}).$promise
              .then(function (course) {
                return course;
              });
          },
          from: function ($transition$) {
            return $transition$.params().from;
          }
        },
        component: 'baseCourseReviews'
      });
  }
})();
