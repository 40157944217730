(function () {

  angular.module('kmi.lms.course.registrations')
    .factory('LearningObjectRegistration', learningObjectRegistration);

  function learningObjectRegistration(rootScopeService, $http, $q, apiUrl, EventDispatcher, Course,
    serverErrorHandlerService, notificationService, CourseRegistration, LearningObjectRegistrationsCache,
    courseRegistrationStatus, courseComponentsService, _, registrationWorkflowService, globalConfig) {

    var LearningObjectRegistration = function (course) {
      this.ready = false;
      this.course = course instanceof Course ? course : new Course(course);

      EventDispatcher.call(this);
    };

    LearningObjectRegistration.get = function (course, userId) {
      var wsUrl;
      if (userId) {
        wsUrl = apiUrl(['/a/user/', userId, '/courses/', course.id, '/'].join(''));
      } else {
        wsUrl = apiUrl(['/a/course_details/', course.id, '/registration/?full=true'].join(''));
      }

      var registration = new LearningObjectRegistration(course);

      return $http.get(wsUrl).then(function (response) {
        registration.init(response.data);
        return registration;
      });
    };

    LearningObjectRegistration.getFromReg = function (registration, course) {
      if (!course) {
        course = {id: registration.courseId};
      }
      var learningObjectRegistration = new LearningObjectRegistration(course);

      learningObjectRegistration.init(registration);
      learningObjectRegistration.originalId = registration.id;
      return learningObjectRegistration;
    };

    angular.extend(LearningObjectRegistration.prototype, {
      init: function (attrs) {
        _.merge(this, attrs);
        this.ready = true;
        this.statusId = this.status_id;
      },
      reload: function () {
        this.ready = false;

        var loadPromise;
        if (this.originalId) {
          loadPromise = CourseRegistration.get({registrationId: this.originalId}).$promise;
        } else {
          loadPromise = $http.get(apiUrl(['/a/course_details/', this.course.id,
            '/registration/?full=true'].join('')), {}, {
            ignoreLoadingBar: true
          }).then(function (response) {
            return response.data;
          });
        }

        return loadPromise
          .then(function (response) {
            applyRegistrationResponse(this, response);
            this.dispatchEvent('updated');
            rootScopeService.broadcast('event:course.registration.updated', this);
            return this;
          }.bind(this));
      },
      executeAction: function (actionName, data, actionUrl) {
        var url = actionUrl || ['/a/course_details/', this.courseId, '/registration/'].join('');
        return $http.post(apiUrl(url), {
          action: actionName,
          id: this.originalId,
          data: angular.extend({}, data)
        })
          .then(function (response) {
            applyRegistrationResponse(this, response.data);

            this.dispatchEvent('actionExecuted', actionName);
            this.dispatchEvent('updated');
            rootScopeService.broadcast('event:course.registration.updated', this);
            LearningObjectRegistrationsCache.removeAll();

            return response.data;
          }.bind(this), function (reason) {
            return serverErrorHandlerService.handleForbiddenError(reason, function (message) {
              notificationService.error(message, 5e3);
              this.reload();
            }.bind(this));
          }.bind(this));
      },
      getLaunchData: function (type) {
        var queryType = type ? ['?t=', type].join('') : '';
        return $http.get(apiUrl(['/a/course_details/', this.courseId, '/launch/', queryType].join('')))
          .then(function (response) {
            if (response.data.ready_for_launch) {
              return response.data;
            }
            return $q.reject();
          });
      },
      isCheckState: function () {
        var hasPendingActions = this.actions &&
          (_.includes(this.actions, 'PurchaseCourseAction') || _.includes(this.actions, 'PurchaseCourseLaterAction'));

        return registrationWorkflowService.isCheckState(this.state) || this.status_id === 1 && !hasPendingActions ||
          this.checkState;
      },
      initCompilationStructure: function (userId) {
        return courseComponentsService.getCompilationStructure(this.course.id, userId)
          .then(function (response) {
            angular.extend(this, {compilationStructure: response.structure});
            this.hasNonRegisteredComponents = response.has_non_registered_components;
            return this;
          }.bind(this));
      },
      hasSessions: function () {
        return _.includes([2, 7], this.type_id) && this.sessions;
      },
      inStatus: function (statuses) {
        return _.includes(statuses, this.status_id);
      },
      checkStatusActual: function () {
        if (this.id) {
          return $http.get(['/a/course_registrations/', this.id, '/actual_status/'].join(''), {
            params: {statusId: this.status_id},
            cacheByPass: true,
            ignoreLoadingBar: true
          })
            .then(function (response) {
              return response.data;
            });
        }

        return $q.when({actual: true});
      },
      hasNotification: function (notificationName) {
        this.notificationList = this.notificationList || {};
        this.notificationList[notificationName] = this.notificationList[notificationName] ||
          (this.notification && this.notification[notificationName]);
        return this.notificationList[notificationName];
      },
      notificationSeen: function (notificationName) {
        if (this.notificationList) {
          this.notificationList[notificationName] = null;
        }
        if (this.notification) {
          this.notification[notificationName] = null;
        }
      },
      getStatusId: function () {
        return !this.status_id && this.has_completed_registration ?
          this.completedRegistration.statusId : this.status_id;
      },
      getStatusName: function () {
        return courseRegistrationStatus.getStatusName(this.getStatusId());
      },
      getStatusTitle: function () {
        return courseRegistrationStatus.getTitle(this.getStatusId());
      },
      isAddAllAvailable: function () {
        return !_.get(globalConfig, 'settings.courseDetails.disableAddAllCourses', false) && this.getStatusId() &&
          this.course.isCollection() && this.hasNonRegisteredComponents;
      },
      isExportAllCoursesAvailable: function (ignoreSettings) {
        var ignoreRegistrationStatus = _.get(globalConfig, 'settings.courseDetails.ignoreRegistrationForExportAllCourses', true),
          allowedBySettings = (ignoreSettings ||
            !_.get(globalConfig, 'settings.courseDetails.disableExportAllCourses', false)),
          hasRegistration = !!this.getStatusId() &&
            !_.includes(courseRegistrationStatus.notStartedSet, this.getStatusId()),
          isCollection = this.course.isCollection();

        return allowedBySettings && (ignoreRegistrationStatus || hasRegistration) && isCollection;
      }

    });

    return LearningObjectRegistration;

    function applyRegistrationResponse(registration, data) {
      angular.extend(registration, data);
      registration.statusId = registration.status_id;
      registration.ready = true;
    }
  }
})();
