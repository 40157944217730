(function () {

  angular.module('kmi.lms.core')
    .factory('navigationService', navigationService);

  /* @ngInject */
  function navigationService($state, $cookies, rootScopeService, $window, notificationService, currentUser, menuService,
    securityService, globalConfig, cookiePrefix, $uiRouterGlobals, siteSettingService, _) {
    var targetState, navigationTargetCookieName = cookiePrefix + '.navigationTarget';

    extractTarget();

    return {
      throwToEntryPoint: throwToEntryPoint,
      goHome: goHome,
      isEditDialog: isEditDialog,
      setTargetState: setTargetState,
      transitionToTargetState: transitionToTargetState,
      getTargetState: getTargetState,
      clearTargetState: clearTargetState,
      getRelativeTargetPath: getRelativeTargetPath,
      goToAccessDenied: goToAccessDenied,
      getState: getState,
      extractTarget: extractTarget
    };

    function setTargetState(state) {
      if (!targetState && !_.includes(globalConfig.settings.deepLinkingExcludedStates, state.name) &&
        (!menuService.getFirstMenuState() || menuService.getFirstMenuState().stateName !== state.name)) {
        targetState = {
          name: state.name,
          params: state.params
        };

        var date = new Date();
        date.setTime(date.getTime() + (20 * 1000));
        // store cookie for 20 seconds to prevent target loss after page refresh
        $cookies.put(navigationTargetCookieName, angular.toJson(targetState), {expires: date});
      }
    }

    function getTargetState(checkAvailability) {
      return targetState && (checkAvailability === false || securityService.isStateAvailable(targetState.name)) ?
        targetState : null;
    }

    function clearTargetState() {
      targetState = null;
    }

    function transitionToTargetState() {
      if (targetState && securityService.isStateAvailable(targetState.name)) {
        var state = angular.copy(targetState);
        targetState = null;

        $state.go(state.name, state.params);
      } else {
        goHome();
      }
    }

    function getRelativeTargetPath() {
      let path = $uiRouterGlobals.params.next ||
        (targetState ? $state.href(targetState.name, targetState.params) : null);
      if (path) {
        return path;
      } else if (!currentUser.get().isRegularUser() && _.get(globalConfig, 'settings.login.forwardAdminToAdminApp')){
        let basePath = siteSettingService.getSiteSetting().basePath || 'lms';
        return '/' + basePath + '/admin';
      }

      return null;
    }

    function throwToEntryPoint(toState, toParams, notify) {
      if (currentUser.get().anonymous) {
        setTargetState({
          name: toState.name,
          params: toParams
        });
        rootScopeService.broadcast('event:auth-loginRequired');
      } else {
        if (notify) {
          notificationService.error('Sorry, Requested page is not available to your user account.', 5000);
        }

        goHome();
      }
    }

    /**
     * @description
     * Redirects to the access-denied if state is available or to entry point
     */
    function goToAccessDenied(toState, toParams, notify) {
      if ($state.get('main.access-denied') && !$state.is('main.access-denied') &&
        securityService.isStateAvailable('main.access-denied')) {
        setTargetState({
          name: toState.name,
          params: toParams
        });
        $state.go('main.access-denied');
      } else {
        throwToEntryPoint(toState, toParams, notify);
      }
    }

    /**
     * @description
     * Redirects to the first available menu item if it exists or to the site root
     */
    function goHome() {
      var homeMenuItem = menuService.getFirstMenuState();

      if (homeMenuItem) {
        if (homeMenuItem.stateName) {
          $state.go(homeMenuItem.stateName, homeMenuItem.stateParams);
        } else {
          $window.location.href = menuService.buildMenuLink(homeMenuItem);
        }
      } else {
        $window.location.href = '/';
      }
    }

    /**
     * @description
     * Determines whether provided state is an edit dialog
     * @param state
     * @returns {boolean}
     */
    function isEditDialog(state) {
      return state.name.lastIndexOf('edit.', 0) === 0;
    }

    function getState(name) {
      if (name) {
        var state = $state.get(name);
        return state && (!state.environment || _.includes(state.environment, globalConfig.environment)) ? state : null;
      } else {
        return _.filter($state.get(), function (state) {
          return !state.environment || _.includes(state.environment, globalConfig.environment);
        });
      }
    }

    function extractTarget() {
      targetState = angular.fromJson($cookies.get(navigationTargetCookieName));
    }
  }
})();
