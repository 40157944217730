(function () {

  /**
   * @ngdoc controller
   * @name kmi.lms.core.controller:AuthorizationRequirementsController
   * @description
   * Show  authorization requirements steps
   * @requires backUrlService
   *
   */
  angular.module('kmi.lms.core')
    .component('authorizationRequirementsComponent', {
      template: require('ajs/modules/authorization/requirements/template.html').default,
      controller: AuthorizationRequirementsController,
      controllerAs: 'vm'
    });

  /* @ngInject */
  function AuthorizationRequirementsController(navigationService, backUrlService, locationHistory, globalConfig) {
    var vm = this;

    vm.signInWording = globalConfig.signInWording;
    vm.goBack = goBack;

    function goBack() {
      locationHistory.clear();
      backUrlService.bypassHistory();

      navigationService.goHome();
    }
  }
})();
