(function () {

  angular.module('kmi.lms.course.details', [
    'kmi.lms.core',
    'globalDependencies',
    'kmi.lms.components',
    'kmi.lms.course.common',
    'kmi.lms.course.reviews',
    'kmi.lms.course.registrations',
    'http-auth-interceptor',
    'kmi.lms.advertising',
    'kmi.lms.coursePresenters',
    'monospaced.qrcode'
  ])
    .config(moduleConfig);

  function moduleConfig($stateProvider) {
    $stateProvider
      .state('mainPrint.course', {
        url: '/course/{courseId}/users/{userId}/schedule',
        params: {
          courseId: null,
          userId: null,
        },
        data: {
          availableFor: ['regularUser', 'admin'],
          label: 'Printable Schedule'
        },
        component: 'coursePrintableSchedule',
        resolve: {
          course: function (Course, $transition$) {
            return Course.get({
              courseId: $transition$.params().courseId,
              full: true
            }).$promise;
          },
          user: function (User, $transition$) {
            return User.get($transition$.params().userId, {permitted_for:'courseRegistration.view'})
              .then(
                (user) => {
                  return user;
                },
                () => {
                  return new User({id: $transition$.params().userId});
                }
              );
          }
        }
      })
    ;
  }
})();
