(function () {

  angular.module('kmi.lms.course.registrations')
    .factory('trainingService', trainingService);

  function trainingService(
    $window, $state, $uibModal, $q, $interval, globalConfig, courseMediaService,
    courseRegistrationStatus, courseComponentsService, _, rootScopeService
  ) {

    var forceNewWindowContentTypes = ['download', 'print', 'launch_external', 'physical_carrier',
      'online_meeting'];

    return {
      viewTranscript: viewTranscript,
      buildExternalLaunchPath: buildExternalLaunchPath,
      editRegistration: editRegistration,
      manageCustomFields: manageCustomFields,
      showApprovalStatus: showApprovalStatus,
      launchContent: launchContent,
      canShowContent: canShowContent,
      getStatusId: getStatusId,
      mergeSessionsData: mergeSessionsData,
      findNextCollectionComponent: findNextCollectionComponent,
      findFirstCollectionComponent: findFirstCollectionComponent,
      hasCompletedCourses: hasCompletedCourses,
      findNextSession: findNextSession,
      pollCoursePlayerWindow: pollCoursePlayerWindow,
      isInternalContentPlayer: isInternalContentPlayer
    };

    function canShowContent(registration) {
      return _.includes([1, 8], registration.course.formatTypeId) ||
        !!_.get(registration, 'onlineMeetingSchedules.length');
    }

    function viewTranscript(courseId, newWindow) {
      var url = ['/d/course/', courseId, '/transcript/'].join('');
      var defaultParam = true;

      if (newWindow || defaultParam) {
        $window.open(url, '', 'menubar=no,toolbar=no,status=no,resizable=yes,scrollbars=yes');
      } else {
        $window.open(url);
      }
    }

    function buildExternalLaunchPath(courseId, registrationId, type) {
      return ['/d/course/', courseId, '/launch/?t=', type, '&registration_id=', registrationId].join('');
    }

    function editRegistration(courseId) {
      if ($state.get('edit.conferenceRegistration.transitional.schedule')) {
        $state.go('edit.conferenceRegistration.transitional.schedule', {id: courseId});
      }
    }

    function manageCustomFields(workflow) {
      $uibModal.open({
        backdrop: 'static',
        keyboard: false,
        component: 'manageCustomFieldsModalComponent',
        resolve: {
          workflow: function () {
            return workflow;
          }
        }
      });
    }

    function showApprovalStatus(registration) {
      $uibModal.open({
        backdrop: 'static',
        keyboard: false,
        component: 'registrationApprovalStatusComponent',
        resolve: {
          registration: function () {
            return registration;
          }
        }
      });
    }

    function pollCoursePlayerWindow(wnd) {
      let defer = $q.defer();

      let pollTimer = $interval(function () {
        try {
          if (!wnd || wnd.closed !== false){
            $interval.cancel(pollTimer);
            defer.resolve();
          }
        }
        catch (e) {
          if (e instanceof DOMException && e.name === 'SecurityError') {
            // If a SecurityError is thrown, assume the window has navigated away
            // stop timer only for close check, if close can not be checked, then timer do not make sence
            $interval.cancel(pollTimer);
            defer.resolve();
          }
        }
      }, 200);

      return defer.promise;
    }

    function launchContent(registration, params) {
      var type = params.type;
      // This change allow run quizzes when scorm runs in new window
      if (
        _.includes(forceNewWindowContentTypes, type) ||
        _.includes(_.get(globalConfig, 'settings.courseDetails.forceNewWindowFormats', []), registration.course.format) &&
        ('course' === type || 'browse' === type)
      ) {

        let wnd = params.newWindow;
        let path;
        if (wnd) {
          path = buildExternalLaunchPath(registration.courseId, registration.id, type);

          if (wnd === $window) {
            wnd.location.href = path;
          } else {
            wnd.location.replace(path);
          }
        } else {
          wnd = $window.open(buildExternalLaunchPath(registration.courseId, registration.id, type));
        }

        let updateCallback = () => {
          rootScopeService.broadcast('event:course.registration.updatedAll');
          registration.reload();
        };

        if (wnd !== $window) {
          // In case when window is not closed we can only update and do not stop timer
          pollCoursePlayerWindow(wnd)
            .then(updateCallback, updateCallback);
        }
      } else {
        rootScopeService.broadcast('event:embedded-course-player-will-open');
        if (registration.current_bls_id && _.get(globalConfig, 'settings.coursePlayer.collectionPlaylist')) {
          $state.go('view.collectionPlayer', {
            courseId: registration.courseId,
            collectionId: registration.current_bls_id
          });
        } else {
          $state.go('view.course_content', {
            courseId: registration.courseId,
            type: type,
            registrationId: registration.originalId,
            current_bls_id: registration.current_bls_id
          });
        }
      }
    }

    function getStatusId(registration) {
      return !registration.status_id && registration.has_completed_registration ?
        registration.completedRegistration.statusId : registration.status_id;
    }

    function mergeSessionsData(courseSessions, registration) {
      registration.onlineMeetingSchedules = [];

      angular.forEach(courseSessions, function (session) {
        angular.forEach(registration.sessions, function (regSessionData) {
          if (regSessionData.id === session.id) {
            angular.extend(session, regSessionData);

            if (registration.session_id && registration.session_id === regSessionData.id) {
              if (regSessionData.actions.indexOf('LaunchOnlineMeetingAction') > -1) {
                var schedules = _.filter(session.schedules, function (e) {
                  return e.onlineMeetingIntegration === true;
                });

                if (schedules) {
                  registration.onlineMeetingSchedules = registration.onlineMeetingSchedules.concat(schedules);
                }
              }
            }
          }
        });
      });
    }

    function hasCompletedCourses(items) {
      function _predicateFn(item) {
        return _.includes(courseRegistrationStatus.strictCompletedSet, item.status_id);
      }

      return _.some(items, _predicateFn);
    }

    function hasRegistrationNotRequiredCourses(items){
      return _.some(items, item => item.courseFormat && !item.courseFormat.registrationRequired);
    }

    function findNextCollectionComponent(structureItems, includeOptional, excludeNonRunnable) {
      var course = null;

      angular.forEach(structureItems, function (item) {
        if (course === null) {
          if (item.type === 1 && (courseComponentsService.isCourseNotInTerminalPrerequisiteStatus(item) ||
            (!item.courseFormat.registrationRequired && !excludeNonRunnable))) {
            course = item;
          }

          if (item.type === 2 && (includeOptional || item.required === true ||
            !hasCompletedCourses(item.items)) || hasRegistrationNotRequiredCourses(item.items)) {
            course = findNextCollectionComponent(item.items, includeOptional, excludeNonRunnable);
          }
        }
      });

      return course;
    }

    function findFirstCollectionComponent(structureItems) {
      var course = null;
      angular.forEach(structureItems, function (item) {
        if (course === null) {
          if (item.type === 1) {
            course = item;
          }

          if (item.type === 2) {
            course = findFirstCollectionComponent(item.items);
          }
        }
      });

      return course;
    }

    function findNextSession(sessions) {
      var resultSessions = _.filter(sessions, function (session) {
        return !session.expired &&
          (!session.status_id || !_.includes(courseRegistrationStatus.completedSet, session.status_id)) &&
          (!session.attend_cap || session.seats_available > 0 || session.wait_list &&
            (!session.wait_list_capacity || !session.is_waitlist_full || session.allow_over_register));
      });

      resultSessions = _.orderBy(resultSessions, [function (session) {
        return new Date(session.start_date);
      }]);
      return resultSessions[0];
    }

    function isInternalContentPlayer(course) {
      return course.format === 108 || course.format === 115;
    }
  }
})();
