(function () {

  angular.module('kmi.lms.course.registrations')
    .directive('registrationActionsMenu', registrationActionsMenu);

  function registrationActionsMenu() {
    return {
      restrict: 'E',
      scope: {
        workflow: '=',
        showDetails: '=',
        showHistory: '=',
        showRemoveAction: '=',
        showTargetActions: '=',
        courseIsNotAvailable: '=',
        trackingName: '=?',
        alignMenu: '<?',
      },
      template: require('ajs/modules/course/registrations/components/registration-action-items.html').default,
      controller: RegistrationActionsMenuController,
      replace: true
    };
  }

  /* @ngInject */
  function RegistrationActionsMenuController($scope, rootScopeService, userCertificatesService, currentUser,
    courseRegistrationService, courseQuizTypes, courseRegistrationStatus, $window, globalConfig,
    notificationService, _, trainingService, userSettingsService, $state, CourseReview, $uibModal,
    courseDetailsState, courseService, courseMediaService, assessmentResultsService, courseEnums) {
    $scope.courseQuizTypes = courseQuizTypes;
    $scope.user = currentUser.get();
    $scope.courseRegistrationStatus = courseRegistrationStatus;
    $scope.appSettings = globalConfig.settings;
    $scope.trainingService = trainingService;
    $scope.editable = globalConfig.settings.view === 'admin' || globalConfig.projectName === 'ubt';
    $scope.userSettingsService = userSettingsService;
    $scope.courseDetailsState = courseDetailsState;
    $scope.courseEnums = courseEnums;
    $scope.testingEnv = globalConfig.environment === 'testing';

    $scope.getStructureExportUrl = getStructureExportUrl;
    $scope.isStateAvailable = isStateAvailable;
    $scope.shareCourse = shareCourse;
    $scope.copyCourseLink = copyCourseLink;
    $scope.hasRemoveAction = hasRemoveAction;
    $scope.hasInformativeButtons = hasInformativeButtons;
    $scope.sharingActionsEnabled = sharingActionsEnabled;
    $scope.getPostAssessmentResult = getPostAssessmentResult;
    $scope.isHistoryAvailable = isHistoryAvailable;
    $scope.externalCertificatesEnabled = externalCertificatesEnabled;
    $scope.isPrintScheduleAvailable = isPrintScheduleAvailable;
    $scope.isReceiptAvailable = isReceiptAvailable;
    $scope.courseQuizTypes = courseQuizTypes;

    function externalCertificatesEnabled() {
      let registration = $scope.workflow && $scope.workflow.registration;

      return registration && $scope.appSettings.registration.externalCertificatesEnabled && !registration.checkState &&
        userCertificatesService.externalCertificateAvailable(registration, registration.course);
    }

    function hasRemoveAction() {
      return $scope.workflow && ($scope.workflow.hasAction('RemoveAction') && $scope.showRemoveAction ||
        $scope.workflow.hasAction('WithdrawAction'));
    }

    function hasInformativeButtons() {
      return $scope.workflow && $scope.workflow.registration &&
        ($scope.workflow.registration.actions && $scope.workflow.registration.actions.indexOf('PrintAction') > -1 ||
          $scope.workflow.registration.certificates_data &&
          $scope.workflow.registration.certificates_data.print_status === 1 ||
          $scope.appSettings.registration.externalCertificatesEnabled &&
          $scope.workflow.registration.inStatus(courseRegistrationStatus.strictCompletedSet) &&
          ($scope.workflow.registration.certificates_data &&
            $scope.hasExternalCertificate($scope.workflow.registration.certificates_data.certificates_list) ||
            (!$scope.workflow.registration.certificates_data ||
              ($scope.workflow.registration.certificates_data.certificates_list &&
                !$scope.workflow.registration.certificates_data.certificates_list.length &&
                $scope.workflow.registration.certificates_data.print_status === 0))));
    }

    function getPostAssessmentResult(registration, type) {
      assessmentResultsService.getPostAssessmentResult(registration, type);
    }

    function getStructureExportUrl() {
      return courseRegistrationService.compilationStructureExportUrl($scope.workflow.registration.course.id);
    }

    $scope.printCert = function () {
      userCertificatesService.printCertificate($scope.workflow.registration.certificates_data.registration_id);
    };

    $scope.hasExternalCertificate = function (certificates) {
      return _.some(certificates, function (item) {
        // certificate category typeid
        return item[2] === 0;
      });
    };

    $scope.addComponentRegistrations = function () {
      return courseRegistrationService.addComponentRegistrations($scope.workflow.registration.course.id)
        .then(function () {
          rootScopeService.broadcast('event:course.registration.updated', $scope.workflow.registration);

          $scope.workflow.registration.hasNonRegisteredComponents = false;
          notificationService.info('Courses have been added', 3e3);
        });
    };

    $scope.showChangeTargetCompletionDate = function () {
      var modalInstance = $uibModal.open({
        component: 'changeTargetCompletionDateModal',
        keyboard: false,
        resolve: {
          registration: function () {
            return $scope.workflow.registration;
          }
        }
      });

      modalInstance.result.then(function () {
        rootScopeService.broadcast('event:course.registration.updated', $scope.workflow.registration);
      });
    };

    function isStateAvailable(stateName) {
      return !!$state.get(stateName);
    }

    function sharingActionsEnabled() {
      return !!(_.get($scope, 'workflow.registration.course') &&
        _.get($scope, 'workflow.registration.course.courseFormat.system', false) === false &&
        (courseDetailsState.modernFeaturesEnabled($scope.workflow.registration.course) ||
          userSettingsService.user_network_enabled && isStateAvailable('main.user_network') &&
          $scope.user.userNetwork.profile));
    }

    function shareCourse() {
      CourseReview.shareCourse($scope.workflow.registration.course);
    }

    function copyCourseLink() {
      courseService.copyCourseLink($scope.workflow.registration.course.id, 'Copy and share this link to invite other users to take this course');
    }

    function isHistoryAvailable() {
      return $scope.workflow && $scope.workflow.registration.has_registration_history && $scope.showHistory && !$scope.user.anonymous;
    }

    function isPrintScheduleAvailable() {
      let courseFormatId = _.get($scope.workflow, 'registration.course.format') ||
        _.get($scope.workflow, 'registration.course.formatId');
      let registrationStatus = _.get($scope.workflow, 'registration.statusId') ||
        _.get($scope.workflow, 'registration.lastRegistration.statusId', 6);
      return !_.includes([3, 6], registrationStatus) &&
        _.includes(_.get(globalConfig, 'settings.registration.printableSchedulesCourseFormats', []), courseFormatId);
    }

    function isReceiptAvailable() {
      return $scope.workflow && _.get($scope.workflow, 'registration.aggregated.accessHasBeenGrunted');
    }
  }
})();
