(function () {

  /**
   * @ngdoc service
   * @name kmi.lms.customAttributes.course:customCourseAttributesService
   *
   *
   * @description
   * Represents customCourseAttributesService data model.
   * Implements all server's CRUD methods and get course attributes by query.
   */
  angular.module('kmi.lms.customAttributes.course')
    .factory('customCourseAttributesService', customCourseAttributesService);

  /*@ngInject*/
  function customCourseAttributesService($http, $q, $cacheFactory, attributesService, _) {
    var service = {
      getTypes: getTypes,
      getCategories: getCategories,
      groupCourseAttributesByCategory: groupCourseAttributesByCategory
    };

    var customCourseAttributesCache = $cacheFactory('customCourseAttributesCache');
    var customCourseAttributesCategoriesCache = $cacheFactory('customCourseAttributesCategoriesCache');

    function getCategories(params, ignoreCache, timoutDefer) {
      var requestParams = {};

      if (params) {
        requestParams.params = params;
      }

      if (!ignoreCache) {
        requestParams.cache = customCourseAttributesCategoriesCache;
      }

      if (timoutDefer) {
        requestParams.timeout = timoutDefer.promise;
      }

      return $http.get('/a/custom_attributes/courses/categories/list/', requestParams);
    }

    function getTypes(categoryId) {
      return $http.get('/a/custom_attributes/courses/categories/' + categoryId + '/types/', {cache: customCourseAttributesCache});
    }

    function groupCourseAttributesByCategory(course, attributes) {
      if (attributes && attributes.length) {
        let attributesByCategories = _.groupBy(attributes, 'categoryId');

        let parameters = {
          groups: angular.toJson(_.map(course.groups, 'id')),
          withAttributes: true,
          filterSystem: true,
          categoryId: Object.keys(attributesByCategories)
        };

        return getCategories(parameters, true, null)
          .then((response) => {
            let categories = response.data;

            categories.forEach(category => {
              attributesService.mergeWithValues(category.attributes, course.attributes, 'id');
            });

            return categories;
          });
      } else {
        return $q.resolve([]);
      }
    }

    return service;
  }
})();

